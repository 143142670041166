.career .head .desc {
  font-size: 14px;
  margin-top: 10px;
  font-weight: 400;
}
.text-center {
  text-align: center !important;
}
.career-input input {
  width: 100%;
}

.custom-field .career-input {
  width: 95%;
}

.custom-field .close-icon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.custom-field .box {
  display: flex;
  align-items: center;
}

.career-input {
  padding-bottom: 30px;
  position: relative;
}

.career-input > span {
  position: absolute;
}

.career-input > div {
  position: relative;
}
.career-input > div .qr-btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.preview-logo {
  width: 150px;
  height: 150px;
  display: inline-block;
  border: 1px solid #000;
  margin-bottom: 20px;
  /* margin-right: 10px; */
}
.preview-box {
  margin-right: 20px;
}

.preview-logo img {
  width: 100%;
  height: 100%;
}

.career img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.career .submit-form {
  padding: 20px 0;
}

.career .submit-form .submitbtncstm {
  background-color: #002e6c !important;
  border-color: #002e6c !important;
}

.applicant {
  padding: 20px 0;
}
.career-input-d .conta {
  display: flex;
}
.qr-code-box {
  width: 150px;
  height: 150px;
  display: inline-block;
  border: 1px solid #000;
  margin-bottom: 20px;
}
.qr-code-box > canvas {
  width: 100% !important;
  height: 100% !important;
}
.career-input-d {
  margin-bottom: 20px;
}
.career-input-d .qr-code {
  width: 400px;
}

.career-input-d .qr-code input {
  display: inline-block;
}
.career-fixbox {
  height: 400px;
  overflow-x: auto;
}
.career-input.opacity input {
  opacity: 0.8;
}
.text-center {
  text-align: center;
}
.description textarea {
  width: 100%;
  font-size: 16px;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid rgb(218, 218, 218);
}
.selectCompany {
  margin-bottom: 16px;
}
.configuration-title select {
  width: 100%;
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid rgb(218, 218, 218);
  margin: 0;
}
.text-center-imp.center {
  justify-content: center;
}

.editor {
  border: 1px solid #e9e9e9;
  color: #000;
  min-height: 150px;
  font-family: "Source Sans Pro", sans-serif;
}

.editor h2,
.editor h3,
.editor h4 {
  color: #112e51;
}

.editor h2 {
  font-size: 1.555555556rem;
}

.editor h3 {
  font-size: 1.333333333rem;
}

.editor h4 {
  font-size: 1rem;
}

.editor a {
  color: #0071bc;
  text-decoration: none;
}

.editor a:hover {
  text-decoration: underline;
  cursor: pointer;
}
.bg-verification-integration {
  padding: 50px 0;
}

.bg-check .bg-container {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  border-radius: 6px;
}
.bg-check .bg-container p {
  margin: 10px 0 0 0;
  text-align: left;
}
.bg-check .bg-container select {
  padding: 10px 10px;
  border-radius: 4px;
  width: 200px;
  margin: 10px 0 0 0;
}

.bg-check .bg-container button {
  padding: 13px 15px;
  border-radius: 4px;
  width: 200px;
  margin: 0 83px;
}

.JobsBody table tr td.first-sm {
  width: unset !important;
}
.ml-1 {
  margin-left: 8px;
}
.bg-container-db {
  display: flex;
}

.bg-container-db select {
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 4px 5px;
  box-sizing: border-box;
  border: 1px solid rgb(218, 218, 218);
}

.bg-container-db button {
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 6px;
  box-sizing: border-box;
}

.package-para {
  margin: 0 0 10px;
}

.not-assign {
  background: #ddd;
  padding: 10px;
  border-radius: 10px;
  display: inline-block;
  width: 100px;
}

.bg-data-table tr:nth-child(even) {
  background-color: #fff;
}

.bg-data-table tr:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.bg-data-table tr.selected {
  background-color: rgba(25, 118, 210, 0.12);
}
