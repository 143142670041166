.NewUserTemplates .opEmpField, .NewUserTemplates table {
  margin-bottom: 10px;
}

.NewUserTemplates .opEmpField {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.NewUserTemplates .opEmpField .alldone {
  font-size: 16px;
  font-weight: bold;
  color: green;
}

.NewUserTemplates table {
  background-color: white;
}

.NewUserTemplates table th, .NewUserTemplates table td {
  padding: 0px;
}

.NewUserTemplates .leftcol, .NewUserTemplates table th {
  padding: 5px;
}

.NewUserTemplates table tr td:first-of-type {
  width: 300px;
}

.NewUserTemplates .rightcol {
  display: flex;
  width: 100%;
  align-items: center;
}

.NewUserTemplates .rightcol .vals {
  flex: 1;
  margin: 5px 0;
}

.NewUserTemplates .rightcol .delete {
  padding: 3px;
}

.NewUserTemplates .rightcol .delete button {
  padding: 1px 6px;
  background-color: red;
}

.NewUserTemplates .rightcol .vals .list {
  margin-bottom: 3px;
}

.NewUserTemplates .rightcol .vals .add {
  margin: 0 5px;
}

.NewUserTemplates .rightcol .vals .add button {
  font-size: 12px;
  padding: 3px;
}

.NewUserTemplates .eChooser .activeEmps > div {
  background-color: white;
}
