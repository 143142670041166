.eChooser, .prodSettings {
  position: relative;
}

.eChooser .loadinGif, .prodSettings .loadinGif {
  width: 150px;
}

.eChooser .loadCont, .prodSettings .loadCont {
  background: rgba(255,255,255,0.7);
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.eChooser .title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

.eChooser .tip {
  font-style: italic;
  font-size: 14px;
  margin-bottom: 10px;
}

.eChooser .top {
  display: flex;
}

.eChooser .label {
  margin-right: 5px;
}

.eChooser .buttonCont button {
  margin-right: 20px;
}

.eChooser .buttonCont button:last-of-type {
  margin-right: 0px;
}

.eChooser .activeEmps {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.eChooser select {
  margin: 5px 0;
}

.eChooser .bot {
  margin-top: 5px;
}

.ccChooser .addCC {
  margin-left: 10px;
}
