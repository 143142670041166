@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");
:root {
  --my-color: #002e6c;
  --color: #ffffff;
  --fontFamily:"Roboto", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  line-height: 1.3;
  font-size: 16px;
  background-color: white;
}

* {
  font-family: var(--fontFamily)
}

.content {
  max-width: 900px;
  margin: 0 auto;
}

*:focus {
  outline: none;
}

button,
input[type="submit"],
.button {
  cursor: pointer;
  /* background-color: #002e6c; */
  background-color: var(--my-color);
  color: white;
  padding: 5px;
  border-radius: 3px;
  border: none;
  font-size: 14px;
}

a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

h1 {
  margin: 0;
  padding: 15px 0 30px 0;
  line-height: 1;
  text-align: center;
  font-size: 30px;
}

h2 {
  margin: 0;
  line-height: 1;
  margin-bottom: 20px;
  text-align: center;
}

select {
  font-size: 16px;
  margin: 0 5px;
}

.bottomMargin {
  margin-bottom: 10px;
}

.rounded3 {
  border-radius: 3px;
}

.rounded5 {
  border-radius: 5px;
}

.grey {
  background-color: #eeeeee;
}

.green {
  background-color: #0cad4d !important;
  color: white;
}

.green-text {
  background-color: #0cad4d;
}

.blue {
  background-color: #002e6c;
}

.blue-text {
  color: #002e6c;
}

.red {
  background-color: red !important;
  color: white;
}

input[type="text"],
input[type="password"] {
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid rgb(218, 218, 218);
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 26px;
    padding: 10px 0 20px 0;
  }

  h2 {
    margin-bottom: 10px;
  }

  .disappearSmall {
    display: none;
  }
}
.error_top{
  margin-top: 10px;
  margin-right: 280px 
}