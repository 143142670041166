.outline {
  padding-bottom: 40px;
}

.outline .body {
  margin: 0 auto;
  margin-top: 75px;
  padding: 0 10px;
  max-width: 1150px;
}
