.topNav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 5px;
  box-shadow: 0 2px 3px 0 rgba(0,0,0,0.5);
  z-index: 10;
  text-align: right;
}

.topNav .cont {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topNav .cont .logoCont {
  width: 100px;
  padding: 3px 0;
}

.topNav .cont .logoCont img {
  width: 100%;
  display: block;
}

.topNav .rightSide {
  display: flex;
  align-items: center;
  color: white;
}

.topNav .rightSide .companyInfo {
  margin-right: 20px;
}

.topNav .rightSide .companyInfo > div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.topNav .rightSide .companyInfo > div span {
  margin-right: 5px;
}

.topNav .rightSide .companyInfo > div span a {
  background-color: white;
  color: #0A75C4;
  padding: 1px 5px;
  border-radius: 5px;
  margin: 3px;
  display: inline-block;
  line-height: 1.3;
}

.topNav .cont .logoutCont button {
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .topNav {
    padding: 0px;
  }

  .topNav .rightSide {
    flex-flow: column;
    justify-content: center;
    align-items: flex-end;
  }

  .topNav .rightSide .companyInfo {
    margin-right: 0px;
    font-size: 14px;
    padding-top: 3px;
  }
}
