:root {
  --my-color: #002e6c;
  --color: #ffffff;
  --primaryColor: #002e6c;
  --productcartbackcolor: #eeeeee;
  --productcolor: #000;
  --background: #f0f3f4;
  --bgcolor: #f0f3f4;
  --paracolor: #000;
  --paragraphfontsize: 16px;
  --proheadingfontsize: 20px;
  --prohendingfontstyle: '';
  --paragraphfontstyle: '';
}
.pis h1 {
  padding: 10px 0 15px 0;
  color: var(--productcolor);
  font-size: var(--proheadingfontsize);
  font-style: var(--prohendingfontstyle);
}
.ddd-color {
  color: #ddd;
}
.color-red {
  color: red;
}
.pis h2 {
  text-align: left;
  margin-bottom: 5px;
  color: var(--productcolor);
  /* font-size: var(--proheadingfontsize); */
  font-style: var(--prohendingfontstyle);
}

.pis .productSettingCont {
  margin-top: 30px;
}

.pis .prod-settings .psrow {
  padding: 10px 0;
  padding-left: 10px;
  border-left: solid 10px var(--my-color);
  margin-bottom: 20px;
}

.pis .successMsg {
  margin: 10px 0;
  font-weight: bold;
  color: #0cad4d;
}

.pis .successMsg.bad {
  color: red;
}

.AccessControl .existing {
  margin-top: 10px;
}

.AccessControl .addMore {
  margin-top: 10px;
}

.AccessControl .accessType {
  margin-top: 10px;
}

.AccessControl .accessType button {
  background-color: white;
  color: #b0afaf;
  border: solid 1px #b0afaf;
}

.AccessControl .accessType button.active {
  background-color: var(--my-color);
  color: white;
}

.AccessControl .accessType button:first-of-type {
  margin-right: 20px;
}

.AccessControl .endButs {
  margin-top: 15px;
}

.AccessControl .endButs button.cancel {
  background-color: white;
  color: black;
}

.AccessControl .endButs button:first-of-type {
  margin-right: 20px;
}

.AccessControl .existingCont .existing {
  max-width: 600px;
}

.AccessControl .AccessControlLine {
  padding: 5px;
  border: solid 1px black;
  border-radius: 5px;
  margin: 5px 0;
}

.AccessControl .AccessControlLine.inactive {
  background-color: #ff6363;
}

.AccessControl .AccessControlLine .top {
  display: flex;
}

.AccessControl .AccessControlLine .bottom .active button {
  font-size: 10px;
  padding: 3px;
}

.AccessControl .AccessControlLine .employee {
  flex: 1;
  display: flex;
  align-items: center;
}

.AccessControl .AccessControlLine .actions .accessType {
  margin: 0;
}
.custom-modal {
  display: block;
  position: absolute;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 33.5%;
  border-radius: 12px;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.d-flex {
  display: flex;
  justify-content: space-around;
}

.d-flex1 {
  display: flex;
}

.ml-2 {
  margin-left: 15px;
}

.mt-2 {
  margin-top: 15px;
}

#modalOuter {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.asscmp-a-table-scroll {
  max-height: 190px;
  overflow-y: auto;
}

.custreportinput {
  display: flex;
  justify-content: space-between;
}

.report-type {
  padding: 6px 15px;
}
.custom-report-input {
  display: flex;
}

.custom-report-input > input {
  width: 76%;
}

.theme-btn {
  color: #fff;
  font-size: 16px !important;
}

.custom-report-container {
  display: flex;
}

.custom-report-container .select-cus-report,
.custom-report-container .custom-report-s-input {
  width: 45%;
  padding: 0 10px;
}

.custom-report-container .select-cus-report select {
  width: 100%;
  margin: 0;
  padding: 10px 15px;
}

.custom-report-container .custom-report-s-input input {
  width: 100%;
  margin: 0;
  padding: 10px 15px;
}
.custom-report-container
  .custom-report-s-input
  input::-webkit-outer-spin-button,
.custom-report-container
  .custom-report-s-input
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.custom-report-container .cus-add-btn {
  width: 10%;
  margin: 0;
}

.custom-report-container .cus-add-btn button {
  font-size: 16px;
  position: relative;
  bottom: -21px;
  padding: 10px 11px;
}

.cus-reportId-table {
  padding: 0 10px;
  margin-top: 30px;
}

.ass-custom-report-table .arrow-icon {
  color: #000;
  background: transparent;
  font-size: 16px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 23px;
  text-align: center;
  margin-left: auto;
}

.ass-custom-report-table .arrow-icon:hover {
  background-color: rgba(0, 0, 0, 0.04);
  transition: 0.3ms;
}
