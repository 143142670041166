.SystemAdmin .head {
    font-size: 20px;
    font-weight: 700;
}

.SystemAdmin .section {
    position: relative;
}

.SystemAdmin .loading {
    position: absolute;
    left: 0;
    right: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255,0.4);
}

.SystemAdmin .section .head {
    margin-bottom: 10px;
}

.SystemAdmin .section .error {
    color: red;
    margin: 10px 0;
    font-weight: 700;
}

.SystemAdmin .section .success {
    color: green;
    margin: 10px 0;
    font-weight: 700;
}

.SystemAdmin .form {
    padding-left: 10px;
}

.SystemAdmin .form input {
    min-width: 350px;
}

.SystemAdmin .form > div {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.SystemAdmin .form > div label {
    margin-right: 10px;
}