:root {
  --primaryColor: #002e6c;
  --productcartbackcolor: #eeeeee;
  --productcolor: #000;
  --background: #f0f3f4;
  --bgcolor: #f0f3f4;
  --paracolor: #000;
  --paragraphfontsize: 16px;
  --proheadingfontsize: 20px;
  --prohendingfontstyle: '';
  --paragraphfontstyle: '';
}
.JobsBody .header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}

.JobsBody .header .left {
  display: flex;
  align-items: center;
}

.JobsBody .instructions {
  margin-bottom: 15px;
  font-size: 14px;
  color: var(--paracolor);
  font-size: var(--paragraphfontsize);
  font-style: var(--paragraphfontstyle);
}

.JobsBody .boards {
  margin-bottom: 25px;
  display: flex;
}

.JobsBody .boards .board {
  height: 90px;
  width: 120px;
  position: relative;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.JobsBody .boards .label {
  text-align: center;
  color: white;
  padding: 3px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.JobsBody .header h2 {
  margin-right: 10px;
}
.JobsBody .header h3 {
  margin-right: 10px;
}

.JobsBody .header button {
  font-size: 12px;
}

.JobsBody .jobCont {
  margin-bottom: 10px;
}

.JobsBody .jobCont:last-of-type {
  margin-bottom: 0px;
}

.JobsBody .jobTitle {
  font-weight: 800;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ddd;
  padding: 0 10px;
  border-radius: 4px;
  background-color: #ddd;
  font-style: var(--prohendingfontstyle);
  color: var(--productcolor);
}

.JobsBody .jobTitle button {
  background: transparent;
  color: black;
  font-size: 20px;
}

.JobsBody .appsCont {
  padding: 5px 10px;
  border-radius: 5px;
}

.JobsBody .appsCont .app {
  border: 1px solid #f4f4f4;
  border-radius: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-flow: column;
  background-color: #f4f4f4;
}

.JobsBody .appsCont .app.expanded {
  border: 1px solid black;
}

.JobsBody .appsCont .app .bottom {
  padding: 10px;
  padding-top: 0;
  transform-origin: top;
}

.JobsBody .appsCont .app .top {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
}

.JobsBody .appsCont .app .top.notReviewed {
  font-weight: 700;
}

.JobsBody .appsCont .app .top.reviewed {
  font-weight: 200;
}

.JobsBody .appsCont .app .top .nameCont {
  display: flex;
  align-items: center;
}

.JobsBody .appsCont .app .top .nameCont .email {
  font-size: 10px;
  border: 1px solid green;
  border-radius: 5px;
  padding: 3px;
  color: green;
  text-align: center;
}

.JobsBody .appsCont .app .top .nameCont .email.notsent {
  border: 1px solid red;
  color: red;
}

.JobsBody .appsCont .app .top .name {
  margin-right: 20px;
  font-size: 18px;
}

.JobsBody .appsCont .app.expanded .top .name {
  font-size: 30px;
  line-height: 1;
}

.JobsBody .appsCont .app:nth-of-type(2n) {
  background-color: white;
}

.JobsBody .appsCont .app:last-of-type .top {
  margin-bottom: 0px;
}

.JobsBody .appsCont .app.nonexpanded .top:hover {
  background-color: #eae9e9;
}

.JobsBody .appsCont .loadMore {
  margin-top: 10px;
  text-align: center;
}

.JobsBody table tr td:first-of-type {
  width: 200px;
}

.JobsBody .bottom .title {
  font-weight: bold;
  font-size: 20px;
  margin: 20px 0 10px 0;
}

.JobsBody .bottom .multi table {
  margin-bottom: 10px;
}

.JobsBody .bottom .multi table:last-of-type {
  margin-bottom: 0px;
}

.JobsBody .bottom .disclaimer {
  font-size: 16px;
  font-style: italic;
  margin: 15px 0 5px 0;
}

.JobsBody .bottom .jobInfoUpper {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.JobsBody .bottom .topbuts.email {
  margin-bottom: 15px;
}

.JobsBody .bottom .topbuts.resume {
  font-weight: 800;
}

.JobsBody .bottom .topbuts.resume a,
.JobsBody .bottom .topbuts.resume.none {
  border: solid 1px black;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  padding: 5px;
}

.JobsBody .bottom .topbuts.resume a img {
  width: 30px;
}

.JobsBody .bottom .topbuts.resume a span {
  margin-left: 5px;
}

.JobsBody .rightSide {
  display: flex;
  align-items: center;
}

.JobsBody .app .rightSide .reviewed {
  margin-right: 3px;
}

.JobsBody .app.expanded .rightSide .reviewed {
  display: none;
}

.JobsBody .app .ur .revd {
  font-weight: bold;
}

.Indeed .contactName {
  display: flex;
  align-items: center;
}

.JobBoard .textinput {
  display: flex;
  align-items: center;
}

.JobBoard .textinput label {
  margin-right: 10px;
}

@media only screen and (max-width: 768px) {
  .JobsBody .jobTitle {
    font-size: 20px;
  }

  .JobsBody .rightSide .sourceTime {
    text-align: right;
  }

  .JobsBody table tr td:first-of-type {
    width: 120px;
  }

  .JobsBody .appsCont .app.expanded .top .name {
    font-size: 22px;
  }
}
