.FormPageRoot {
  background: linear-gradient(180deg, #002e6c 200px, #f2fafd 0);
  padding: 10px;
  padding-top: 120px;
  flex: 1;
  background-color: #f2fafd;
}

.FormPageRoot .contentOuter {
  max-width: 800px;
  margin: 0 auto;
}

.FormPageRoot .contentInner {
  background-color: white;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}

.FormPageRoot .footer {
  height: 70px;
}

.FormPageRoot .nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.FormPageRoot .nav .content {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.FormPageRoot .nav .rightSide .backto a {
  color: white;
  font-weight: 800;
}

.FormPageRoot .nav .logo {
  width: 120px;
}

.FormPageRoot .nav .logo img {
  width: 100%;
  display: block;
}

@media only screen and (max-width: 768px) {
  .FormPageRoot .nav .rightSide .backto a {
    font-size: 14px;
  }

  .FormPageRoot .footer {
    height: 50px;
  }
}
