.ein-report .heading {
  padding: 40px 0;
}

.ein-report .heading h1 {
  font-size: 4rem;
}

.report-container {
  width: 85%;
  margin: 0 auto;
  margin-bottom: 40px;
}

.report-table {
  position: relative;
}

.report-table table,
.report-table th,
.report-table td {
  border: none;
  /* border-bottom: 1px solid rgb(209, 209, 209); */
}

.report-table td {
  border: none;
  border-bottom: 2px solid rgb(209, 209, 209);
  font-size: 19px;
}

.report-table table thead {
  background-color: rgb(224, 224, 224);
}

.report-table table thead th {
  font-weight: 600;
  font-size: 22px;
  text-align: left;
}

.report-table table tbody tr:nth-child(even) {
  background-color: unset;
}

.report-table .table {
  height: 400px;
  overflow: auto;
}

.report-table .table::-webkit-scrollbar {
  width: 15px;
}

.report-table .table::-webkit-scrollbar-track {
  background-color: rgba(224, 224, 224, 0.363);
}

.report-table .table::-webkit-scrollbar-thumb {
  background: #a0a0a0;
  border-radius: 10px;
}

.report-table .table::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.report-table .pagination {
  padding: 15px 0;
}

.pagination {
  text-align: right;
}

.report-card-row {
  display: flex;
}

.report-card {
  width: 33%;
  padding: 15px;
}

.report-card .item-card {
  border-radius: 5px;
  padding: 20px;
  display: flex;
  border: 1px solid #ddd;
}

.report-card .item-card .icon {
  width: 50px;
  padding: 50px;
}

.report-card .item-card .icon.company-icon {
  width: 130px;
  padding: 10px;
}

.report-card .item-card .icon.employee-icon {
  width: 100px;
  padding: 25px;
}

.report-card .item-card .icon img {
  width: 100%;
}

.report-card .item-card h5 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  line-height: 1.3;
  color: #262b40;
  font-size: 1.25rem;
}

.report-card .item-card h2 {
  text-align: left;
}

.report-card .item-card p {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 14px;
}

.table-report table thead tr {
  border-bottom: 1px solid #000;
  background-color: #f5f8fb;
}

.table-report table tr {
  border-bottom: 1px solid #ddd;
}

.table-report table,
.table-report th,
.table-report td {
  border: none;
  text-align: left;
  padding: 15px 20px;
  white-space: nowrap;
}

.table-report {
  /* height: 350px; */
  width: 100%;
  overflow: auto;
}

.table-report::-webkit-scrollbar {
  width: 15px;
}

.table-report::-webkit-scrollbar-track {
  background-color: rgba(224, 224, 224, 0.363);
}

.table-report::-webkit-scrollbar-thumb {
  background: #a0a0a0;
  border-radius: 10px;
}

.table-report::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.report-pagination {
  text-align: right;
  margin-top: 15px;
}

.report-pagination > span {
  margin: 0 10px;
}

.report-pagination > button {
  margin: 0 10px;
}

.export-btn-container {
  /* text-align: right; */
  padding: 20px 10px;
  position: relative;
}

.btn-export {
  text-align: right;
}

.dropdown-list {
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 50px;
  right: 10px;
}

.dropdown-list li {
  border-bottom: 1px solid #ddd;
  padding: 15px 50px;
  cursor: pointer;
}

.dropdown-list li:hover {
  background-color: #ddd;
}

.option-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.graph-item {
  padding: 0 !important;
}

button:disabled {
  background-color: #a0a0a0;
}

.curser-loading {
  cursor: progress;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.progress-bar.loading::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  animation-name: example;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

@keyframes example {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.inner-drop-down {
  position: absolute;
  top: 0;
  right: 100%;
  background-color: #fff;
  list-style: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 0;
  display: none;
}

.inner-drop-down > li {
  white-space: nowrap;
}

.dropdown-list > li {
  position: relative;
}

.dropdown-list > li:hover .inner-drop-down {
  display: block;
}
