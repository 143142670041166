:root {
  --primaryColor: #002e6c;
  --productcartbackcolor: #eeeeee;
  --productcolor: #000;
  --background: #f0f3f4;
  --bgcolor: #f0f3f4;
  --paracolor: #000;
  --paragraphfontsize: 16px;
  --proheadingfontsize: 20px;
  --prohendingfontstyle: '';
  --paragraphfontstyle: '';
}
.BaseProductSetting, .prodSettings {
  position: relative;
}

.BaseProductSetting .title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
  color: var(--productcolor);
}

.BaseProductSetting .tip {
  font-style: var(--paragraphfontstyle);
  font-size: var(--paragraphfontsize);
  margin-bottom: 10px;
  color: var(--paracolor);
}

.BaseProductSetting input {
  min-width: 300px;
}

.WOTCConstantsSetting .section {
}

.WOTCConstantsSetting .section .header {
  font-weight: 800;
  margin-bottom: 5px;
}

.WOTCConstantsSetting .section:first-of-type {
  margin-bottom: 20px;
}

.WOTCConstantsSetting .formCont {
  margin-left: 20px;
}

.WOTCConstantsSetting .formCont .formRow {
  display: flex;
  align-items: center;
  margin: 5px 0;
  max-width: 600px;
}

.WOTCConstantsSetting .formCont .formRow input {
  flex: 1;
}

.WOTCConstantsSetting .formCont .formRow label {
  margin-right: 10px;
}
