.loginBox {
  margin: 0 auto;
  padding: 10px;
  width: 90%;
  max-width: 500px;
}

.loginBox .logo img {
  width: 300px;
  display: block;
  margin: 0 auto;
}

.loginBox .header {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin: 20px 0;
}

.loginBox .error {
  font-weight: 600;
  text-align: center;
  margin: 15px 0;
}

.loginBox .form .row {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.loginBox .form .row:last-of-type {
  margin-bottom: 0px;
}

.loginBox .form .row > div {
  padding: 3px;
}

.loginBox .form .row .label {
  width: 100px;
  text-align: center;
  line-height: 1;
}

.loginBox .form .row .input {
  flex: 1;
}

.loginBox .form .row .input input {
  width: 100%;
  border: 0;
}

.loginBox .form .submit {
  margin: 10px 0;
  text-align: center;
}

.loginBox .admin {
  margin-top: 20px;
  text-align: right;
}
