.WOTCForm {
  display: flex;
  flex-wrap: wrap;
}

.WOTCForm .pageTitle {
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  padding: 20px 0;
  width: 100%;
}

.WOTCForm .loadinGif {
  width: 120px;
  align-self: center;
}

.WOTCForm input:focus {
  border: solid 1px #002e6c;
}

.WOTCForm > div {
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  margin: 10px 0;
}

.WOTCForm > div.required label {
  font-weight: 900;
  color: red;
}

.WOTCForm .title {
  font-size: 20px;
  font-weight: 800;
  margin-top: 15px;
}

.WOTCForm .title:first-of-type {
  margin-top: 0px;
}

.WOTCForm .subTitle {
  font-size: 16px;
  font-weight: 800;
}

.WOTCForm .lineBreak {
  margin: 10px 0;
}

.WOTCForm .lineBreak > div {
  width: 60%;
  height: 2px;
  background-color: #002e6c;
  margin: 0px auto;
}

.WOTCForm .text100,
.WOTCForm .text50,
.WOTCForm .textSmallField {
  display: flex;
  align-items: center;
}

.WOTCForm .text50,
.WOTCForm .state {
  width: 50%;
}

.WOTCForm .text100 label,
.WOTCForm .text50 label {
  margin-right: 5px;
}

.WOTCForm .text100 input,
.WOTCForm .text50 input {
  flex: 1;
}

.WOTCForm .textSmallField label {
  flex: 1;
}

.WOTCForm .check {
  display: flex;
}

.WOTCForm .check .descCont {
  flex: 1;
  font-size: 14px;
}

.WOTCForm .check .descCont ul {
  margin: 5px 0;
}

.WOTCForm .check .checkboxCont {
  margin-right: 5px;
}

.WOTCForm .check .checkboxCont input {
  cursor: pointer;
}

.WOTCForm .saveCont {
  text-align: center;
  width: 100%;
}

.WOTCForm .saveCont {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #002e6c;
  width: 100vw;
  height: 50px;
  z-index: 12;
}

.slideUp-enter {
  transform: translateY(100%);
}

.slideUp-enter.slideUp-enter-active {
  transform: translateY(0);
  transition: all 200ms;
}

.slideUp-leave {
  transform: translateY(0);
}

.slideUp-leave.slideUp-leave-active {
  transform: translateY(100%);
  transition: all 200ms;
}

.WOTCForm .saveCont button {
  font-size: 20px;
  font-weight: 800;
  width: 100%;
  height: 100%;
}

.WOTCForm .successIndicator {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #0cad4d;
  width: 100vw;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  font-weight: 800;
  z-index: 15;
}

.WOTCForm .remainingFieldsIndicator {
  position: fixed;
  bottom: 0;
  background-color: red;
  color: white;
  font-size: 16px;
  font-weight: 400;
  padding: 5px 10px;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

.WOTCForm .complete {
  color: #0cad4d;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
}

.WOTCForm .noncomplete {
  color: red;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
}

.WOTCForm .complete.top {
  text-align: right;
  font-size: 14px;
  padding: 0;
  margin: 0;
  padding: 3px;
}

.WOTCForm .signature .label {
  font-size: 20px;
  margin-bottom: 10px;
}

.WOTCForm .required .signature .label {
  color: red;
  font-weight: 800;
}

.WOTCForm .signature input[type="checkbox"] {
  cursor: pointer;
}

.WOTCForm .signature .agree {
  display: flex;
  margin: 5px 0;
}

.WOTCForm .signature .agree div {
  flex: 1;
}

.WOTCForm .signature .agree input {
  margin-right: 10px;
}

.WOTCForm .signature .sign {
  margin: 10px 0 5px 0;
}

.WOTCForm .signature .sign label {
  display: block;
  margin-bottom: 5px;
}

.WOTCForm .signature .sign input {
  width: 100%;
}

.WOTCForm .signature .submit {
  margin-top: 10px;
}

@media only screen and (max-width: 768px) {
  .WOTCForm .text50,
  .wotcForm .state {
    width: 100%;
  }

  .WOTCForm .text100,
  .WOTCForm .text50,
  .WOTCForm .textSmallField {
    flex-flow: column;
    justify-content: flex-start;
    align-items: stretch;
  }

  .WOTCForm .saveCont button {
    font-size: 16px;
  }

  .WOTCForm .remainingFieldsIndicator {
    font-size: 14px;
  }
}
