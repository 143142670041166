.WebclockAttestation .buts {
    margin-top: 10px;
}

:root {
    --primaryColor: #002e6c;
    --productcartbackcolor: #eeeeee;
    --productcolor: #000;
    --background: #f0f3f4;
    --bgcolor: #f0f3f4;
    --paracolor: #000;
    --paragraphfontsize: 16px;
    --proheadingfontsize: 20px;
    --prohendingfontstyle: '';
    --paragraphfontstyle: '';
  }

.WebclockAttestation .steps {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.WebclockAttestation .step {
    border: solid 1px black;
    padding: 5px;
    margin: 10px;
    border-radius: 5px;
    min-width: 300px;
}

.WebclockAttestation .step > div {
    margin-bottom: 15px;
}

.WebclockAttestation .step > div:last-of-type {
    margin-bottom: 0px;
}

.WebclockAttestation .step .stepHeader {
    display: flex;
    justify-content: space-between;
}

.WebclockAttestation .step .stepHeader .firstStepButton {
    font-size: 13px;
    color: var(--paracolor);
    font-style: var(--paragraphfontstyle);
}

.WebclockAttestation .step .stepLabel {
    font-weight: 800;
    color: var(--productcolor);
    font-style: var(--proheadingfontsize);
}

.WebclockAttestation .step .buts {
    margin-top: 10px;
}

.WebclockAttestation .step .outcomes .cont {
    padding-left: 10px;
}

.WebclockAttestation .step .outcomes .outcome {
    margin-bottom: 15px;
    border: 1px solid rgb(218, 218, 218);
    padding: 5px;
}

.WebclockAttestation .step .outcomes .outcome > div {
    margin-bottom: 7px;
}

.WebclockAttestation .step .outcomes .outcome > div:last-of-type{
    margin-bottom: 0px;
}

.WebclockAttestation .step .outcomes .outcome .value {
    display: flex;
    align-items: center;
}

.WebclockAttestation .step .condition {
    display: flex;
    align-items: center;
    padding-left: 20px;
    margin: 3px 0;
}

.WebclockAttestation .step .condition span {
    display: flex;
    align-items: center;
}

.WebclockAttestation .step .condition input[type="text"] {
    min-width: 40px;
    width: 40px;
    text-align: center;
    padding: 3px;
    margin: 0 3px;
}

.WebclockAttestation .step textarea {
    min-width: 300px;
    min-height: 100px;
    font-size: 13px;
    resize: none;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid rgb(218, 218, 218);
}

.WebclockAttestation .step input[type="text"] {
    font-size: 12px;
}

.WebclockAttestation .step select {
    font-size: 13px;
}

.WebclockAttestation .step {
    font-size: 13px;
}

.WebclockAttestation .step button {
    font-size: 12px;
}