:root {
  --my-color: #002e6c;
  --color: #ffffff;
  --primaryColor: #002e6c;
  --productcartbackcolor: #eeeeee;
  --productcolor: #000;
  --background: #f0f3f4;
  --bgcolor: #f0f3f4;
  --paracolor: #000;
  --paragraphfontsize: 16px;
  --proheadingfontsize: 20px;
  --prohendingfontstyle: '';
  --paragraphfontstyle: '';
}

.selectSetting {
  display: flex;
  align-items: center;
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.selectSetting label {
  margin-right: 10px;
}

.textSetting {
  display: flex;
  align-items: center;
}

.domainSetting input {
  border: 1px solid rgb(218, 218, 218);
}

.domainSetting {
  margin-top: 10px;
}

.domainSetting label {
  margin-right: 10px;
}

.enSetting .example {
  margin-top: 5px;
  font-style: italic;
}

.enSetting .error {
  font-weight: bold;
  margin-bottom: 5px;
}

.nodeWithXCont {
  display: flex;
  flex-wrap: wrap;
}

.nodeWithX {
  margin: 5px;
  padding: 5px;
  border: solid 1px black;
  border-radius: 5px;
}

.nodeWithX button {
  margin-left: 5px;
}

.IPRestrictionSetting .list {
  margin-bottom: 10px;
}

.IPRestrictionSetting .addMore {
  display: flex;
  align-items: center;
}

.IPRestrictionSetting .addMore .inputs {
  display: flex;
  margin: 0 10px;
}

.IPRestrictionSetting .addMore .inputs input[type="text"] {
  width: 43px;
  margin: 0 5px;
  min-width: 0;
}

.WebclockInputs.list {
  display: flex;
  flex-wrap: wrap;
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.WebclockInputs.list .nodeWithX {
  cursor: pointer;
}

.WebclockInputs.list .nodeWithX.chosen {
  background-color: var(--my-color);
  color: white;
}

.WebclockActions .action {
  padding: 5px;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 5px;
}

.WebclockActions .action .name {
  margin-bottom: 5px;
  font-weight: 700;
}

.WebclockActions .action .main {
  padding-left: 10px;
}

.WebclockActions .textInput {
  display: flex;
  align-items: center;
}

.WebclockActions .textInput label {
  margin-right: 10px;
}

.WebclockActions .save {
  margin: 10px;
}

.WebclockActions .ccs .hea {
  margin: 5px 0;
  text-decoration: underline;
}

.WebclockActions .ccs .ccCont {
  padding-left: 20px;
}

.WebclockActions .ccs .ccCont > div {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.WebclockActions input[type="checkbox"] {
  cursor: pointer;
}
