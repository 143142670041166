:root {
    --primaryColor: #002e6c;
    --productcartbackcolor: #eeeeee;
    --productcolor: #000;
    --background: #f0f3f4;
    --bgcolor: #f0f3f4;
    --paracolor: #000;
    --paragraphfontsize: 16px;
    --proheadingfontsize: 20px;
    --prohendingfontstyle: '';
    --paragraphfontstyle: '';
    --dynamicwidth: '';
    --dynamicheight: '';
    --my-color: #002e6c;
    --color: #ffffff;
}

.dash-outer {
    /* margin: 40px; */
    margin-top: 25px;
}

.dash-inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 25px;
}


.dash-card,
.servicerap-outer,
.othercontact-outer {
    padding: 14px;
    border-radius: 12px;
    width: calc(calc(100% - 50px) / 3);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
}


.dash-card h3 {
    margin: 0px 0px 10px 0px;
    color: var(--my-color);
    font-size: 24px;
}

.dash-card button {
    border: 1px solid #0dc76f;
    background-color: #0dc76f0a;
    padding: 8px 20px;
    color: #0dc76f;
    border-radius: 6px;
}

.servicerap-outer {
    color: var(--paracolor);
    display: flex;
    justify-content: space-between;
}

.servicerap-outer h3 {
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: var(--productcolor);
}

.servicerap-outer h4 {
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: var(--productcolor);
}

.servicerap-outer p {
    margin: 10px 0px;

}



.servicerap-inner {
    margin: 10px 0px;
}

.servicerap-inner label {
    font-size: 16px;
    font-weight: 600;
    width: 100px;
    display: inline-block;
    color: var(--productcolor);
}

.servicerap-inner span {
    color: var(--paracolor);
}

.othercontact-btn button span {
    font-size: 17px;
}



.othercontact-outer h3 {
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: var(--productcolor);
    /* margin-right: 34%; */

}

.othercontact-btn {
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
    gap: 15px;
    flex-direction: column;
    align-items: center;
}




.othercontact-btn button {
    padding: 15px 20px;
    padding-left: 30px;
    border-radius: 6px;
    width: 200px;
    border-radius: 40px;
    text-align: left;
}

.dash-outer .dash-card button:hover {
    background: #0dc76f;
    color: #fff;
}

.othercontact-outer p {
    margin: 10px 0px;
    color: var(--paracolor);
}

/* .othercontact-btn button:hover {
    background: var(--productcolor);
} */

.othercontact-btn button:active {
    background: var(--productcolor);
}

.cal_hole {
    background-color: #ffffff;
    border-radius: 93px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
    height: 20px;
    width: 20px;
}

.cal_hole_left {
    background-color: #ffffff;
    border-radius: 93px;
    height: 20px;
    width: 20px;
}

.date_tag {
    font-size: 60px !important;
    color: var(--productcolor);
}

.announcement-outer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    gap: 20px;
    /* flex-wrap: wrap; */
}

.announcement-left h3 {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 35px;
    color: var(--productcolor);
}

.announcement-left-box {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    border: 1px solid #d9d9d9;
    padding: 15px 20px;
    border-radius: 10px;
    margin-top: 20px;
    height: 430px;
}

.announcementleft-inner {
    display: flex;
    grid-gap: 50px;
    gap: 20px;
    margin-top: 10px;
}

.announcementleft-inner input {
    border: 1px solid #d9d9d9;
    padding: 10px;
    border-radius: 8px;
}

.announcementleft-inner p {
    font-size: 18px;
    width: 100%;
    max-width: 870px;
    text-align: justify;
    margin-top: 0px;
    color: var(--paracolor);
}

.announcement-right,
.hot_topics {
    display: flex;
    flex-direction: column;
    gap: 15px;
    border: 1px solid #d9d9d9;
    padding: 15px 20px;
    border-radius: 10px;
    margin-top: 20px;

}

.announcement-right,
.cal-box {
    width: calc(calc(100% - 25px) / 3);
}

.Banner-right {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    border: 1px solid #d9d9d9;
    padding: 15px 20px;
    border-radius: 10px;
    width: 100%;
    margin-top: 38px;
    height: 450px;
    margin-bottom: 30px;
}

.announcement-right button {
    padding: 15px 20px;
    border-radius: 6px;
}

.hot-fieldbtn {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 15px;
}

.announcement-right a {
    color: var(--productcolor);
}

.announcement-calender {
    width: 25%;
    border-radius: 12px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
    height: 190px;
}

.announcementcalend-head {
    background-color: var(--my-color);
    color: #fff;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 14px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.projecttimeline-outer {
    margin-top: 20px;
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
    margin-bottom: 25px;
}

.projecttimeline-left h3 {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 15px;
    color: var(--productcolor);
}

.projecttimeline-left p {
    font-size: 16px;
    color: #8b8b8b;
    width: 100%;
    max-width: 700px;
    text-align: justify;
}

.announcementcalend-head h4 {
    font-size: 25px;
}

.projecttimeline-table {
    width: 100%;
}

span.arrow-rightWrapper {
    padding-right: 10px;
    margin-left: 15px;
}

.arrow-color {
    color: var(--my-color) !important;
}

.projecttimeline-table table thead tr th {
    padding: 15px;
    color: #fff;
    background: var(--my-color);
}

.projecttimeline-table table tbody tr td {
    padding: 10px;
    text-align: center;
    font-size: 16px;
}

.hot_topics h3 {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 15px;
    color: var(--productcolor);
}

.hot_topics ul {
    padding-left: 0px;
    /*  border-radius: 8px;
    border: 1px solid #d9d9d9; */
    color: var(--paracolor);
}

.hot_topics ul li {
    list-style: none;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
}

.hot_topics ul li:last-child {
    border: none;
}

.hot_topics ul li p {
    padding: 10px 5px 10px 5px;
    margin: 11px 0px;
}

.announcementcalend-head h4 {
    margin: 8px 0;
}

span.arrow-rightWrapper svg {
    color: var(--productcolor)
}

h1.date_tag {
    padding: 15px 0 20px 0;
}

.hot_topics,
.announce_box {
    width: calc(calc(calc(100% - 25px) / 3)* 2);
}

.projecttimeline-left {
    width: 65%;
}

.projecttimeline-table {
    width: 100%;
}

.images_dash {
    width: 150px;
    padding: 5px;
}

.hot_field {
    height: 320px;
    overflow-y: auto;
}

.hot_fields {
    height: 320px;
    overflow-y: auto;
}

.hot_topic_heading {
    display: flex;
    justify-content: space-between;
    background-color: var(--my-color);
    /* margin: 17px 15px 0px 15px; */
    border-radius: 8px;
}

.anc-edit-btn {
    margin-top: 100%;
}

.anc-edit-btn button {
    height: 35px;
    width: 80px;
}

.hot_input_field {
    padding: 12px 10px 12px 50px !important;
    border-radius: 8px !important;
    width: 95%;
    height: 50px;
    /* margin-left: 15px !important; */
    margin-bottom: 15px;
}

.announcement-right_form {
    display: flex;
    justify-content: space-between;
    padding: 4px !important;
    cursor: auto !important;
}

.topic_close {
    margin: auto;
    font-size: 21px;
    margin-right: inherit;
    padding: 10px;
    color: #ffffff;
    cursor: pointer;
}

.calendar_btn {
    display: flex;
    /* width: 100%; */
    margin: 18px 1px 20px 1px;
    padding: 0px !important;
    border-radius: 10px;
}

.edit-icon-btn-calendar {
    color: var(--productcolor);
    width: 40px;
    font-size: 17px;
}

.cal_head {
    display: flex;
    justify-content: space-between;
    background: var(--my-color);
    border-radius: 8px;
    width: 32%;
}

.cal_head p {
    color: #ffffff;
    margin-left: 10px;
}

.salesforce_silider {
    height: 410px;
    width: 100%;
    margin-top: 12px;
    /* cursor: pointer; */
    border-radius: 10px 10px 10px 10px;
    margin-bottom: 30px;
}

.pen-banner {
    cursor: pointer;
}

.slider_li .slick-dots li {
    display: none;
}

.sales_text {
    overflow-y: auto;
    height: 260px;
}

.sales_text p {
    padding: 0px 15px 0px 0px
}

.head_dash {
    display: flex;
    justify-content: space-between;
    background: var(--my-color);
    border-radius: 8px;
}

.head_dash p {
    margin-left: 15px;
    color: #ffffff;
}


/* .btn-other {
    margin-left: -28px;
}

.btn-others {
    margin-left: -38px;
} */

.hottopic-btn {
    margin-left: 20px;
    height: 45px;
    width: 10%;
    border-radius: 5px 5px 5px 5px;
}

.cal-anc {
    margin-top: 20px;
    display: flex;
    grid-gap: 25px;
    gap: 25px;
    justify-content: space-between;
    margin-bottom: 25px;
    align-items: center;
}

.cal-head-anc {
    background-color: var(--my-color);
    margin-left: 15p;
    border-radius: 5px;
    color: #fff;
}

.save_btn {
    margin: 10px 10px 0px 10px;
}

/* .image_style {
    display: flex !important;
    justify-content: end !important;
    margin-top: -150px !important
} */
.sale_form {
    padding: 12px 10px 12px 50px !important;
    border-radius: 8px !important;
    width: 93%;
    height: 50px;
    margin-bottom: 15px;
}

.salestextarea {
    width: 100% !important;
    border: 1px solid rgb(218, 218, 218);
    border-radius: 3px;
}


.salesforce_modal {
    /* min-height: 460px; */
    width: 100%;
    background-color: #ffffff;
    padding: 12px;
}

.sales_input {
    width: 100%;
    margin: 10px 10px 10px 0px;
    border-radius: 5px 5px 5px 5px;
    height: 40px;
    font-size: 16px;
    display: block;
    padding: 5px;
    box-sizing: border-box;
    border: 1px solid rgb(218, 218, 218);
}

.salesforcemodal {
    width: 25%;
}

.salesforce_modal label {
    margin-left: 10px;
}

.saleforse-btn {
    margin-left: 10px;
}

.saleforse-btn button {
    height: 35px;
    width: 70px;
    font-size: 15px;
    margin-top: 10px;
}

.bannerimage-container {
    position: relative;
}


.editbanner-icon {
    position: absolute;
    z-index: 11;
    top: 24px;
    left: 12px;
    padding: 4px;
    margin: 0px;
}

.editbanner-msg {
    position: absolute;
    margin: 0px;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
}


.annouce-remember {
    flex-direction: column-reverse;

}

.annouce-remember .sync_history_mar {
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.annouce-remember .start_date_dash {
    margin-bottom: 0px;
    margin-right: auto;
}

.annouce-remember .anc-edit-btn {
    margin-top: 0px;
}


@media screen and (max-width: 1885px) {


    .hottopic-btn {
        width: 25%;
    }

    .announcement-right,
    .hot_topics,
    .cal-box,
    .announce_box {
        width: calc(calc(100% - 25px) / 2);
    }

    .projecttimeline-left {
        width: 100%;
    }





    .announcement-calender {
        width: 100%;
    }
}

@media screen and (max-width: 1366px) {
    .announcement-outer {
        flex-wrap: wrap;
    }

    .announcement-left {
        width: 66%;
    }

    .announcement-right {
        width: 32%;
    }

    .hottopic-btn {
        width: 25%;
    }

    .announcement-left {
        width: 66%;
    }

    .projecttimeline-left {
        width: 66%;
    }

    .projecttimeline-table {
        width: 100%;
    }


    .announce_box {
        width: calc(calc(100% - 25px) - 36%);
    }

    .cal-box {
        width: 36%;
    }

    .hot_topics ul {
        width: 100%;
        /* height: 385px;
        overflow: auto; */
    }

    .hot_topics {
        width: 32%;
    }

    .announcement-calender {
        width: 100%;
    }
}

@media screen and (max-width: 1024px) {

    .dash-card,
    .servicerap-outer {
        width: calc(calc(100% - 20px) / 2);
    }


    .othercontact-outer {
        width: 100%;
    }

    .announcement-left-box,
    .hot_topics,
    .announcement-right,
    .othercontact-outer,
    .dash-card,
    .servicerap-outer {
        padding: 12px;
    }

    .othercontact-outer h3,
    .othercontact-outer p {
        padding-left: 0px;
    }

    .othercontact-btn {
        flex-direction: row;
        margin-bottom: 11px;
        gap: 10px;
    }

    .othercontact-btn button {
        width: calc(calc(100% - 20px) / 3);
        padding: 12px;
        text-align: center;
    }


    .cal-anc {
        flex-direction: column;
    }

    .announce_box {
        width: 100%;
    }

    .cal-box {
        width: 100%;
    }

    .hottopic-btn {
        width: 25%;
    }

    .projecttimeline-table {
        width: 100%;
        overflow: auto;
    }

    .announcement-left {
        width: 100%;
    }

    .announcement-right {
        width: 100%;
        height: 290px;
    }

    .projecttimeline-left {
        width: 100%;
    }

    .hot_topics {
        width: 100%;
        margin-top: 15px;
    }

    .announcement-calender {
        width: 100%;
    }
}


@media screen and (max-width: 576px) {

    .dash-card,
    .servicerap-outer {
        width: 100%;
    }

    .othercontact-btn {
        flex-direction: column;
        margin-bottom: 11px;
        align-items: flex-start;
    }


    .othercontact-btn button {
        width: 100%;
        text-align: left;
        padding: 16px 24px;
    }

    .hottopic-btn {
        width: 32%;
    }

    .announce_box {
        height: 100%;
    }

    .announcementleft-inner {
        flex-direction: column;
    }

    .announcement-calender {
        width: 100%;
    }
}

/* .servicerap-outer-card{
  display: flex;
  justify-content: space-between;
} */
.projecttimeline-outer .hot_topics {
    margin-top: 20px;
}

.projecttimeline-outer .hot_topics ul {
    margin: 0px;
}

@media screen and (min-device-width: 1025px) and (max-device-width: 1269px) {


    .dash-inner {
        justify-content: start;
    }
}

@media screen and (min-device-width: 1025px) and (max-device-width: 1366px) {
    .hot_topics {
        width: calc(calc(calc(100% - 25px) / 3)* 2);
    }

    .announcement-right {
        width: calc(calc(100% - 25px) / 3);
    }
}