:root {
  --my-color: #002e6c;
  --color: #ffffff;
  --primaryColor: #002e6c;
  --productcartbackcolor: #eeeeee;
  --productcolor: #000;
  --background: #f0f3f4;
  --bgcolor: #f0f3f4;
  --paracolor: #000;
  --paragraphfontsize: 16px;
  --proheadingfontsize: 20px;
  --prohendingfontstyle: '';
  --paragraphfontstyle: '';
}
.baseIntegration h1 {
  text-align: left;
  padding: 10px 0 20px 0;
}

.baseIntegration .head {
  display: flex;
  justify-content: space-between;
}

.baseIntegration .head h1 {
  flex: 1;
}

.baseIntegration .head .img {
  width: 220px;
  position: relative;
}

.baseIntegration .head .img img {
  width: 100%;
  position: absolute;
  z-index: 0;
}

.baseIntegration h2 {
  text-align: left;
  padding: 0;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.baseIntegration .HideShow h2 {
  margin-bottom: 0;
}

.baseIntegration .HideShow .top {
  margin-bottom: 5px;
}

.baseIntegration h2 span {
  margin-left: 10px;
}

.baseIntegration h2 .hint {
  font-size: 12px;
  align-self: flex-end;
  font-weight: normal;
}

.baseIntegration h2 span button {
  font-size: 12px;
}

.baseIntegration .intSect {
}

.baseIntegration .intSect .sect {
  margin-bottom: 30px;
}

.baseIntegration .intSect .status {
  font-weight: bold;
}

.baseIntegration .intSect .status .good {
  color: #0cad4d;
}

.baseIntegration .intSect .status .bad {
  color: red;
}

.baseIntegration .intSect .status .connect {
  margin-top: 5px;
}

.baseIntegration .intSect .status .connected .connect button {
  font-size: 12px;
}

.tableCont {
  width: 100%;
  overflow-x: auto;
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid rgb(218, 218, 218);
  position: relative;
}

.tableCont.loading {
  overflow: hidden;
}

.tableCont .tableLoading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

table {
  width: 100%;
  font-size: 14px;
  border-spacing: 5px;
}

table,
th,
td {
  border: 1px solid rgb(218, 218, 218);
  border-collapse: collapse;
  box-sizing: border-box;
}

th,
td {
  padding: 10px 5px;
}

table tbody tr:nth-child(even) {
  background-color: rgb(238, 238, 238);
}

table tbody tr:nth-child(odd) {
  background-color: #fff;
}

td.success.col,
td.SUCCESS.col {
  background-color: #0cad4d;
  color: white;
}

td.WAITING.col,
td.WAITING.col {
  background-color: #f6fa00;
  color: #000;
}

td.failed.col,
td.FAILED.col,
td.FAIL.col {
  background-color: red;
  color: white;
}

.baseIntegration .settings .settingRow {
  margin-bottom: 10px;
}

.baseIntegration .breadcrumbs {
  margin-bottom: 10px;
}

.breadcrumbs span{
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.access_para{
  color: var(--paracolor);
  font-size: var(--paragraphfontsize);
  font-style: var(--paragraphfontstyle);
}

.baseIntegration .documentationBody .subHead {
  margin: 5px 0 10px 0;
  font-size: 18px;
  font-weight: bold;
}

.baseIntegration .documentationBody ul {
  margin-top: 0;
}

@media only screen and (max-width: 1000px) {
  .baseIntegration .head .img img {
    width: 100%;
    position: relative;
  }

  .baseIntegration .head .img {
    width: 200px;
  }
}

@media only screen and (max-width: 520px) {
  .baseIntegration .head {
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
  }

  .baseIntegration .head .img {
    width: 150px;
  }
}

.integration-table {
  padding: 10px 0;
}

.integration-table > .header-action {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  margin-right: 10px;
}
.pagination-container {
  padding: 0;
  display: flex;
  list-style-type: none;
  justify-content: flex-end;
}

.pagination-container > li a {
  display: inline-block;
  padding: 10px;
  background-color: var(--my-color);
  color: #fff;
  border: 1px solid var(--my-color);
  border-radius: 4px;
  margin: 0 5px;
}

.pagination-container > li.active a {
  background-color: #fff;
}

.pagination-container > li.disabled a {
  background-color: rgba(33, 37, 41, 0.75);
  border-color: rgba(33, 37, 41, 0.75);
}

.pagination-container > li.active a {
  background-color: #fff;
  color: #000;
}

/* .pagination-container > li:first-child {
  border-radius: 4;
} */

.icon-btn {
  display: inline-block;
  cursor: pointer;
  transition: 0.3s;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  font-size: 25px;
}
