:root {
  --my-color: #002e6c;
  --color: #ffffff;
}
:root {
  --primaryColor: #002e6c;
  --productcartbackcolor: #eeeeee;
  --productcolor: #000;
  --background: #f0f3f4;
  --bgcolor: #f0f3f4;
  --paracolor: #000;
  --paragraphfontsize: 16px;
  --proheadingfontsize: 20px;
  --prohendingfontstyle: '';
  --paragraphfontstyle: '';
}
.settingsCont {
  position: relative;
}

.settingsCont .settingsRow {
  padding: 10px 0;
  padding-left: 10px;
  border-left: solid 10px var(--my-color);
  margin-bottom: 20px;
}

.settingsCont .unsaved .settingsRow {
  background: rgba(255, 0, 0, 0.1);
  border-left: solid 10px #ff221e;
}

.loadCont {
  background: rgba(255, 255, 255, 0.7);
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.settingsCont .saveAlert {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.settingsCont .saveAlert .box {
  width: 20px;
  height: 20px;
  background-color: #ff221e;
}

.settingsCont .saveAlert span {
  margin-right: 10px;
}

.settingsCont .settingsRow .title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

.settingsCont .tip {
  font-style: italic;
  font-size: 14px;
  margin-bottom: 10px;
}

.settingsCont .buttonCont.top {
  margin-bottom: 20px;
  margin-top: 10px;
}

.settingsCont .buttonCont button {
  margin-right: 20px;
}

.settingsCont .buttonCont button:last-of-type {
  margin-right: 0px;
}

.settingsCont .textSetting .inputCont {
  min-width: 300px;
}

.settingsCont .textSetting .inputCont input {
  width: 100%;
  padding: 5px;
}

.settingsCont .successMsg {
  margin: 10px 0;
  font-weight: bold;
  color: #0cad4d;
}

.settingsCont .successMsg.bad {
  color: red;
}

.settingsCont .note {
  font-size: 14px;
  font-style: var(--paragraphfontstyle);
  margin-top: 5px;
  color: var(--paracolor);
}

.settingsCont input[type="text"] {
  min-width: 300px;
}
