.connector-configuration-input input {
  width: 100%;
}

.connector-configuration .d-flex {
  justify-content: space-between;
  padding-bottom: 15px;
}

.connector-configuration .con-input {
  width: 48%;
}

.connector-configuration .con-input input {
  width: 100%;
}
.btn-configuration {
  padding-top: 20px;
}
.btn-configuration .submitbtncstm {
  background-color: #002e6c !important;
}
.connector-configuration-input label,
.con-input label {
  font-weight: 400;
}
.connector-configuration-input {
  padding-bottom: 15px;
}
