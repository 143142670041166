.Indeed .head .desc {
  font-size: 14px;
  margin-top: 10px;
  font-weight: 400;
}

.Indeed .sponsor {
  margin-bottom: 20px;
}

.Indeed .sponsor .success {
  color: #0cad4d;
  margin: 20px 0;
  font-weight: bold;
}

.Indeed .sponsor .job {
  padding: 10px;
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
  border-radius: 5px;
  margin-bottom: 10px;
}

.Indeed .sponsor .job .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Indeed .sponsor .job .actions button {
  font-size: 12px;
}

.Indeed .sponsor .job .actions button.marg {
  margin-left: 10px;
}

.Indeed .sponsor .job .actions button.good {
  background-color: #0cad4d;
}

.Indeed .sponsor .job .actions button.bad {
  background-color: red;
}

.Indeed .sponsor .job .top .main {
  display: flex;
  align-items: center;
}

.Indeed .sponsor .job:nth-of-type(2n) {
  background-color: white;
}

.Indeed .sponsor .job .title {
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
}

.Indeed .sponsor .job .label {
  border-radius: 5px;
  padding: 3px;
  border-width: 1px;
  border-style: solid;
  font-size: 12px;
}

.Indeed .sponsor .job .label.sponsored {
  border-color: #0cad4d;
  color: #0cad4d;
}

.Indeed .sponsor .job .label.unsponsored {
  border-color: red;
  color: red;
}

.Indeed .sponsor .job .sponsorship {
  margin-top: 5px;
  margin-left: 10px;
  border: solid 1px red;
  border-radius: 5px;
  padding: 3px;
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
}

.Indeed .sponsor .job .sponsorship .main {
  display: flex;
  align-items: center;
}

.Indeed .sponsor .job .sponsorship .status {
  color: white;
  background-color: red;
  font-size: 12px;
  padding: 5px;
  border-radius: 5px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Indeed .sponsor .job .sponsorship.active .status {
  background-color: #0cad4d;
}

.Indeed .sponsor .job .sponsorship.active {
  border-color: #0cad4d;
}

.Indeed .sponsor .job .sponsorship .budget {
  margin-left: 40px;
  width: 120px;
}

.Indeed .sponsor .job .sponsorship .ends {
  text-align: right;
}

.Indeed .modalOuter {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 17;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Indeed .modalBody {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 90vw;
  max-width: 750px;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
  box-sizing: border-box;
}

.Indeed .modalOuter .close {
  position: fixed;
  right: 5px;
  top: 5px;
  width: 25px;
  height: 25px;
  z-index: 11;
  cursor: pointer;
}

.Indeed .modalBody h1 {
  padding-top: 0;
  padding-bottom: 15px;
  font-size: 20px;
  text-align: center;
}

.Indeed .modalBody a {
  color: #1237ee;
}

.Indeed .modalBody .spacebelow {
  margin-bottom: 10px;
}

.Indeed .modalBody .somespacebelow {
  margin-bottom: 5px;
}

.Indeed .modalBody .list {
  margin: 0;
  margin-top: 5px;
}

.Indeed .modalBody .buttoncont {
  text-align: center;
}

.Indeed .modalBody .buttoncont button {
  background-color: #f60;
  font-size: 18px;
}

.Indeed .modalBody .it {
  font-size: 14px;
  color: grey;
  font-style: italic;
}

.Indeed .modalBody .bold {
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .Indeed .sponsor .job .top {
    display: block;
  }

  .Indeed .sponsor .job .top .main {
    margin-bottom: 15px;
  }

  .Indeed .sponsor .job .top .actions {
    text-align: center;
  }

  .Indeed .sponsor .job .sponsorship {
    display: block;
  }

  .Indeed .sponsor .job .sponsorship .actions {
    margin-top: 10px;
    text-align: center;
  }
}
