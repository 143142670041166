.WOTCBody h2 button {
  font-size: 12px;
  margin-left: 10px;
}

.WOTCBody .section .head {
  font-size: 22px;
}

.WOTCBody .CreateApplicant {
  margin-top: 10px;
}

.WOTCBody .tableCont .noapps {
  padding: 10px;
}

.WOTCBody .form {
  max-width: 600px;
  background-color: rgb(238, 238, 238);
  padding: 10px;
  border-radius: 5px;
  position: relative;
}

.WOTCBody .form .loading {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255,255,255,0.8);
  border-radius: 5px;
}

.WOTCBody .form .title {
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 10px;
}

.WOTCBody .form .submit {
  margin-top: 10px;
}

.WOTCBody .form .submit .cancel {
  background-color: white;
  color: black;
  margin-right: 20px;
}

.WOTCBody .form .field {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.WOTCBody .form .field input {
  flex: 1;
}

.WOTCBody .form .field label {
  width: 100px;
}

.WOTCBody .form .error {
  font-weight: 800;
  color: red;
  margin: 10px 0;
}

.WOTCBody .table .col3, .WOTCBody .table .col4, .WOTCBody .table .col5, .WOTCBody .table .col6 {
  width: 150px;
  text-align: center;
}

.WOTCBody .table .col4, .WOTCBody .table .col6 {
  width: 100px;
}

.WOTCBody .table button {
  font-size: 12px;
}

.WOTCBody .modalOuter {
  align-items: flex-start;
  overflow-y: auto;
  padding: 20px;
}

.WOTCBody .modalBody {
  max-height: none;
  width: 100%;
  margin-bottom: 50px;
}

@media only screen and (max-width: 768px) {
  .WOTCBody .modalOuter {
    padding: 10px;
  }
  .modalOuter .close {
    background-color: black;
    border-radius: 16px;
  }
}
