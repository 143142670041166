@import url(https://fonts.googleapis.com/css?family=Roboto:400,700);
.loginBox {
  margin: 0 auto;
  padding: 10px;
  width: 90%;
  max-width: 500px;
}

.loginBox .logo img {
  width: 300px;
  display: block;
  margin: 0 auto;
}

.loginBox .header {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin: 20px 0;
}

.loginBox .error {
  font-weight: 600;
  text-align: center;
  margin: 15px 0;
}

.loginBox .form .row {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.loginBox .form .row:last-of-type {
  margin-bottom: 0px;
}

.loginBox .form .row > div {
  padding: 3px;
}

.loginBox .form .row .label {
  width: 100px;
  text-align: center;
  line-height: 1;
}

.loginBox .form .row .input {
  flex: 1 1;
}

.loginBox .form .row .input input {
  width: 100%;
  border: 0;
}

.loginBox .form .submit {
  margin: 10px 0;
  text-align: center;
}

.loginBox .admin {
  margin-top: 20px;
  text-align: right;
}

.notLoggedInPage {
  padding-top: 50px;
}

.topNav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 5px;
  box-shadow: 0 2px 3px 0 rgba(0,0,0,0.5);
  z-index: 10;
  text-align: right;
}

.topNav .cont {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topNav .cont .logoCont {
  width: 100px;
  padding: 3px 0;
}

.topNav .cont .logoCont img {
  width: 100%;
  display: block;
}

.topNav .rightSide {
  display: flex;
  align-items: center;
  color: white;
}

.topNav .rightSide .companyInfo {
  margin-right: 20px;
}

.topNav .rightSide .companyInfo > div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.topNav .rightSide .companyInfo > div span {
  margin-right: 5px;
}

.topNav .rightSide .companyInfo > div span a {
  background-color: white;
  color: #0A75C4;
  padding: 1px 5px;
  border-radius: 5px;
  margin: 3px;
  display: inline-block;
  line-height: 1.3;
}

.topNav .cont .logoutCont button {
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .topNav {
    padding: 0px;
  }

  .topNav .rightSide {
    flex-flow: column;
    justify-content: center;
    align-items: flex-end;
  }

  .topNav .rightSide .companyInfo {
    margin-right: 0px;
    font-size: 14px;
    padding-top: 3px;
  }
}

.dashboard .intsCont {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.dashboard .intsCont .intx {
  width: 30%;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.4);
  margin: 5px 5px 15px 5px;
  transition: all 0.3s;
  border-radius: 5px;
  border: solid 1px rgba(0,0,0,.1);
}

.dashboard .intsCont .intx:hover {
  box-shadow: 0 3px 3px 0 rgba(0,0,0,.4);
  transform: translateY(-4px);
  transition: all 0.3s;
}

.dashboard .intsCont .intx .title {
  padding: 10px 5px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
}

.dashboard .intsCont .intx .img {
  height: 100px;
  margin: 5px 0;
}

.dashboard .intsCont .intx .desc {
  padding: 15px 5px;
  text-align: center;
  padding-top: 0;
}

.dashboard .intsCont .intx .status.green, .dashboard .intsCont .intx .status.red {
  color: white;
}

@media only screen and (max-width: 768px) {
  .dashboard .intsCont .intx {
    width: 45%;
  }
}

@media only screen and (max-width: 520px) {
  .dashboard .intsCont .intx {
    width: 90%;
  }
}


.dashboard .admin .head {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
}

.dashboard .admin .section {
  margin-bottom: 15px;
}
.callbackPage .errorBox {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}

.callbackPage .errorBox .error {
  font-weight: bold;
  margin-bottom: 30px;
}

.callbackPage .errorBox .linkBox {
  text-align: center;
}

.SystemAdmin .head {
    font-size: 20px;
    font-weight: 700;
}

.SystemAdmin .section {
    position: relative;
}

.SystemAdmin .loading {
    position: absolute;
    left: 0;
    right: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255,0.4);
}

.SystemAdmin .section .head {
    margin-bottom: 10px;
}

.SystemAdmin .section .error {
    color: red;
    margin: 10px 0;
    font-weight: 700;
}

.SystemAdmin .section .success {
    color: green;
    margin: 10px 0;
    font-weight: 700;
}

.SystemAdmin .form {
    padding-left: 10px;
}

.SystemAdmin .form input {
    min-width: 350px;
}

.SystemAdmin .form > div {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.SystemAdmin .form > div label {
    margin-right: 10px;
}
.outline {
  padding-bottom: 40px;
}

.outline .body {
  margin: 0 auto;
  margin-top: 75px;
  padding: 0 10px;
  max-width: 1150px;
}

:root {
  --my-color: #002e6c;
  --color: #ffffff;
  --primaryColor: #002e6c;
  --productcartbackcolor: #eeeeee;
  --productcolor: #000;
  --background: #f0f3f4;
  --bgcolor: #f0f3f4;
  --paracolor: #000;
  --paragraphfontsize: 16px;
  --proheadingfontsize: 20px;
  --prohendingfontstyle: '';
  --paragraphfontstyle: '';
}

.CloudPunchBody h2 {
  display: flex;
  align-items: center;
}

.search-input {
  display: inline-block !important;
}

.my-btn-disable {
  background-color: #ddd;
  color: #000;
}

.CloudPunchBody h2 button {
  font-size: 12px;
  margin-left: 10px;
}

.bg-theme {
  background-color: var(--my-color) !important;
}

.CloudPunchBody .section {
  margin-top: 20px;
  position: relative;
}

.CloudPunchBody .section .head {
  font-size: 22px;
}

.CloudPunchBody .CreateApplicant {
  margin-top: 10px;
}

.CloudPunchBody .tableCont .noapps {
  padding: 10px;
}

.CloudPunchBody .table button {
  font-size: 12px;
}

.CloudPunchBody .table .but {
  width: 150px;
  text-align: center;
}

.CloudPunchBody .table .but>div {
  margin: 0 auto;
}

.CloudPunchBody .table .enrrow.active {
  font-weight: 800;
}

.CloudPunchBody .head {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.CloudPunchBody .ccTreeName {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.CloudPunchBody .ccTreeName .name {
  font-size: 16px;
  font-weight: 700;
}

.CloudPunchBody .ccTreeName .addmore {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
}

.CloudPunchBody .ccTreeCont {
  margin-bottom: 15px;
}

.CloudPunchBody .ccTreeList {
  padding-left: 10px;
}

.CloudPunchBody .ccTreeList .ccName {
  display: flex;
  align-items: center;
}

.CloudPunchBody .addDelete {
  background: none;
  padding: 0;
  margin-left: 5px;
}

.CloudPunchBody .addDelete div {
  height: 15px;
  width: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CloudPunchBody .addDelete.add {
  background-color: #0cad4d;
}

.CloudPunchBody .addDelete.delete {
  background-color: red;
}

.CloudPunchBody .addDelete.delete div {
  height: 10px;
}

.CloudPunchBody .install {
  padding-left: 15px;
}

.CloudPunchBody .HideShow h2 {
  display: block;
  margin-bottom: 0;
}

.CloudPunchBody .HideShow .step .stepBody {
  padding-left: 15px;
  color: var(--paracolor);
  font-style: var(--paragraphfontstyle);
}

.CloudPunchBody .HideShow .step {
  margin: 15px 0;
}

.CloudPunchBody .HideShow .step:first-of-type {
  margin-top: 5px;
}

.CloudPunchBody .HideShow .head {
  font-size: 18px;
  margin-bottom: 5px;
  text-decoration: underline;
  font-style: var(--prohendingfontstyle);
  color: var(--productcolor);
}

.CloudPunchBody .HideShow p {
  margin: 8px 0;
}

.btn-cloudpunch-download {
  padding: 8px !important;
  margin: 5px;
  border-radius: 10px !important;
}

.CloudPunchBody .downloadlinks a {
  display: inline-block;
  background-color: #002e6c;
  color: white;
  border-radius: 10px;
  padding: 5px 10px;
  margin-right: 10px;
}

.table.tablecstm .col2 {
  width: 10%;
}

.table.tablecstm .col5 {
  width: 40%;
}

.sort-select {
  padding: 3px 15px;
}

.margin-left-button-blue {
  margin-left: 5px;
  color: blue;
}

.margin-left-button-red {
  margin-left: 5px;
  color: red;
}

.margin-left-button-yellow {
  margin-left: 5px;
  color: yellowgreen;
}

.margin-left-button {
  margin-left: 5px;
}

.installation-tablerow {
  background-color: var(--my-color);
  color: white;
}

.icon-plus {
  font-size: 18px;
  font-weight: 600;
  border: 2px solid #000;
  width: 25px;
  height: 25px;
  border-radius: 27px;
  line-height: 25px;
  cursor: pointer;
  text-align: center;
}

.mealBrackSelect {
  width: 100%;
  margin: 0;
  border: none;
}

.visibility-none {
  visibility: hidden;
}

.circle-default {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  display: block;
  background: #000;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}


.actions {
  display: flex;
  justify-content: center;
}

.actions-btn {
  margin-left: 5px;
}

.circle-flex-main {
  display: flex;
  padding-right: 13px;
  justify-content: center;
}

.circle-flex {
  display: flex;
  justify-content: center;
}

.circle-blue-animation {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  display: block;
  background: linear-gradient(85deg, #12048b, #2b07fc);
  border-radius: 50%;
  animation: blueanimate 0.7s infinite;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.circle-red-animation {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  display: block;
  background: linear-gradient(85deg, #8b0404, #ff3232);
  border-radius: 50%;
  /* animation: greenanimate 0.7s infinite; */
  cursor: not-allowed;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.circle-blue {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  display: block;
  background: #00FF00;
  border-radius: 50%;
  /* animation: blueanimate 0.7s infinite; */
  box-shadow: rgb(0 50 253 / 24%) 0px 3px 8px;
}

.circle-red {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  display: block;
  background: #1f1f1f;
  border-radius: 50%;
  /* animation: greenanimate 0.7s infinite; */
  cursor: not-allowed;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@keyframes blueanimate {
  from {
    background: linear-gradient(85deg, #12048b, #0d0053);
  }


  to {
    background: linear-gradient(85deg, #2b07fc, #2a17d4);
  }

}

@keyframes greenanimate {
  from {
    background: linear-gradient(85deg, #000000, #ff3232);
  }


  to {
    background: linear-gradient(85deg, #ff4747, #000000, );
  }

}





@media only screen and (max-width: 768px) {}
:root {
  --background:#f0f3f4;
}

.modalOuter {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0,0,0,0.8);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalBody {
  /* background-color: white; */
  background-color: var(--background);
  padding: 20px;
  border-radius: 10px;
  width: 90vw;
  max-width: 750px;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
  box-sizing: border-box;
}

.modalOuter .close {
  position: fixed;
  right: 5px;
  top: 5px;
  width: 25px;
  height: 25px;
  z-index: 11;
  cursor: pointer;
}

.py-2 {
  padding: 1rem 0;
}
.px-1 {
  padding: 0 0.5rem;
}
.mx-1 {
  margin: 0 0.5rem;
}
.schedule-report select {
  padding: 10px 15px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}
.schedule-report .col-2 {
  width: 15%;
}
.schedule-report .col-6 {
  width: 50%;
}
.schedule-report .col-5 {
  width: 42%;
}
.schedule-report .col-12 {
  width: 100%;
}
.schedule-report .col-10 {
  width: 85%;
}
.schedule-report .d-flex {
  align-items: center;
  justify-content: unset;
}

.schedule-report {
  padding-top: 20px;
}

.schedule-report .daily {
  border: 1px solid #ced4da;
  padding: 20px;
  border-radius: 6px;
}

.schedule-report .daily input {
  margin: 0 10px;
  padding: 10px 15px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

/* 06-09-2023 css */

.schedule-report .formInput {
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid rgb(218, 218, 218);
  width: 100%;
  padding: 12px 10px !important;
}

.schedule-report .formInput::-webkit-outer-spin-button,
.schedule-report .formInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.schedule-report .formselect {
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid rgb(218, 218, 218);
  margin: 0;
  width: 100%;
  padding: 12px 10px !important;
}
.Custmrow {
  display: flex;
  flex-wrap: wrap;
}
.custmmb-2 {
  margin-bottom: 0.8rem;
}
.custmEmpty {
  text-align: center;
  padding: 6px 0;
  margin: 15px 0;
}
.custmCol-3 {
  flex: 0 0 auto;
  width: 22.54%;
  padding: 0 12px;
}
.custmCol-12 {
  flex: 0 0 auto;
  width: 97.65%;
  padding: 0 12px;
}
.custmCol-2 {
  flex: 0 0 auto;
  width: 12.9111%;
  padding: 0 12px;
}
.CustmBtn {
  padding: 12px 30px;
  /* height: 100%; */
}
.CustmWidth {
  width: 45%;
  display: flex;
  align-items: center;
}
.custmFullWidth {
  width: 100%;
}
.custmSpaceBetwen {
  justify-content: space-between !important;
}
.custmPaddingXY {
  padding: 10px 15px;
}

.table-company-sftp {
  justify-content: flex-end;
  display: flex;
}
.time-management-sftp {
  margin-left: auto;
}
.scheduler-btn {
  display: flex;
  justify-content: flex-end;
}

.scheduler-btn button {
  padding: 12px 25px;
}

.scheduler-autocomplete {
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
}

.scheduler-autocomplete button {
  padding: 0 25px;
}

.scheduler-autocomplete > div {
  display: block !important;
  width: 100%;
}
.scheduler-autocomplete > div > input {
  width: 100%;
  font-size: 16px;
  display: block;
  border-radius: 3px;
  box-sizing: border-box;
  border: 1px solid rgb(218, 218, 218);
  padding: 12px 10px !important;
}
.sftp-terminal .react-terminal-window-buttons {
  display: none;
}
.sftp-terminal .react-terminal-wrapper {
  padding: 15px;
}

.sftp-terminal .react-terminal-input:before {
  content: ">";
}
.terminal-green {
  text-decoration: underline;
  color: green;
}

.connect-sftp .fa-2xl {
  font-size: 12rem;
}

.date-picker {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
}
.mr-1 {
  margin-right: 0.5rem;
}

/* logs table start */
.logs-table td {
  font-family: monospace;
  /* padding: 10px 5px; */
}
.logs-table .status {
  background: #ddd;
  padding: 5px 14px;
  border-radius: 10px;
}

.logs-table .status.info {
  color: yellow;
  background-color: black;
}

.logs-table .status.error {
  color: red;
}

.logs-table .status.success {
  color: #fff;
  background-color: green;
}
.logs-table {
  width: 100%;
  overflow-y: auto;
}

/* logs table end */

:root {
  --my-color: #002e6c;
  --color: #ffffff;
  --primaryColor: #002e6c;
  --productcartbackcolor: #eeeeee;
  --productcolor: #000;
  --background: #f0f3f4;
  --bgcolor: #f0f3f4;
  --paracolor: #000;
  --paragraphfontsize: 16px;
  --proheadingfontsize: 20px;
  --prohendingfontstyle: '';
  --paragraphfontstyle: '';
}
.pis h1 {
  padding: 10px 0 15px 0;
  color: var(--productcolor);
  font-size: var(--proheadingfontsize);
  font-style: var(--prohendingfontstyle);
}
.ddd-color {
  color: #ddd;
}
.color-red {
  color: red;
}
.pis h2 {
  text-align: left;
  margin-bottom: 5px;
  color: var(--productcolor);
  /* font-size: var(--proheadingfontsize); */
  font-style: var(--prohendingfontstyle);
}

.pis .productSettingCont {
  margin-top: 30px;
}

.pis .prod-settings .psrow {
  padding: 10px 0;
  padding-left: 10px;
  border-left: solid 10px var(--my-color);
  margin-bottom: 20px;
}

.pis .successMsg {
  margin: 10px 0;
  font-weight: bold;
  color: #0cad4d;
}

.pis .successMsg.bad {
  color: red;
}

.AccessControl .existing {
  margin-top: 10px;
}

.AccessControl .addMore {
  margin-top: 10px;
}

.AccessControl .accessType {
  margin-top: 10px;
}

.AccessControl .accessType button {
  background-color: white;
  color: #b0afaf;
  border: solid 1px #b0afaf;
}

.AccessControl .accessType button.active {
  background-color: var(--my-color);
  color: white;
}

.AccessControl .accessType button:first-of-type {
  margin-right: 20px;
}

.AccessControl .endButs {
  margin-top: 15px;
}

.AccessControl .endButs button.cancel {
  background-color: white;
  color: black;
}

.AccessControl .endButs button:first-of-type {
  margin-right: 20px;
}

.AccessControl .existingCont .existing {
  max-width: 600px;
}

.AccessControl .AccessControlLine {
  padding: 5px;
  border: solid 1px black;
  border-radius: 5px;
  margin: 5px 0;
}

.AccessControl .AccessControlLine.inactive {
  background-color: #ff6363;
}

.AccessControl .AccessControlLine .top {
  display: flex;
}

.AccessControl .AccessControlLine .bottom .active button {
  font-size: 10px;
  padding: 3px;
}

.AccessControl .AccessControlLine .employee {
  flex: 1 1;
  display: flex;
  align-items: center;
}

.AccessControl .AccessControlLine .actions .accessType {
  margin: 0;
}
.custom-modal {
  display: block;
  position: absolute;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 33.5%;
  border-radius: 12px;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.d-flex {
  display: flex;
  justify-content: space-around;
}

.d-flex1 {
  display: flex;
}

.ml-2 {
  margin-left: 15px;
}

.mt-2 {
  margin-top: 15px;
}

#modalOuter {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.asscmp-a-table-scroll {
  max-height: 190px;
  overflow-y: auto;
}

.custreportinput {
  display: flex;
  justify-content: space-between;
}

.report-type {
  padding: 6px 15px;
}
.custom-report-input {
  display: flex;
}

.custom-report-input > input {
  width: 76%;
}

.theme-btn {
  color: #fff;
  font-size: 16px !important;
}

.custom-report-container {
  display: flex;
}

.custom-report-container .select-cus-report,
.custom-report-container .custom-report-s-input {
  width: 45%;
  padding: 0 10px;
}

.custom-report-container .select-cus-report select {
  width: 100%;
  margin: 0;
  padding: 10px 15px;
}

.custom-report-container .custom-report-s-input input {
  width: 100%;
  margin: 0;
  padding: 10px 15px;
}
.custom-report-container
  .custom-report-s-input
  input::-webkit-outer-spin-button,
.custom-report-container
  .custom-report-s-input
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.custom-report-container .cus-add-btn {
  width: 10%;
  margin: 0;
}

.custom-report-container .cus-add-btn button {
  font-size: 16px;
  position: relative;
  bottom: -21px;
  padding: 10px 11px;
}

.cus-reportId-table {
  padding: 0 10px;
  margin-top: 30px;
}

.ass-custom-report-table .arrow-icon {
  color: #000;
  background: transparent;
  font-size: 16px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 23px;
  text-align: center;
  margin-left: auto;
}

.ass-custom-report-table .arrow-icon:hover {
  background-color: rgba(0, 0, 0, 0.04);
  transition: 0.3ms;
}

.eChooser, .prodSettings {
  position: relative;
}

.eChooser .loadinGif, .prodSettings .loadinGif {
  width: 150px;
}

.eChooser .loadCont, .prodSettings .loadCont {
  background: rgba(255,255,255,0.7);
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.eChooser .title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

.eChooser .tip {
  font-style: italic;
  font-size: 14px;
  margin-bottom: 10px;
}

.eChooser .top {
  display: flex;
}

.eChooser .label {
  margin-right: 5px;
}

.eChooser .buttonCont button {
  margin-right: 20px;
}

.eChooser .buttonCont button:last-of-type {
  margin-right: 0px;
}

.eChooser .activeEmps {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.eChooser select {
  margin: 5px 0;
}

.eChooser .bot {
  margin-top: 5px;
}

.ccChooser .addCC {
  margin-left: 10px;
}

:root {
  --my-color: #002e6c;
  --color: #ffffff;
  --primaryColor: #002e6c;
  --productcartbackcolor: #eeeeee;
  --productcolor: #000;
  --background: #f0f3f4;
  --bgcolor: #f0f3f4;
  --paracolor: #000;
  --paragraphfontsize: 16px;
  --proheadingfontsize: 20px;
  --prohendingfontstyle: '';
  --paragraphfontstyle: '';
}

.selectSetting {
  display: flex;
  align-items: center;
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.selectSetting label {
  margin-right: 10px;
}

.textSetting {
  display: flex;
  align-items: center;
}

.domainSetting input {
  border: 1px solid rgb(218, 218, 218);
}

.domainSetting {
  margin-top: 10px;
}

.domainSetting label {
  margin-right: 10px;
}

.enSetting .example {
  margin-top: 5px;
  font-style: italic;
}

.enSetting .error {
  font-weight: bold;
  margin-bottom: 5px;
}

.nodeWithXCont {
  display: flex;
  flex-wrap: wrap;
}

.nodeWithX {
  margin: 5px;
  padding: 5px;
  border: solid 1px black;
  border-radius: 5px;
}

.nodeWithX button {
  margin-left: 5px;
}

.IPRestrictionSetting .list {
  margin-bottom: 10px;
}

.IPRestrictionSetting .addMore {
  display: flex;
  align-items: center;
}

.IPRestrictionSetting .addMore .inputs {
  display: flex;
  margin: 0 10px;
}

.IPRestrictionSetting .addMore .inputs input[type="text"] {
  width: 43px;
  margin: 0 5px;
  min-width: 0;
}

.WebclockInputs.list {
  display: flex;
  flex-wrap: wrap;
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.WebclockInputs.list .nodeWithX {
  cursor: pointer;
}

.WebclockInputs.list .nodeWithX.chosen {
  background-color: var(--my-color);
  color: white;
}

.WebclockActions .action {
  padding: 5px;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 5px;
}

.WebclockActions .action .name {
  margin-bottom: 5px;
  font-weight: 700;
}

.WebclockActions .action .main {
  padding-left: 10px;
}

.WebclockActions .textInput {
  display: flex;
  align-items: center;
}

.WebclockActions .textInput label {
  margin-right: 10px;
}

.WebclockActions .save {
  margin: 10px;
}

.WebclockActions .ccs .hea {
  margin: 5px 0;
  text-decoration: underline;
}

.WebclockActions .ccs .ccCont {
  padding-left: 20px;
}

.WebclockActions .ccs .ccCont > div {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.WebclockActions input[type="checkbox"] {
  cursor: pointer;
}

.UserProvisioningBody .intsCont {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.UserProvisioningBody .intsCont .intx {
  width: 30%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
  margin: 5px 5px 15px 5px;
  transition: all 0.3s;
  border-radius: 5px;
}

.UserProvisioningBody .intsCont .intx:hover {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.4);
  transform: translateY(-4px);
  transition: all 0.3s;
}

.UserProvisioningBody .intsCont .intx .img {
  height: 100px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.UserProvisioningBody .intsCont .intx .title {
  padding: 10px 5px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
}

.UserProvisioningBody .intsCont .intx .desc {
  padding: 15px 5px;
  text-align: center;
  padding-top: 0;
}

.UserProvisioningBody .intsCont .intx .status {
  padding: 10px 5px;
  text-align: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #eeeeee;
}

.UserProvisioningBody .intsCont .intx .status.green,
.UserProvisioningBody .intsCont .intx .status.red {
  color: white;
}

.status.blue {
  background-color: #001c80 !important;
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .UserProvisioningBody .intsCont .intx {
    width: 45%;
  }

  .UserProvisioningBody .intsCont {
    justify-content: center;
  }
}

@media only screen and (max-width: 520px) {
  .UserProvisioningBody .intsCont .intx {
    width: 90%;
  }
}

.bold {
  font-weight: bold !important;
}

:root {
  --primaryColor: #002e6c;
  --productcartbackcolor: #eeeeee;
  --productcolor: #000;
  --background: #f0f3f4;
  --bgcolor: #f0f3f4;
  --paracolor: #000;
  --paragraphfontsize: 16px;
  --proheadingfontsize: 20px;
  --prohendingfontstyle: '';
  --paragraphfontstyle: '';
}
.JobsBody .header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}

.JobsBody .header .left {
  display: flex;
  align-items: center;
}

.JobsBody .instructions {
  margin-bottom: 15px;
  font-size: 14px;
  color: var(--paracolor);
  font-size: var(--paragraphfontsize);
  font-style: var(--paragraphfontstyle);
}

.JobsBody .boards {
  margin-bottom: 25px;
  display: flex;
}

.JobsBody .boards .board {
  height: 90px;
  width: 120px;
  position: relative;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.JobsBody .boards .label {
  text-align: center;
  color: white;
  padding: 3px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.JobsBody .header h2 {
  margin-right: 10px;
}
.JobsBody .header h3 {
  margin-right: 10px;
}

.JobsBody .header button {
  font-size: 12px;
}

.JobsBody .jobCont {
  margin-bottom: 10px;
}

.JobsBody .jobCont:last-of-type {
  margin-bottom: 0px;
}

.JobsBody .jobTitle {
  font-weight: 800;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ddd;
  padding: 0 10px;
  border-radius: 4px;
  background-color: #ddd;
  font-style: var(--prohendingfontstyle);
  color: var(--productcolor);
}

.JobsBody .jobTitle button {
  background: transparent;
  color: black;
  font-size: 20px;
}

.JobsBody .appsCont {
  padding: 5px 10px;
  border-radius: 5px;
}

.JobsBody .appsCont .app {
  border: 1px solid #f4f4f4;
  border-radius: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-flow: column;
  background-color: #f4f4f4;
}

.JobsBody .appsCont .app.expanded {
  border: 1px solid black;
}

.JobsBody .appsCont .app .bottom {
  padding: 10px;
  padding-top: 0;
  transform-origin: top;
}

.JobsBody .appsCont .app .top {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
}

.JobsBody .appsCont .app .top.notReviewed {
  font-weight: 700;
}

.JobsBody .appsCont .app .top.reviewed {
  font-weight: 200;
}

.JobsBody .appsCont .app .top .nameCont {
  display: flex;
  align-items: center;
}

.JobsBody .appsCont .app .top .nameCont .email {
  font-size: 10px;
  border: 1px solid green;
  border-radius: 5px;
  padding: 3px;
  color: green;
  text-align: center;
}

.JobsBody .appsCont .app .top .nameCont .email.notsent {
  border: 1px solid red;
  color: red;
}

.JobsBody .appsCont .app .top .name {
  margin-right: 20px;
  font-size: 18px;
}

.JobsBody .appsCont .app.expanded .top .name {
  font-size: 30px;
  line-height: 1;
}

.JobsBody .appsCont .app:nth-of-type(2n) {
  background-color: white;
}

.JobsBody .appsCont .app:last-of-type .top {
  margin-bottom: 0px;
}

.JobsBody .appsCont .app.nonexpanded .top:hover {
  background-color: #eae9e9;
}

.JobsBody .appsCont .loadMore {
  margin-top: 10px;
  text-align: center;
}

.JobsBody table tr td:first-of-type {
  width: 200px;
}

.JobsBody .bottom .title {
  font-weight: bold;
  font-size: 20px;
  margin: 20px 0 10px 0;
}

.JobsBody .bottom .multi table {
  margin-bottom: 10px;
}

.JobsBody .bottom .multi table:last-of-type {
  margin-bottom: 0px;
}

.JobsBody .bottom .disclaimer {
  font-size: 16px;
  font-style: italic;
  margin: 15px 0 5px 0;
}

.JobsBody .bottom .jobInfoUpper {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.JobsBody .bottom .topbuts.email {
  margin-bottom: 15px;
}

.JobsBody .bottom .topbuts.resume {
  font-weight: 800;
}

.JobsBody .bottom .topbuts.resume a,
.JobsBody .bottom .topbuts.resume.none {
  border: solid 1px black;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  padding: 5px;
}

.JobsBody .bottom .topbuts.resume a img {
  width: 30px;
}

.JobsBody .bottom .topbuts.resume a span {
  margin-left: 5px;
}

.JobsBody .rightSide {
  display: flex;
  align-items: center;
}

.JobsBody .app .rightSide .reviewed {
  margin-right: 3px;
}

.JobsBody .app.expanded .rightSide .reviewed {
  display: none;
}

.JobsBody .app .ur .revd {
  font-weight: bold;
}

.Indeed .contactName {
  display: flex;
  align-items: center;
}

.JobBoard .textinput {
  display: flex;
  align-items: center;
}

.JobBoard .textinput label {
  margin-right: 10px;
}

@media only screen and (max-width: 768px) {
  .JobsBody .jobTitle {
    font-size: 20px;
  }

  .JobsBody .rightSide .sourceTime {
    text-align: right;
  }

  .JobsBody table tr td:first-of-type {
    width: 120px;
  }

  .JobsBody .appsCont .app.expanded .top .name {
    font-size: 22px;
  }
}

.WOTCForm {
  display: flex;
  flex-wrap: wrap;
}

.WOTCForm .pageTitle {
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  padding: 20px 0;
  width: 100%;
}

.WOTCForm .loadinGif {
  width: 120px;
  align-self: center;
}

.WOTCForm input:focus {
  border: solid 1px #002e6c;
}

.WOTCForm > div {
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  margin: 10px 0;
}

.WOTCForm > div.required label {
  font-weight: 900;
  color: red;
}

.WOTCForm .title {
  font-size: 20px;
  font-weight: 800;
  margin-top: 15px;
}

.WOTCForm .title:first-of-type {
  margin-top: 0px;
}

.WOTCForm .subTitle {
  font-size: 16px;
  font-weight: 800;
}

.WOTCForm .lineBreak {
  margin: 10px 0;
}

.WOTCForm .lineBreak > div {
  width: 60%;
  height: 2px;
  background-color: #002e6c;
  margin: 0px auto;
}

.WOTCForm .text100,
.WOTCForm .text50,
.WOTCForm .textSmallField {
  display: flex;
  align-items: center;
}

.WOTCForm .text50,
.WOTCForm .state {
  width: 50%;
}

.WOTCForm .text100 label,
.WOTCForm .text50 label {
  margin-right: 5px;
}

.WOTCForm .text100 input,
.WOTCForm .text50 input {
  flex: 1 1;
}

.WOTCForm .textSmallField label {
  flex: 1 1;
}

.WOTCForm .check {
  display: flex;
}

.WOTCForm .check .descCont {
  flex: 1 1;
  font-size: 14px;
}

.WOTCForm .check .descCont ul {
  margin: 5px 0;
}

.WOTCForm .check .checkboxCont {
  margin-right: 5px;
}

.WOTCForm .check .checkboxCont input {
  cursor: pointer;
}

.WOTCForm .saveCont {
  text-align: center;
  width: 100%;
}

.WOTCForm .saveCont {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #002e6c;
  width: 100vw;
  height: 50px;
  z-index: 12;
}

.slideUp-enter {
  transform: translateY(100%);
}

.slideUp-enter.slideUp-enter-active {
  transform: translateY(0);
  transition: all 200ms;
}

.slideUp-leave {
  transform: translateY(0);
}

.slideUp-leave.slideUp-leave-active {
  transform: translateY(100%);
  transition: all 200ms;
}

.WOTCForm .saveCont button {
  font-size: 20px;
  font-weight: 800;
  width: 100%;
  height: 100%;
}

.WOTCForm .successIndicator {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #0cad4d;
  width: 100vw;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  font-weight: 800;
  z-index: 15;
}

.WOTCForm .remainingFieldsIndicator {
  position: fixed;
  bottom: 0;
  background-color: red;
  color: white;
  font-size: 16px;
  font-weight: 400;
  padding: 5px 10px;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

.WOTCForm .complete {
  color: #0cad4d;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
}

.WOTCForm .noncomplete {
  color: red;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
}

.WOTCForm .complete.top {
  text-align: right;
  font-size: 14px;
  padding: 0;
  margin: 0;
  padding: 3px;
}

.WOTCForm .signature .label {
  font-size: 20px;
  margin-bottom: 10px;
}

.WOTCForm .required .signature .label {
  color: red;
  font-weight: 800;
}

.WOTCForm .signature input[type="checkbox"] {
  cursor: pointer;
}

.WOTCForm .signature .agree {
  display: flex;
  margin: 5px 0;
}

.WOTCForm .signature .agree div {
  flex: 1 1;
}

.WOTCForm .signature .agree input {
  margin-right: 10px;
}

.WOTCForm .signature .sign {
  margin: 10px 0 5px 0;
}

.WOTCForm .signature .sign label {
  display: block;
  margin-bottom: 5px;
}

.WOTCForm .signature .sign input {
  width: 100%;
}

.WOTCForm .signature .submit {
  margin-top: 10px;
}

@media only screen and (max-width: 768px) {
  .WOTCForm .text50,
  .wotcForm .state {
    width: 100%;
  }

  .WOTCForm .text100,
  .WOTCForm .text50,
  .WOTCForm .textSmallField {
    flex-flow: column;
    justify-content: flex-start;
    align-items: stretch;
  }

  .WOTCForm .saveCont button {
    font-size: 16px;
  }

  .WOTCForm .remainingFieldsIndicator {
    font-size: 14px;
  }
}

.WOTCBody h2 button {
  font-size: 12px;
  margin-left: 10px;
}

.WOTCBody .section .head {
  font-size: 22px;
}

.WOTCBody .CreateApplicant {
  margin-top: 10px;
}

.WOTCBody .tableCont .noapps {
  padding: 10px;
}

.WOTCBody .form {
  max-width: 600px;
  background-color: rgb(238, 238, 238);
  padding: 10px;
  border-radius: 5px;
  position: relative;
}

.WOTCBody .form .loading {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255,255,255,0.8);
  border-radius: 5px;
}

.WOTCBody .form .title {
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 10px;
}

.WOTCBody .form .submit {
  margin-top: 10px;
}

.WOTCBody .form .submit .cancel {
  background-color: white;
  color: black;
  margin-right: 20px;
}

.WOTCBody .form .field {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.WOTCBody .form .field input {
  flex: 1 1;
}

.WOTCBody .form .field label {
  width: 100px;
}

.WOTCBody .form .error {
  font-weight: 800;
  color: red;
  margin: 10px 0;
}

.WOTCBody .table .col3, .WOTCBody .table .col4, .WOTCBody .table .col5, .WOTCBody .table .col6 {
  width: 150px;
  text-align: center;
}

.WOTCBody .table .col4, .WOTCBody .table .col6 {
  width: 100px;
}

.WOTCBody .table button {
  font-size: 12px;
}

.WOTCBody .modalOuter {
  align-items: flex-start;
  overflow-y: auto;
  padding: 20px;
}

.WOTCBody .modalBody {
  max-height: none;
  width: 100%;
  margin-bottom: 50px;
}

@media only screen and (max-width: 768px) {
  .WOTCBody .modalOuter {
    padding: 10px;
  }
  .modalOuter .close {
    background-color: black;
    border-radius: 16px;
  }
}

.HideShow .top {
  display: flex;
  align-items: flex-end;
  margin-right: 10px;
  cursor: pointer;
}

.HideShow .top .controller {
  margin-left: 10px;
  font-size: 20px;
  font-weight: 600;
}

.HideShow .top .controller button {
  font-size: 12px;
  background: none;
  color: black;
}
.HideShow .top .controllers {
  margin-left: -20px;
  font-size: 20px;
  font-weight: 600;
}

.GoogleHireBody .section {
    margin-top: 20px;
    position: relative;
}
:root {
  --primaryColor: #002e6c;
  --productcartbackcolor: #eeeeee;
  --productcolor: #000;
  --background: #f0f3f4;
  --bgcolor: #f0f3f4;
  --paracolor: #000;
  --paragraphfontsize: 16px;
  --proheadingfontsize: 20px;
  --prohendingfontstyle: '';
  --paragraphfontstyle: '';
}
.BaseProductSetting, .prodSettings {
  position: relative;
}

.BaseProductSetting .title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
  color: var(--productcolor);
}

.BaseProductSetting .tip {
  font-style: var(--paragraphfontstyle);
  font-size: var(--paragraphfontsize);
  margin-bottom: 10px;
  color: var(--paracolor);
}

.BaseProductSetting input {
  min-width: 300px;
}

.WOTCConstantsSetting .section {
}

.WOTCConstantsSetting .section .header {
  font-weight: 800;
  margin-bottom: 5px;
}

.WOTCConstantsSetting .section:first-of-type {
  margin-bottom: 20px;
}

.WOTCConstantsSetting .formCont {
  margin-left: 20px;
}

.WOTCConstantsSetting .formCont .formRow {
  display: flex;
  align-items: center;
  margin: 5px 0;
  max-width: 600px;
}

.WOTCConstantsSetting .formCont .formRow input {
  flex: 1 1;
}

.WOTCConstantsSetting .formCont .formRow label {
  margin-right: 10px;
}

.WebclockAttestation .buts {
    margin-top: 10px;
}

:root {
    --primaryColor: #002e6c;
    --productcartbackcolor: #eeeeee;
    --productcolor: #000;
    --background: #f0f3f4;
    --bgcolor: #f0f3f4;
    --paracolor: #000;
    --paragraphfontsize: 16px;
    --proheadingfontsize: 20px;
    --prohendingfontstyle: '';
    --paragraphfontstyle: '';
  }

.WebclockAttestation .steps {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.WebclockAttestation .step {
    border: solid 1px black;
    padding: 5px;
    margin: 10px;
    border-radius: 5px;
    min-width: 300px;
}

.WebclockAttestation .step > div {
    margin-bottom: 15px;
}

.WebclockAttestation .step > div:last-of-type {
    margin-bottom: 0px;
}

.WebclockAttestation .step .stepHeader {
    display: flex;
    justify-content: space-between;
}

.WebclockAttestation .step .stepHeader .firstStepButton {
    font-size: 13px;
    color: var(--paracolor);
    font-style: var(--paragraphfontstyle);
}

.WebclockAttestation .step .stepLabel {
    font-weight: 800;
    color: var(--productcolor);
    font-style: var(--proheadingfontsize);
}

.WebclockAttestation .step .buts {
    margin-top: 10px;
}

.WebclockAttestation .step .outcomes .cont {
    padding-left: 10px;
}

.WebclockAttestation .step .outcomes .outcome {
    margin-bottom: 15px;
    border: 1px solid rgb(218, 218, 218);
    padding: 5px;
}

.WebclockAttestation .step .outcomes .outcome > div {
    margin-bottom: 7px;
}

.WebclockAttestation .step .outcomes .outcome > div:last-of-type{
    margin-bottom: 0px;
}

.WebclockAttestation .step .outcomes .outcome .value {
    display: flex;
    align-items: center;
}

.WebclockAttestation .step .condition {
    display: flex;
    align-items: center;
    padding-left: 20px;
    margin: 3px 0;
}

.WebclockAttestation .step .condition span {
    display: flex;
    align-items: center;
}

.WebclockAttestation .step .condition input[type="text"] {
    min-width: 40px;
    width: 40px;
    text-align: center;
    padding: 3px;
    margin: 0 3px;
}

.WebclockAttestation .step textarea {
    min-width: 300px;
    min-height: 100px;
    font-size: 13px;
    resize: none;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid rgb(218, 218, 218);
}

.WebclockAttestation .step input[type="text"] {
    font-size: 12px;
}

.WebclockAttestation .step select {
    font-size: 13px;
}

.WebclockAttestation .step {
    font-size: 13px;
}

.WebclockAttestation .step button {
    font-size: 12px;
}
:root {
  --my-color: #002e6c;
  --color: #ffffff;
  --primaryColor: #002e6c;
  --productcartbackcolor: #eeeeee;
  --productcolor: #000;
  --background: #f0f3f4;
  --bgcolor: #f0f3f4;
  --paracolor: #000;
  --paragraphfontsize: 16px;
  --proheadingfontsize: 20px;
  --prohendingfontstyle: '';
  --paragraphfontstyle: '';
}
.baseIntegration h1 {
  text-align: left;
  padding: 10px 0 20px 0;
}

.baseIntegration .head {
  display: flex;
  justify-content: space-between;
}

.baseIntegration .head h1 {
  flex: 1 1;
}

.baseIntegration .head .img {
  width: 220px;
  position: relative;
}

.baseIntegration .head .img img {
  width: 100%;
  position: absolute;
  z-index: 0;
}

.baseIntegration h2 {
  text-align: left;
  padding: 0;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.baseIntegration .HideShow h2 {
  margin-bottom: 0;
}

.baseIntegration .HideShow .top {
  margin-bottom: 5px;
}

.baseIntegration h2 span {
  margin-left: 10px;
}

.baseIntegration h2 .hint {
  font-size: 12px;
  align-self: flex-end;
  font-weight: normal;
}

.baseIntegration h2 span button {
  font-size: 12px;
}

.baseIntegration .intSect {
}

.baseIntegration .intSect .sect {
  margin-bottom: 30px;
}

.baseIntegration .intSect .status {
  font-weight: bold;
}

.baseIntegration .intSect .status .good {
  color: #0cad4d;
}

.baseIntegration .intSect .status .bad {
  color: red;
}

.baseIntegration .intSect .status .connect {
  margin-top: 5px;
}

.baseIntegration .intSect .status .connected .connect button {
  font-size: 12px;
}

.tableCont {
  width: 100%;
  overflow-x: auto;
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid rgb(218, 218, 218);
  position: relative;
}

.tableCont.loading {
  overflow: hidden;
}

.tableCont .tableLoading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

table {
  width: 100%;
  font-size: 14px;
  border-spacing: 5px;
}

table,
th,
td {
  border: 1px solid rgb(218, 218, 218);
  border-collapse: collapse;
  box-sizing: border-box;
}

th,
td {
  padding: 10px 5px;
}

table tbody tr:nth-child(even) {
  background-color: rgb(238, 238, 238);
}

table tbody tr:nth-child(odd) {
  background-color: #fff;
}

td.success.col,
td.SUCCESS.col {
  background-color: #0cad4d;
  color: white;
}

td.WAITING.col,
td.WAITING.col {
  background-color: #f6fa00;
  color: #000;
}

td.failed.col,
td.FAILED.col,
td.FAIL.col {
  background-color: red;
  color: white;
}

.baseIntegration .settings .settingRow {
  margin-bottom: 10px;
}

.baseIntegration .breadcrumbs {
  margin-bottom: 10px;
}

.breadcrumbs span{
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.access_para{
  color: var(--paracolor);
  font-size: var(--paragraphfontsize);
  font-style: var(--paragraphfontstyle);
}

.baseIntegration .documentationBody .subHead {
  margin: 5px 0 10px 0;
  font-size: 18px;
  font-weight: bold;
}

.baseIntegration .documentationBody ul {
  margin-top: 0;
}

@media only screen and (max-width: 1000px) {
  .baseIntegration .head .img img {
    width: 100%;
    position: relative;
  }

  .baseIntegration .head .img {
    width: 200px;
  }
}

@media only screen and (max-width: 520px) {
  .baseIntegration .head {
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
  }

  .baseIntegration .head .img {
    width: 150px;
  }
}

.integration-table {
  padding: 10px 0;
}

.integration-table > .header-action {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  margin-right: 10px;
}
.pagination-container {
  padding: 0;
  display: flex;
  list-style-type: none;
  justify-content: flex-end;
}

.pagination-container > li a {
  display: inline-block;
  padding: 10px;
  background-color: var(--my-color);
  color: #fff;
  border: 1px solid var(--my-color);
  border-radius: 4px;
  margin: 0 5px;
}

.pagination-container > li.active a {
  background-color: #fff;
}

.pagination-container > li.disabled a {
  background-color: rgba(33, 37, 41, 0.75);
  border-color: rgba(33, 37, 41, 0.75);
}

.pagination-container > li.active a {
  background-color: #fff;
  color: #000;
}

/* .pagination-container > li:first-child {
  border-radius: 4;
} */

.icon-btn {
  display: inline-block;
  cursor: pointer;
  transition: 0.3s;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  font-size: 25px;
}

.NewUserTemplates .opEmpField, .NewUserTemplates table {
  margin-bottom: 10px;
}

.NewUserTemplates .opEmpField {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.NewUserTemplates .opEmpField .alldone {
  font-size: 16px;
  font-weight: bold;
  color: green;
}

.NewUserTemplates table {
  background-color: white;
}

.NewUserTemplates table th, .NewUserTemplates table td {
  padding: 0px;
}

.NewUserTemplates .leftcol, .NewUserTemplates table th {
  padding: 5px;
}

.NewUserTemplates table tr td:first-of-type {
  width: 300px;
}

.NewUserTemplates .rightcol {
  display: flex;
  width: 100%;
  align-items: center;
}

.NewUserTemplates .rightcol .vals {
  flex: 1 1;
  margin: 5px 0;
}

.NewUserTemplates .rightcol .delete {
  padding: 3px;
}

.NewUserTemplates .rightcol .delete button {
  padding: 1px 6px;
  background-color: red;
}

.NewUserTemplates .rightcol .vals .list {
  margin-bottom: 3px;
}

.NewUserTemplates .rightcol .vals .add {
  margin: 0 5px;
}

.NewUserTemplates .rightcol .vals .add button {
  font-size: 12px;
  padding: 3px;
}

.NewUserTemplates .eChooser .activeEmps > div {
  background-color: white;
}

:root {
  --my-color: #002e6c;
  --color: #ffffff;
}
:root {
  --primaryColor: #002e6c;
  --productcartbackcolor: #eeeeee;
  --productcolor: #000;
  --background: #f0f3f4;
  --bgcolor: #f0f3f4;
  --paracolor: #000;
  --paragraphfontsize: 16px;
  --proheadingfontsize: 20px;
  --prohendingfontstyle: '';
  --paragraphfontstyle: '';
}
.settingsCont {
  position: relative;
}

.settingsCont .settingsRow {
  padding: 10px 0;
  padding-left: 10px;
  border-left: solid 10px var(--my-color);
  margin-bottom: 20px;
}

.settingsCont .unsaved .settingsRow {
  background: rgba(255, 0, 0, 0.1);
  border-left: solid 10px #ff221e;
}

.loadCont {
  background: rgba(255, 255, 255, 0.7);
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.settingsCont .saveAlert {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.settingsCont .saveAlert .box {
  width: 20px;
  height: 20px;
  background-color: #ff221e;
}

.settingsCont .saveAlert span {
  margin-right: 10px;
}

.settingsCont .settingsRow .title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}

.settingsCont .tip {
  font-style: italic;
  font-size: 14px;
  margin-bottom: 10px;
}

.settingsCont .buttonCont.top {
  margin-bottom: 20px;
  margin-top: 10px;
}

.settingsCont .buttonCont button {
  margin-right: 20px;
}

.settingsCont .buttonCont button:last-of-type {
  margin-right: 0px;
}

.settingsCont .textSetting .inputCont {
  min-width: 300px;
}

.settingsCont .textSetting .inputCont input {
  width: 100%;
  padding: 5px;
}

.settingsCont .successMsg {
  margin: 10px 0;
  font-weight: bold;
  color: #0cad4d;
}

.settingsCont .successMsg.bad {
  color: red;
}

.settingsCont .note {
  font-size: 14px;
  font-style: var(--paragraphfontstyle);
  margin-top: 5px;
  color: var(--paracolor);
}

.settingsCont input[type="text"] {
  min-width: 300px;
}

.Indeed .head .desc {
  font-size: 14px;
  margin-top: 10px;
  font-weight: 400;
}

.Indeed .sponsor {
  margin-bottom: 20px;
}

.Indeed .sponsor .success {
  color: #0cad4d;
  margin: 20px 0;
  font-weight: bold;
}

.Indeed .sponsor .job {
  padding: 10px;
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
  border-radius: 5px;
  margin-bottom: 10px;
}

.Indeed .sponsor .job .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Indeed .sponsor .job .actions button {
  font-size: 12px;
}

.Indeed .sponsor .job .actions button.marg {
  margin-left: 10px;
}

.Indeed .sponsor .job .actions button.good {
  background-color: #0cad4d;
}

.Indeed .sponsor .job .actions button.bad {
  background-color: red;
}

.Indeed .sponsor .job .top .main {
  display: flex;
  align-items: center;
}

.Indeed .sponsor .job:nth-of-type(2n) {
  background-color: white;
}

.Indeed .sponsor .job .title {
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
}

.Indeed .sponsor .job .label {
  border-radius: 5px;
  padding: 3px;
  border-width: 1px;
  border-style: solid;
  font-size: 12px;
}

.Indeed .sponsor .job .label.sponsored {
  border-color: #0cad4d;
  color: #0cad4d;
}

.Indeed .sponsor .job .label.unsponsored {
  border-color: red;
  color: red;
}

.Indeed .sponsor .job .sponsorship {
  margin-top: 5px;
  margin-left: 10px;
  border: solid 1px red;
  border-radius: 5px;
  padding: 3px;
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
}

.Indeed .sponsor .job .sponsorship .main {
  display: flex;
  align-items: center;
}

.Indeed .sponsor .job .sponsorship .status {
  color: white;
  background-color: red;
  font-size: 12px;
  padding: 5px;
  border-radius: 5px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Indeed .sponsor .job .sponsorship.active .status {
  background-color: #0cad4d;
}

.Indeed .sponsor .job .sponsorship.active {
  border-color: #0cad4d;
}

.Indeed .sponsor .job .sponsorship .budget {
  margin-left: 40px;
  width: 120px;
}

.Indeed .sponsor .job .sponsorship .ends {
  text-align: right;
}

.Indeed .modalOuter {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 17;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Indeed .modalBody {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 90vw;
  max-width: 750px;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
  box-sizing: border-box;
}

.Indeed .modalOuter .close {
  position: fixed;
  right: 5px;
  top: 5px;
  width: 25px;
  height: 25px;
  z-index: 11;
  cursor: pointer;
}

.Indeed .modalBody h1 {
  padding-top: 0;
  padding-bottom: 15px;
  font-size: 20px;
  text-align: center;
}

.Indeed .modalBody a {
  color: #1237ee;
}

.Indeed .modalBody .spacebelow {
  margin-bottom: 10px;
}

.Indeed .modalBody .somespacebelow {
  margin-bottom: 5px;
}

.Indeed .modalBody .list {
  margin: 0;
  margin-top: 5px;
}

.Indeed .modalBody .buttoncont {
  text-align: center;
}

.Indeed .modalBody .buttoncont button {
  background-color: #f60;
  font-size: 18px;
}

.Indeed .modalBody .it {
  font-size: 14px;
  color: grey;
  font-style: italic;
}

.Indeed .modalBody .bold {
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .Indeed .sponsor .job .top {
    display: block;
  }

  .Indeed .sponsor .job .top .main {
    margin-bottom: 15px;
  }

  .Indeed .sponsor .job .top .actions {
    text-align: center;
  }

  .Indeed .sponsor .job .sponsorship {
    display: block;
  }

  .Indeed .sponsor .job .sponsorship .actions {
    margin-top: 10px;
    text-align: center;
  }
}

.career .head .desc {
  font-size: 14px;
  margin-top: 10px;
  font-weight: 400;
}
.text-center {
  text-align: center !important;
}
.career-input input {
  width: 100%;
}

.custom-field .career-input {
  width: 95%;
}

.custom-field .close-icon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.custom-field .box {
  display: flex;
  align-items: center;
}

.career-input {
  padding-bottom: 30px;
  position: relative;
}

.career-input > span {
  position: absolute;
}

.career-input > div {
  position: relative;
}
.career-input > div .qr-btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.preview-logo {
  width: 150px;
  height: 150px;
  display: inline-block;
  border: 1px solid #000;
  margin-bottom: 20px;
  /* margin-right: 10px; */
}
.preview-box {
  margin-right: 20px;
}

.preview-logo img {
  width: 100%;
  height: 100%;
}

.career img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.career .submit-form {
  padding: 20px 0;
}

.career .submit-form .submitbtncstm {
  background-color: #002e6c !important;
  border-color: #002e6c !important;
}

.applicant {
  padding: 20px 0;
}
.career-input-d .conta {
  display: flex;
}
.qr-code-box {
  width: 150px;
  height: 150px;
  display: inline-block;
  border: 1px solid #000;
  margin-bottom: 20px;
}
.qr-code-box > canvas {
  width: 100% !important;
  height: 100% !important;
}
.career-input-d {
  margin-bottom: 20px;
}
.career-input-d .qr-code {
  width: 400px;
}

.career-input-d .qr-code input {
  display: inline-block;
}
.career-fixbox {
  height: 400px;
  overflow-x: auto;
}
.career-input.opacity input {
  opacity: 0.8;
}
.text-center {
  text-align: center;
}
.description textarea {
  width: 100%;
  font-size: 16px;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid rgb(218, 218, 218);
}
.selectCompany {
  margin-bottom: 16px;
}
.configuration-title select {
  width: 100%;
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid rgb(218, 218, 218);
  margin: 0;
}
.text-center-imp.center {
  justify-content: center;
}

.editor {
  border: 1px solid #e9e9e9;
  color: #000;
  min-height: 150px;
  font-family: "Source Sans Pro", sans-serif;
}

.editor h2,
.editor h3,
.editor h4 {
  color: #112e51;
}

.editor h2 {
  font-size: 1.555555556rem;
}

.editor h3 {
  font-size: 1.333333333rem;
}

.editor h4 {
  font-size: 1rem;
}

.editor a {
  color: #0071bc;
  text-decoration: none;
}

.editor a:hover {
  text-decoration: underline;
  cursor: pointer;
}
.bg-verification-integration {
  padding: 50px 0;
}

.bg-check .bg-container {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  border-radius: 6px;
}
.bg-check .bg-container p {
  margin: 10px 0 0 0;
  text-align: left;
}
.bg-check .bg-container select {
  padding: 10px 10px;
  border-radius: 4px;
  width: 200px;
  margin: 10px 0 0 0;
}

.bg-check .bg-container button {
  padding: 13px 15px;
  border-radius: 4px;
  width: 200px;
  margin: 0 83px;
}

.JobsBody table tr td.first-sm {
  width: unset !important;
}
.ml-1 {
  margin-left: 8px;
}
.bg-container-db {
  display: flex;
}

.bg-container-db select {
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 4px 5px;
  box-sizing: border-box;
  border: 1px solid rgb(218, 218, 218);
}

.bg-container-db button {
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 6px;
  box-sizing: border-box;
}

.package-para {
  margin: 0 0 10px;
}

.not-assign {
  background: #ddd;
  padding: 10px;
  border-radius: 10px;
  display: inline-block;
  width: 100px;
}

.bg-data-table tr:nth-child(even) {
  background-color: #fff;
}

.bg-data-table tr:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.bg-data-table tr.selected {
  background-color: rgba(25, 118, 210, 0.12);
}

.connector-configuration-input input {
  width: 100%;
}

.connector-configuration .d-flex {
  justify-content: space-between;
  padding-bottom: 15px;
}

.connector-configuration .con-input {
  width: 48%;
}

.connector-configuration .con-input input {
  width: 100%;
}
.btn-configuration {
  padding-top: 20px;
}
.btn-configuration .submitbtncstm {
  background-color: #002e6c !important;
}
.connector-configuration-input label,
.con-input label {
  font-weight: 400;
}
.connector-configuration-input {
  padding-bottom: 15px;
}

* {
  box-sizing: border-box;
}

:root {
  --my-color: #002e6c;
  --color: #ffffff;
}

:root {
  --primaryColor: #002e6c;
  --productcartbackcolor: #eeeeee;
  --productcolor: #000;
  --background: #f0f3f4;
  --bgcolor: #f0f3f4;
  --paracolor: #000;
  --paragraphfontsize: 16px;
  --proheadingfontsize: 20px;
  --prohendingfontstyle: '';
  --paragraphfontstyle: '';
  --dynamicwidth: '';
  --dynamicheight: ''
}

.lalit {
  width: var(--dynamicwidth);
  height: var(--dynamicheight);
}

/* utility class start */
.cursor-pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

.my-flex {
  display: flex;
  flex-wrap: wrap;
}

.my-d-flex {
  display: flex;
}

.my-justify-content-between {
  justify-content: space-between;
}

.dynamic_color {
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.my-justify-content-center {
  justify-content: center;
}

.my-justify-content-end {
  justify-content: flex-end;
}

.my-align-item-center {
  align-items: center;
}

.my-align-item-end {
  align-items: flex-end;
}

.my-flex-grow-1 {
  flex: 1 1;
}

.m-0 {
  margin: 0 !important;
}

.mb-2 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 2rem !important;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mx-1 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mx-2 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-4 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mt-0 {
  margin: 0;
}

.mt-1 {
  margin-top: 0.5rem !important;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ms-1 {
  margin-left: 0.5rem;
}

.ms-2 {
  margin-left: 1rem;
}

.ms-4 {
  margin-left: 2rem;
}

.ms-10-px {
  margin-left: 10px;
}

.me-10-px {
  margin-right: 10px;
}

.me-1 {
  margin-right: 0.5rem;
}

.me-2 {
  margin-right: 1rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mt-8 {
  margin-top: 4rem;
}

.m-auto {
  margin: auto;
}

.p-0 {
  padding: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.p-2 {
  padding: 1rem;
}

.p-4 {
  padding: 2rem;
}

.pt-4 {
  padding-top: 2rem !important;
}

.pe-2 {
  padding-right: 1rem !important;
}

.pe-1 {
  padding-right: 0.5rem !important;
}

.pt-6 {
  padding-top: 3rem !important;
}

.px-4 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.one-col-1 {
  width: 8.3333%;
}

.one-col-2 {
  width: 16.6666%;
}

.one-col-3 {
  width: 25%;
}

.one-col-4 {
  width: 33.33333%;
}

.one-col-5 {
  width: 41.66666%;
}

.one-col-6 {
  width: 50%;
}

.one-col-7 {
  width: 58.3333%;
}

.one-col-8 {
  width: 66.66666%;
}

.one-col-9 {
  width: 75%;
}

.one-col-10 {
  width: 82.33333%;
}

.one-col-11 {
  width: 91.66666%;
}

.one-col-12 {
  width: 100%;
}

.one-col-sm-2 {
  width: 16.6666%;
}

.one-col-sm-10 {
  width: 82.33333%;
}

.text-left {
  text-align: left;
}

.full-width {
  width: 100% !important;
}

.color-fff {
  color: #fff;
}

.my-btn {
  padding: 10px 10px;
}

.overflow-hidden {
  overflow: hidden;
}

.z-index-99 {
  /* z-index: 99; */
  z-index: 10;
}

/* utility class end */

/* tooltip css start */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: #002e6c !important;
}

/* tooltip css end */

/* drop css start */
.dash-menu .drop-down {
  list-style-type: none;
  padding: 0;
  margin-left: 15px;
}

/* drop css end */

main {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
}

.loadinGif {
  margin: 0 auto;
  display: block;
  width: 100%;
  max-width: 130px;
  color: 'red'
}

.textInputCont {
  display: block;
  width: 100%;
  position: relative;
  line-height: 1;
}

.textInputCont input {
  width: 100%;
  padding: 5px 3px;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  border-color: #e4e4e4;
  padding-right: 22px;
  font-size: 16px;
}

.textInputCont input.nox {
  padding-right: 3px;
}

.textInputCont .xbutton {
  position: absolute;
  right: 3px;
  height: 70%;
  top: 15%;
  cursor: pointer;
  width: auto;
}

/* 29-09-2022 */

.d-flexcstm {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
}

.cstmbtn1 {
  background: #4e6ab7;
  padding: 10px 20px;
}

.tablecstm thead tr th {
  text-align: left;
  white-space: nowrap;
}

.formcontrol {
  width: 100%;
}

.associatecompany {
  width: 100%;
  display: flex;
  align-items: center;
}

.autocomplete {
  width: calc(100% - 146px);
}

.autocomplete input {
  width: 100%;
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid rgb(218, 218, 218);
}

.primarycheck {
  margin: 0 0 0 20px;
  display: flex;
  color: #3894ff;
  align-items: center;
}

.primarycheck input {
  width: 20px;
  height: 20px;
}

.addcompanybtn {
  background: #4e6ab7;
  padding: 3.5px 15px;
  margin-left: 10px;
  font-size: 20px;
}

.tablecustompop {
  width: 100%;
}

.tablecustompop thead tr th {
  text-align: left;
  white-space: nowrap;
}

.tablecustompop th,
.tablecustompop td {
  border: none;
  border-top: 1px solid rgb(218, 218, 218);
}

.tablecustompop tbody tr:nth-child(even) {
  background: transparent;
}

.badgecolor {
  background-color: #27b148;
  color: #fff;
  font-size: 13px;
  display: inline-block;
  padding: 3px 14px 2px;
  border-radius: 5px;
}

.modal-contentcstm {
  width: 600px;
  max-width: 90%;
}

.submitbtncstm {
  background: #4e6ab7 !important;
  border: 1px solid #4e6ab7 !important;
  padding: 8px 20px !important;
  margin-left: 10px !important;
  font-size: 15px !important;
  margin: 0 !important;
}

.cancelbtncstm {
  background-color: #eaeaea !important;
  border: 1px solid #505559 !important;
  color: #505559 !important;
  margin-left: 10px !important;
}

.mbcstm {
  margin-bottom: 30px;
}

.tablecstm tbody tr td {
  white-space: nowrap;
}

.cstmdlt {
  color: #cc1313;
  background: none;
  font-size: 16px !important;
}

@media only screen and (max-width: 768px) {
  main {
    margin-top: 0;
  }
}

.connect-sftp h1 {
  padding-bottom: 0;
}

.connect-sftp p {
  text-align: center;
}

.color-green {
  color: #27b148;
}

/* market place css start */
.market-place h1 {
  text-align: left;
}

.market-place .slick-center {
  transform: scale(1.1);
}

.market-place .slick-prev,
.slick-next {
  z-index: 9;
}

.market-logo {
  max-width: 200px;
  height: 75px;
  padding-bottom: 0.5rem;
  margin: auto;
}

.market-logo img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.market-place .slick-track {
  padding: 40px 0;
}

.market-place-card {
  padding: 0.5rem 1.5rem;
  margin: 1rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 50px;
  border-bottom: 4px solid #3894ff;
  cursor: pointer;
  transition: ease-in 0.3s;
  /* background-color: #fff; */
  background-color: var(--bgcolor);
  min-height: 255px;
  color: var(--paracolor);
  font-size: var(--paragraphfontsize);
  font-style: var(--paragraphfontstyle);
}

/* .slick-active:not(.slick-center) .market-place-card:hover {
  transform: scale(1.1);
} */

.market-place-card h2 {
  text-align: left;
  color: var(--productcolor);
  font-size: var(--proheadingfontsize);
  font-style: var(--prohendingfontstyle);
}

.market-place .place-row {
  display: flex;
  flex-wrap: wrap;
}

/* market place css end */

/* date range start */
.log-management .date-picker {
  position: relative;
  justify-content: space-between;
}

.date-range-log {
  position: absolute;
  top: 51px;
  /* left: 0; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 10px;
  z-index: 9;
  background-color: #fff;
}

.date-picker span {
  color: #000;
}

.date-picker .log-btn {
  display: flex;
  justify-content: flex-end;
}

.logs-table tr:nth-child(even) {
  background-color: unset !important;
}

/* date range end */
.csv-export-btn {
  cursor: pointer;
  background-color: var(--my-color);
  color: white;
  padding: 7px;
  border-radius: 3px;
  border: none;
  font-size: 14px;
  margin: 0 10px;
}

.queue-id {
  color: #002e6c;
}

.queue-id:hover {
  text-decoration: underline;
  cursor: pointer;
}

.schedule-report .prod-settings {
  padding: 0 12px;
}

.quick-connect-reset-btn {
  padding: 0 12px;
}

.quick-connect-reset-btn button {
  margin-right: 12px;
}

.timing-account input {
  min-width: 100px;
  font-size: 16px;
  border-radius: 3px;
  box-sizing: border-box;
  border: 1px solid rgb(218, 218, 218);
}

/* market place css start */
.market-place .slick-prev,
.market-place .slick-next {
  /* background-color: red !important; */
  background-color: var(--my-color) !important;
  height: 35px !important;
  width: 35px !important;
  text-align: center;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  background-color: #002e6c;
}

.market-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* market place css end */
/* tool confirmation css start */
.confirm-body {
  height: 100vh;
  display: flex;
}

.confirm-container {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 600px;
  margin: auto;
  padding: 20px;
  text-align: center;
}

.confirm-container .badge-check-icon {
  width: 30%;
  margin: auto;
}

.confirm-container .badge-check-icon img {
  width: 100%;
}

/* tool confirmation css end */

/* product modal css start */
.product-modal {
  background: #000000a3;
  z-index: 33;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.product-modal-inner {
  padding: 45px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-modalBody {
  /* background-color: white; */
  background-color: var(--background);
  border-radius: 10px;
  width: 92vw;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}

.product-close-modal-btn svg {
  cursor: pointer;
  font-size: 20px;
  transition: 0.3s;
  width: 25px;
  height: 25px;
  padding: 8px;
  border-radius: 50%;
  font-weight: 400;
}

.product-close-modal-btn svg:hover {
  background-color: #091e420f;
}

.product-modal-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  background-color: var(--my-color);
  color: #fff;
}

.product-modal-head h2 {
  margin-bottom: 0px;
}

.product-modal-content {
  padding: 10px 20px;
  height: 750px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.custom_product-modal {
  background: #000000a3;
  z-index: 33;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.custom_product-modalBody {
  background-color: var(--background);
  border-radius: 10px;
  width: 50vw;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  height: 700px;
}

.sync_history {
  margin: 10px 0px 10px 80%
}

.date_cul {
  margin-top: 100px;
  left: 250px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.Integration-outer {
  width: 70%;
  position: relative;
  transition: 0.6s;
  overflow: auto;
  max-height: 680px;
}

.product-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
  /* cursor: pointer; */
}

.product-modal-header span {
  cursor: pointer;
}

.setting_heading {
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.product-modal-header h3 {
  font-size: 24px;
  margin-bottom: 0px;
  margin-top: 0;
}

.product-modal-header span svg {
  cursor: pointer;
  font-size: 25px;
}

.product-card-content-outer {
  display: flex;
}

.product-card-outer-out {
  padding: 10px;
  width: 25%;
}

.integration-active .product-card-outer-out {
  width: 20%;
}

.product-card-outer {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  background: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.product-card-outer .input-h3 {
  border: 1px solid #fff !important;
  margin-bottom: 0px;
  margin-top: 8px;
  text-align: center;
  font-size: var(--proheadingfontsize);
  font-weight: 600;
  cursor: context-menu;
  width: 100%;
  position: relative;
  color: var(--productcolor) !important;
  font-style: var(--prohendingfontstyle);
}

.product-card-outer .input-h3.active:focus {
  border: 1px solid #002e6c !important;
}

.product-card-outer .input-h3:disabled {
  color: #000;
  background-color: #fff;
}

.product-card-outer .input-p {
  border: 1px solid #fff !important;
  text-align: center;
  margin: 5px 0px;
  font-size: var(--paragraphfontsize);
  height: calc(1em * 1.5 * 5 + 3px + 3px + 1px + 1px);
  padding: 5px 5px;
  resize: none;
  cursor: context-menu;
  width: 100%;
  position: relative;
  color: var(--paracolor) !important;
  font-style: var(--paragraphfontstyle);
}

.product-card-outer .input-p.active:focus {
  border: 1px solid #002e6c !important;
}

.product-card-outer .input-p:disabled {
  background-color: #fff;
  color: #000;
}

.product-setting-section {
  width: 30%;
  transition: 0.6s;
  padding-left: 15px;
  position: relative;
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.product-card-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-right: 10px;
}

.product-card-inner img {
  /* width: 100px;
  padding: 5px; */
  object-fit: contain;
  width: 100%;
}

.product-card-inner {
  display: flex;
  justify-content: center;
  background-color: var(--productcartbackcolor);
  /* background-color: #eeeeee; */
  border-radius: 10px 10px 0px 0px;
  height: 100px;
  width: 100%;
}

.product-card-inner1 {
  display: flex;
  flex-direction: column;
  height: 145px;
  overflow: hidden;
}

.product-card-inner1 h3 {
  margin-bottom: 0px;
  margin-top: 8px;
  text-align: center;
  font-size: 20px;
}

.product-card-inner1 p {
  text-align: center;
  margin: 5px 0px;
}

.product-card-inner2 {
  background: #ff0000;
  padding: 15px;
  text-align: center;
}

.product-card-inner2 h4 {
  margin: 0px;
  color: #fff;
  letter-spacing: 1px;
  font-size: 14px;
}

.product-setting-inner {
  padding: 10px 0;
  padding-left: 10px;
  border-left: solid 10px var(--my-color);
  margin-bottom: 20px;
}

.unsave .product-setting-inner {
  border-left: solid 10px #ff0000;
}

.product-setting-inner label {
  font-weight: 600;
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.product-setting-inner.email-notification p {
  font-size: 14px;
  margin: 0;
  margin-bottom: 1rem;
  color: var(--paracolor);
}

.product-setting-inner p {
  color: var(--paracolor);
  /* font-size: var(--paragraphfontsize); */
  font-style: var(--paragraphfontstyle);
}

.product-setting-inner.email-notification .activeEmps button {
  padding: 8px 15px;
}

.product-setting-inner.email-notification .activeEmps {
  display: flex;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
}

.product-setting-inner.email-notification .employees select {
  width: 100%;
}

.product-setting-inner.email-notification .freeinput .form-input {
  padding: 10px;
  border: 1px solid #d9d9d9;
  width: 90%;
  border-radius: 5px;
  /* margin: 11px; */
}

.product-setting-inner select,
.product-setting-inner1 select {
  padding: 10px;
  margin: 0px;
  margin-top: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  width: 80%;
}

.product-setting-inner button,
.product-setting-inner1 button {
  padding: 12px 20px;
}

.product-setting-inner .n-days-in input {
  padding: 12px 20px;
  width: 80%;
  border: 1px solid #d9d9d9;
}

.product-setting-section h3 {
  margin-bottom: 2px;
}

.product-setting-inner1 label {
  font-weight: 600;
  font-size: 20px;
}

.product-setting-inner1 .Access-type label {
  font-weight: 400;
  font-size: 16px;
}

.product-setting-inner1 {
  margin-top: 15px;
  overflow: auto;
  max-height: 380px;
}

.access-control {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px;
}

.access-control h3 {
  margin: 0px;
  font-size: 16px;
}

.access-control button {
  padding: 5px;
}

.access-control button.inactive {
  background-color: gray;
}

.access-control.inactive {
  background-color: #ff6363;
}

.product-setting-inner2 button {
  padding: 10px;
  margin-top: 15px;
}

.system-admin-outer {
  padding-left: 25px;
}

.system-admin-inner label {
  font-size: 16px;
  font-weight: 600;
}

.system-admin-outer h3 {
  font-size: 24px;
  /* margin-bottom: 15px; */
}

.system-admin-inner select {
  padding: 10px;
  width: 60%;
  margin: 0px;
  margin-top: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

.system-admin-inner button {
  padding: 12px 0;
  margin-left: 10px;
  width: 142px;
}

.product-deactivate {
  margin-top: 25px;
}

.prod-card {
  /* height: 100% !important;
  padding: 15px !important;
  margin-bottom: 25px !important; */
  position: relative;
}

.dash-product-card {
  position: relative;
}

/* product modal css end */
.Integration-outer.integration-active {
  width: 100%;
}

.product-left-content.integration-active {
  width: 100%;
  transition: 0.6s;
}

.product-setting-section.setting-hide {
  visibility: hidden;
  position: absolute;
  right: 0%;
  transform: translate(100%, 0);
}

.product-right-content.setting-hide {
  visibility: hidden;
  position: absolute;
  right: 0%;
  transform: translate(100%, 0);
}

/* product integration modal css end */
.heading {
  display: flex;
  justify-content: space-between;
}

.provision-modal {
  padding: 20px;
}

.left-heading h2 {
  text-align: left;
  margin-bottom: 10px;
  color: var(--productcolor);
  font-size: var(--proheadingfontsize);
  font-style: var(--prohendingfontstyle);
}

.side-img {
  width: 25%;
  /* width: 150px; */
  /* display: flex; */
  align-items: center;
}

.side-img img {
  width: 100%;
  float: right;
}

.left-heading h4 {
  margin: 10px 0px;
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.left-heading p {
  color: red;
  font-weight: 600;
}

.hint-product {
  font-size: 12px;
  align-self: flex-end;
  font-weight: 400;
}

.provision-modal {
  padding: 20px;
  height: 750px;
  position: relative;
}

.product_card_content {
  display: flex;
}

.product-left-content {
  width: 70%;
  transition: 0.6s;
  overflow: auto;
}

.product-right-content {
  width: 30%;
  padding-left: 16px;
  transition: 0.6s;
}

.suite-btn {
  padding: 12px 20px;
}

.product-table .header-action input {
  padding: 10px;
  border: 1px solid rgb(218, 218, 218);
}

.product-table .header-action select {
  border: 1px solid rgb(218, 218, 218);
}

.product-table table thead tr {
  background-color: var(--my-color);
  color: #fff;
}

/* .product-table .statusz.FAIL {
  background-color: red;
  color: #fff;
} */

/* .my-align-item-center {
  font-size: 25px;
  cursor: pointer;
} */

.ess .side-img img {
  width: 100%;
}

.ess .side-img {
  width: 25%;
}

.container-banner {
  position: relative;
  padding-top: 86px;
}

/* .hero-banner-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
.hero-banner-img {
  background-image: url(/static/media/banner-1c.aa813ff7.png);
  height: 600px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 23%;
}

/*start 27/11/2023 */

.hero-banner-content {
  position: absolute;
  top: 86px;
  left: 0%;
  width: 100%;
  height: 600px;
  background-color: rgba(0, 0, 0, 0.35);
}

/* end 27/11/2023 */

/* .hero-banner-img {
  height: 800px;
} */

.hero-banner-content .content-data {
  position: absolute;
  top: 30%;
  left: 4%;
  width: 430px;
  padding: 20px;
}

.hero-banner-content .content-data button {
  padding: 20px;
  font-size: 20px;
  border-radius: 5px;
}

.upper-img {
  position: absolute;
  top: 50%;
  left: 50%;
}

.hero-banner-content .content-data h2 {
  text-align: left;
  /* color: #002e6c; */
  color: #fff;
  font-weight: 300;
  line-height: 2.375rem;
  margin-bottom: 0;
  font-size: 1.75rem;
  margin: 5px 0px;
}

.hero-banner-content .content-data p {
  /* color: #002e6c; */
  color: #fff;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.8rem;
  margin-bottom: 1.125rem;
  margin-top: 0.625rem;
  max-width: 26.25rem;
}

.dashboard-integration-doc {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(100%, 0px);
  height: 100%;
  width: 70%;
  background-color: #fff;
  transition: 0.6s;
}

.dashboard-integration-doc.open::after {
  content: "";
  width: 43%;
  height: 100%;
  background: #00000057;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-100%, 0);
}

.dashboard-integration-doc.association {
  width: 40%;
}

.dashboard-integration-doc.open.association::after {
  width: 150%;
}

.dashboard-integration-doc.open {
  transform: translate(0, 0px);
}

.integration-document {
  padding: 0 2rem;
  overflow: auto;
  height: 700px;
}

.integration-configuration {
  padding: 0 2rem;
  overflow: auto;
  height: 95%;
  padding-bottom: 25px;
}

.settings .saveAlert .box {
  width: 20px;
  height: 20px;
  background-color: #ff221e;
  display: inline-block;
}

.settings .saveAlert {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.edit-btn-container {
  position: absolute;
  top: 0;
  left: 0;
}

.prod-card .edit-btn-container {
  display: flex;
  justify-content: flex-end;
}

.edit-icon-btn {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  color: var(--productcolor);
}

footer.footer_sec {
  padding: 20px 0px;
  background: #002e6c;
  font-weight: 500;
  color: #fff;
  margin-top: 50px;
}

.text {
  color: #fff;
}

/************ new import scheduler css start *************/

.one-control-input,
.one-control-select {
  padding: 0 8px;
}

.import-schedule-report .one-control-input input {
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #dadada;
  width: 100%;
  padding: 12px 10px !important;
}

.import-schedule-report .one-control-input input[type="radio"] {
  display: unset;
  width: unset;
}

.import-schedule-report .one-control-input>label {
  padding: 12px 10px;
  display: inline-block;
}

.import-schedule-report .one-control-select select {
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #dadada;
  margin: 0;
  width: 100%;
  padding: 12px 10px !important;
}

.import-schedule-report .one-control-input-date input {
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #dadada;
  margin: 0;
  width: 100%;
  padding: 12px 10px !important;
}

button.one-control-btn {
  padding: 14px 15px;
}

.imp-container-report {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 7px;
}

.scheduler-type-container {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  margin: 0 8px;
}

/************ new import scheduler css end  **************/
.product-modal-content .date-picker {
  position: relative;
}

.product-modal-content .date-picker .date-range-log {
  position: absolute;
  top: 53px;
  right: 73px;
}

/************ new Multiple EIN css Start  **************/
.association-form .one-control-input {
  padding: 0;
  margin-bottom: 1rem;
}

.association-form .one-control-input input {
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #dadada;
  width: 100%;
  padding: 12px 10px !important;
}

.association-form .one-control-button button {
  padding: 14px 16px !important;
}

.association-form th,
.association-form td {
  border: none;
  border-top: 1px solid rgb(218, 218, 218);
}

.mul-association-btn button {
  padding: 12px 30px !important;
}

.auto-com {
  position: relative;
}

.association-con {
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

.dashboard-integration-doc.association .custom-report-multiple {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  z-index: 999;
  transition: 0.5s;
}

.dashboard-integration-doc.association .custom-report-multiple.active {
  left: 0;
}

.one-select-option select {
  padding: 10px;
  width: 100%;
  margin: 0px;
  margin-top: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

.custom-report-multiple .one-control-input {
  padding: 0;
}

.custom-report-multiple .one-control-input input {
  padding: 10px;
  border: 1px solid #d9d9d9;
  width: 100%;
  border-radius: 5px;
}

.custom-report-multiple .one-control-button1 button {
  padding: 11px;
  border: 1px solid #d9d9d9;
  width: 100%;
  border-radius: 5px;
}

/************ new Multiple EIN css end    **************/

/* 27/11/2023 */

.product-right-content.setting-show,
.product-left-content {
  height: 700px;
  overflow-x: hidden;
  overflow-y: auto;
}

/* system-admin-tool-form css start */
.system-admin-tool-form .one-control-input input {
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #dadada;
  width: 100%;
  padding: 12px 10px !important;
}

/* system-admin-tool-form css  end */

/* .emp-body {
  height: auto !important;
} */

/* .Integration-divider > div:nth-child(1) {
  width: 60%;
}

.Integration-divider > div:nth-child(2) {
  width: 40%;
} */

.userprovision-table.table thead tr th:nth-child(5) {
  width: 220px;
}

.userprovision-table.table thead tr :is(th:nth-child(2),
  th:nth-child(3),
  th:nth-child(4),
  th:nth-child(6),
  th:nth-child(1)) {
  width: 80px;
}

.userprovision-table.table thead tr :is(th:nth-child(1)) {
  width: 120px;
}

.userprovision-table.table thead tr :is(th:nth-child(7)) {
  min-width: 150px;
}

.product-table table tbody tr :is(td:nth-child(7)) {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-table table tbody tr td {
  font-size: 13px;
  padding: 8px;
}

.product-table table tbody tr td:nth-child(5) {
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.middleproductcard-section {
  width: 75%;
}

.middleproductcard-section-overlay {
  width: 25%;
}

.middletop-section {
  z-index: 12;
  position: relative;
}

/* slick slider dot  */
.slick-dots li {
  margin: 0px !important;
}

.header-right img {
  height: 40px;
}

.card-bar-one input.prod-heading {
  width: 100%;
  position: relative;
  color: var(--productcolor);
}

.card-bar-one input.prod-heading:disabled {
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.marketplace-card-margin {
  margin-inline: 32px;
}

header {
  z-index: 16 !important;
}

.systemadmininner-select select {
  width: 100%;
  margin-bottom: 1rem;
}

.product-modal-content.productmodal-padding .access-control button {
  margin-top: 8px;
}

.table-responsive {
  overflow-x: auto;
  margin-right: 10px;
}

.product-modal.productmodal-full .product-right-content .product-setting-inner button {
  margin-top: 8px;
}

.system-admin-tool-form .one-control-input input {
  max-width: 270px;
}

.system-admin-tool-form .one-control-input {
  padding: 0px;
}

.systemadmin-btn button {
  width: 100%;
  max-width: 270px;
}

:is(.associations-table, .import-table, .associationcustomreports) tbody tr td:last-child {
  min-width: 150px;
}

.associationcustomreports tbody tr td:last-child {
  min-width: 170px;
}

.associations-table tbody tr td:nth-child(7) {
  text-align: center;
}

.associationcustomreports.table,
.associationcompany-table.table {
  min-width: 500px;
  width: 100%;
}

.association-list {
  width: 80%;
  padding-top: 20px;
  padding-bottom: 50px;
}

.product-modal-content.productmodalcontent-sm .one-col-sm-10.d-flex {
  justify-content: flex-end;
}

.product-modal-content.productmodalcontent-sm .date-picker {
  justify-content: flex-start;
}

.employee-report .one-control-select select {
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #dadada;
  margin: 0;
  width: 100%;
  padding: 12px 10px !important;
}

.ess-portal .prod-card {
  min-height: 180px;
  display: flex;
  align-items: center;
}

.ess-portal .marketplacecard-container {
  margin-inline: 2rem;
}

.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.employeereport-select {
  max-width: 150px;
  width: 100%;
}

.my-association-list {
  width: 100%;
}

.modalOuter {
  z-index: 999;
}

.Indeed.JobBoard .one-select-option select {
  margin-top: 0;
}

.emp-dashboard-body .career.JobsBody.JobBoard .img {
  height: 100px;
}

.emp-dashboard-body .JobsBody .head {
  display: flex;
  justify-content: space-between;
}

.card-bar-one textarea.prod-description:disabled {
  color: var(--paracolor);
}

.prod-description-extra-dev {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* login new design css start */
.login-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(/static/media/login-bg.4383dfbf.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.login-container .login-box {
  width: 90%;
  max-width: 500px;
  padding: 2rem;
  background-color: #0a75c2;
  color: #fff;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.login-container .login-box .one-input input {
  width: 100%;
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

.login-container .login-box .header {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.login-container .login-box .login-logo {
  width: 60%;
  margin: auto;
}

.login-container .login-box .login-logo img {
  width: 100%;
}

.login-btn {
  width: 100%;
  padding: 10px;
  transition: 0.3s;
  font-size: 18px;
  background-color: #002e6c;
}

.login-btn:hover {
  background-color: #003f95;
}

/* login new design css end   */

/* company list data table start */
.company_system_table a,
.company_system_table button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  text-decoration: none;
}

.table_head {
  margin-top: -56px;
}

.date_tab {
  padding: 0px 0px 5px 0px;
  display: flex;
  justify-content: flex-end;
}

.log-btn_tab {
  display: flex;
  justify-content: flex-end;
  padding: 0px
}

.company_system_table select:focus-visible {
  outline: none !important;
}

.company_system_table input[type="checkbox"] {
  width: 18px;
  height: 18px;
}

.company_system_table .system_table {
  padding: 1rem;
}

.company_system_table .tbl_container {
  overflow-x: auto;
  background-color: #fff;
  height: 750px;
  overflow-y: auto;
}

.tbl_header-bom {
  margin-bottom: 15px;
}

.company_system_table .tbl_container table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

.company_system_table .tbl_container th,
td {
  text-align: left;
  padding: 5px;
}

.company_system_table .tbl_containers th,
td {
  text-align: left;
  padding: 5px;
}

.company_system_table .tbl_container table tbody tr:nth-child(even) {
  background: none;
}

.company_system_table .tbl_container table tbody tr:nth-child(odd) {
  background: none;
}

.company_system_table .tbl_container tbody tr,
.company_system_table .tbl_container tbody tr td {
  border: 1px solid #ddd;
}

.company_system_table .tbl_container thead tr:nth-child(1) th {
  border: 1px solid #fff;
}

.company_system_table .tbl_container thead tr:nth-child(1) {
  background: var(--my-color);
  color: #fff;
}

.company_system_table .tbl_container thead tr:nth-child(2) {
  background: #f2f0f2;
}

.company_system_table .tbl_container tbody input[type="checkbox"] {
  border: 1px solid var(--my-color);
}

.company_system_table .tbl_container thead tr select {
  width: 150px;
  border: 1px solid #bdbaba;
  padding: 4px;
}

.company_system_table .tbl_container {
  background-color: #fff;
}

.company_system_table .tbl_containers thead tr:nth-child(1) {
  background: var(--my-color);
  color: #fff;
}

.company_system_table .refresh_data {
  color: #002e6c;
}

.company_system_table.company-list .tbl_container thead tr:nth-child(1) th:nth-child(1) {
  background-color: #000;
  width: 3%;
}

.company_system_table .tbl_container tbody tr:hover {
  background-color: #d9d9d9 !important;
}

.company_system_table .tbl_container tbody tr td:nth-child(6) {
  color: #002e6c;
  border: none;
  display: flex;
  align-items: center;
  justify-self: center;
}

.company_system_table .flex-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.company_system_table .filter_down {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0company_system_table 0.5rem 0;
}

.company_system_table .page_upper {
  justify-content: space-between !important;
  position: -webkit-sticky !important;
  position: sticky !important;
}

.company_system_table .row_filter {
  margin-right: 1rem;
}

.company_system_table .arrow_input {
  height: 100%;
  margin: 0px 0company_system_table 0.5rem;
  width: 100px;
}

.company_system_table .fresh_img {
  margin: 0 0company_system_table 0.3rem;
}

.company_system_table .arrow_bx {
  height: 20px;
  width: 20px;
  border: 1px solid #bdbaba;
  margin: 0px 0company_system_table 0.8rem;
  flex-wrap: nowrap;
}

.company_system_table .add_new {
  padding: 5px 15px;
  border: 1px solid #002e6c;
  color: #002e6c;
  background: #fff;
}

.company_system_table .left_tsec select,
.company_system_table .filter_down select {
  border: 1px solid #bdbaba;
  padding: 4px;
}

.company_system_table .custom_filter {
  margin-left: 2rem;
}

.company_system_table .default_drop {
  border: none !important;
  color: #002e6c;
}

.company_system_table .right_tsec {
  grid-gap: 2rem;
  gap: 2rem;
}

.company_system_table .inf_td img {
  margin-right: 1rem;
}

.company_system_table .tbl_container table tfoot {
  background: #f2f0f2;
}

.company_system_table button {
  background-color: var(--my-color);
  margin-right: 20px;
}

@media (max-width: 768px) {
  .company_system_table .right_tsec {
    margin: 10px 0 4px;
    grid-gap: 10px;
    gap: 10px;
  }

  .company_system_table .left_tsec {
    grid-gap: 10px;
    gap: 10px;
  }
}

@media (max-width: 480px) {
  .company_system_table .right_tsec {
    margin: 5px 0;
  }

  .company_system_table .show_filter {
    grid-gap: 10px;
    gap: 10px;
  }
}

/* company list data table   end */

.product-company-detail {
  padding: 10px 20px;
  margin-top: 5px;
}

.product-company-detail-heading {
  margin-left: 50px;
  font-size: 23px;
  font-weight: 600;
  margin-bottom: 5px;
}

.product-company-detail h3 {
  font-size: 24px;
  margin-bottom: 0px;
  margin-top: 0;
}

.company-details-container {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
}

body {
  /* zoom: 80%; */
}


.scrollable-content {
  overflow: auto;
  /* This will add scrollbars when content overflows */
  max-height: 400px;
  /* Set maximum height for the content */
}

.employee-integration-event .HideShow .top {
  align-items: flex-start;
}

.employee-integration-event {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px;
}

.integration-event-status-green {
  color: green;
}

.integration-event-status-red {
  color: red;
}

.emp-integration-setting {
  padding: 10px 20px;
  height: 320px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.btn-copy:hover {
  background-color: green;
}

.desc-items .mb-0 {
  margin-bottom: 0 !important;
}

.desc-items {
  height: 220px;
  overflow-y: scroll;
}

/* .emp-integration-item{
  margin-bottom: 32px!important;
    margin-left: 10px!important;
} */


.emp-installation-controller {
  background-color: #8a8282;
  padding: 20px 10px;
  font-size: 14px;
  line-height: 1.2;
  min-height: 100vh;
}


.emp-installation-sub-controller {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
  background-color: white;
  padding: 30px;
  border-radius: 5px;
}


.emp-installation-status-table {
  width: 100%;
  margin: 10px 0;
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
}

/* 29/03/2024 */

.modalContainer {
  position: relative;
  height: 400px;
}

.sidebarCustom {
  width: 200px;
  padding: 10px;
  border-right: 1px solid gray;
  background-color: rgb(248, 248, 248);
  height: 100%;
  position: absolute;
  left: 0px;
}

.navlistCustom {
  list-style: none;
  padding-left: 0px;
}

.navitemCustom {
  display: inline-block;
  width: 100%;
  padding-block: 5px;
  padding-inline: 10px;
  border-radius: 5px;
  margin-bottom: 1px;
}

.navitemCustom.myactive {
  background-color: rgb(20, 20, 59);
  color: white;
}

.mainContentCustom {
  position: absolute;
  left: 200px;
  width: calc(100% - 200px);
  height: 100%;
}

.mainContentCustom {
  padding: 10px;
  overflow-y: auto;
}

.formFieldCustom {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}

.passwordChangeBtn {
  margin-block: 5px;
  padding: 10px 20px;
}

.passwordClearBtn {
  margin-block: 5px;
  padding: 10px 20px;
  background-color: silver;
  color: black;
}

.flexCenterCustom {
  grid-gap: 10px;
  gap: 10px;
  display: flex;
  justify-content: center;
}

.customFieldSet {
  border-radius: 10px;
  border: 2px solid silver;
}

.customFlex {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

/* add employee 29/03 */
.addEmloyeeCustom .top {
  display: block;
}

.addEmloyeeCustom .form-input {
  width: 100% !important;
}

.horizontalLinewithOr {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hrLine {
  width: 45%;
  height: 1px;
  border: 1px solid gray;
}

.text-end {
  text-align: end;
}

.newsetupup-btn {
  cursor: pointer;
  background-color: var(--my-color);
  color: white;
  padding: 8px !important;
  border-radius: 3px;
  border: none;
}

.mainContentCustom2 {
  padding: 10px;

  width: 100%;
  height: 100%;
}

.drag-area {
  border: 2px dashed #5256ad;
  height: 130px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.drag-area .icon {
  font-size: 100px;
  color: #fff;
}

.drag-area header {
  padding: 0px 0px 5px 0px;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  position: unset !important;
  background-color: unset;
  color: #5256ad;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drag-area span {
  font-size: 16px;
  font-weight: 500;
  color: #5256ad;
  margin: 5px 0 5px 0;
}

.drag-area button {
  padding: 7px 20px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  outline: none;
  background: #5256ad;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.uploadprocess-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.step {

  margin-top: 25px !important;
}

.setupdlhd {
  margin: 5px 0;
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}


.setupname {
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid rgb(218, 218, 218);
}


.saveInstallationsetupBtn {
  margin-block: 5px;
  padding: 10px 20px;
}

.Cstmfileuploadinput {
  margin-left: 80px;
}

.open-mr {
  margin-right: 5px;
}

.btn-mineral {
  padding: 15px !important;
}

.mineral-container {
  display: flex !important;
}

.left-side-mineral-data {
  margin-right: 860px !important;
  margin-top: 35px !important;
}


/* minral page css start */
.mineral-login-page .main-wrapper {
  max-width: 50rem;
  margin: auto;
  text-align: center;
}

.mineral-login-page .logo-wrapper {
  max-width: 100%;
  margin: 3.75rem 0px 2.5rem;
}

.mineral-login-page .Cstmlogo {
  margin-bottom: 0px;
  margin-top: 20px;
  max-height: 60px;
  max-width: 200px;
}

.mineral-login-page .hr {
  border: none;
  height: 1px;
  margin: 2rem auto;
  width: 100%;
  background-color: rgb(203, 203, 203);
}

.mineral-login-page .signin-heading {
  font-size: 2rem;
  font-weight: 500;
}

.mineral-login-page .form-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 90px auto 40px;
  max-width: 800px;
  padding: 0px 20px;
  width: 100%;
}

.mineral-login-page .Cstmtext-start {
  text-align: start;
  margin-bottom: 10px;
  color: rgb(40, 40, 40);
  line-height: normal;
  font-size: 16px;
  font-weight: bold;
}

.mineral-login-page #Username {
  width: 480px;
  height: 48px;
  font-size: 16px;
  border-color: rgb(169, 169, 169);
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  display: block;
  padding: 6px 15px;

}

.mineral-login-page #Password {
  width: 480px;
  height: 48px;
  font-size: 16px;
  border-color: rgb(169, 169, 169);
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  display: block;
  padding: 6px 15px;

}

.mineral-login-page input[name="checkbox"] {
  height: 20px;
  width: 20px;
}

.mineral-login-page .remember-wrapper {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 15px;
}

.mineral-login-page .signin-btn {
  border: none;
  font-weight: 500;
  background: #005e8e;
  min-height: 60px;
  min-width: 152px;
  padding: 5px 15px;
  width: 320px;
  font-size: 20px;
  margin-top: 20px;

}

.mineral-login-page .forgotpasstext {
  color: rgb(105, 205, 255);
  font-size: 20px;
  font-weight: 500;
}

.mineral-login-page .termstext {
  padding: 5rem 0px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: #282828;
}

.mineral-login-page .cstmtext-color {
  color: rgb(105, 205, 255);
}

/* mineral page css end */

.light {
  transform: rotate(180deg);
  transition: all .3s ease-in;
  color: #002e6c;
  height: 30px;
  margin-left: 15px;
  margin-top: 12px;
}

.dark {
  transform: rotate(360deg);
  transition: all .3s ease-in;
  color: #002e6c;
  height: 30px;
  margin-left: 15px;
  margin-top: 12px;
}

/* mineral cart */
.mineral_cart {
  padding: 0.5rem 1.5rem;
  margin: 1rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 50px;
  border-bottom: 4px solid #3894ff;
  cursor: pointer;
  transition: ease-in 0.3s;
  background-color: #fff;
  min-height: 300px;
}

.mineral_cart_img {
  height: 270px;
  width: 100%;
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-bottom: 4px solid #3894ff;
  cursor: pointer;
  border-radius: 10px 10px 10px 10px;
}

.mineral-main-sec {
  display: flex;
  justify-content: space-between;
}

.market-place .slick-list .slick-track {
  padding: 0;
}

.market-place h1 {
  padding: 10px 0 0 0;
  font-size: 24px;
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.mineral-sec .btn-mineral {
  padding: 10px 15px !important;
}

.mineral-img img {
  height: 50px;
}

.mineral-img p {
  margin: 0;
}

.mineral-desc {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
}

.mineral-desc-min {
  padding: 10px;
  border-radius: 8px;
}

.min_biz p {
  color: var(--paracolor);
  font-style: var(--paragraphfontstyle);
}

.mineral-dynamic-desc p {
  margin: 0 0 30px 0;
  color: var(--paracolor);
  font-style: var(--paragraphfontstyle);
}

.market-place .slick-list .slick-slide {
  padding: 0 5px;
}

.market-place .slick-slider .slick-next {
  right: 0;
}

.market-place .slick-slider .slick-prev {
  left: 0;
}

@media(max-width:768px) {

  .market-place .slick-list .slick-slide {
    padding: 0 5px;
  }

  .mineral-main-sec {
    flex-wrap: wrap;
  }

  .mineral-page {
    padding: 20px 0 0 0;
  }
}

@media(max-width:576px) {
  .market-place .slick-list .slick-slide {
    padding: 0;
  }
}

.auth_text {
  text-align: center;
  margin-top: 15px;
  height: 500px;
}

.edit-icon-btn-mineral {
  cursor: pointer;
}

.video_img {
  width: 100%;
  height: 270px;
  object-fit: cover;
  width: 100%;
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-bottom: 4px solid #3894ff;
  cursor: pointer;
  border-radius: 10px 10px 10px 10px;
}

.configuration_bizlibrary {
  margin-bottom: 10px;
  text-align: center;
  line-height: 1;
  padding: 10px 10px 10px 0;
  font-size: 30px;
}

.configuration_bizlibrary_url {
  margin-bottom: 5px;
  width: 100%;
  padding: 12px 10px !important;
}

.biz_lib_file {
  /* width: 50%; */
  padding: 10px;
  -webkit-border-radius: 5px;
  border: 1px dashed #012b64;
  text-align: center;
  background-color: #DDD;
  cursor: pointer;
}

.columndata {
  float: left;
  width: 50%;
  padding: 10px;
  height: 300px;
  /* Should be removed. Only for demonstration */
}

/* Clear floats after the columns */
.rowdata:after {
  content: "";
  display: table;
  clear: both;
}


/* 02/07/2024 */
.cstm-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.cstm-col-4 {
  width: 33.3%;
  padding: 10px;
}

.PresentationImage {
  width: 100%;
  object-fit: cover;
}

.RemovePpt {
  position: absolute;
  top: 0px;
  right: 0px;
  display: none;
  transform: translate(50%, -50%);
  cursor: pointer;
}

.PptParent {
  position: relative;
}

.PptParent:hover .RemovePpt {
  display: flex;
}

/* modal */
.product-modal-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-modal-center .product-modal-inner {
  width: 700px;
  max-width: 80%;
}

.product-modal-center .user-provisional-outer {
  width: 100%;
}

.product-modal-body {
  background-color: #ffffff;
  /* padding: 10px; */
}

/* modal end */

.schedule-confi {
  color: var(--productcolor);
  font-size: var(--proheadingfontsize);
  font-style: var(--prohendingfontstyle);
}

.cus_report strong {
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.cus_report span {
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.cus_report_heading {
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.cus_report label {
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.paragraph {
  color: var(--paracolor);
  font-size: var(--paragraphfontsize);
  font-style: var(--paragraphfontstyle);
}

.heading_style {
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.paragraph_style {
  color: var(--paracolor);
  font-style: var(--paragraphfontstyle);
}

.font_color {
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

/* input file */
.cstm-close-btn {
  width: 80px !important;
  height: 40px !important;
  border-radius: 2px;
  margin-left: 5px;
  padding: 5px;

}

.cstm-flex-end {
  display: flex;
  justify-content: flex-end;
}

/* input file end */
.border-left {
  border-left: 1px solid #c5c5c5;
}

/* 09/07/2027 company status */


.cstm-col-3 {
  width: 25%;
  padding: 10px;
}

.cstm-card {
  border-radius: 5px;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0px 0px 2px #c0c0c0;
}

.cstm-col-6 {
  width: 50%;
  padding: 10px;
}

.cstm-col-12 {
  width: 100%;
  padding: 10px;
}

.cstmflexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cstm-card-head {
  font-size: 45px;
  color: var(--my-color);
  text-align: start;

}

.cstm-sub-heading {
  font-size: 18px;
  font-weight: 800;
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.cstm-card-svg {
  width: 80%;
}

.cstm-card-svg svg {
  max-width: 70px;
}

.color-primary {
  color: var(--primaryColor);
}

/* cstm table */
.cstm-tbl_container thead {
  background-color: var(--my-color);
  text-align: left;
  color: #ffffff;
}

.cstm-tbl_container tr th:last-child {
  text-align: center;
}

.cstm-tbl_container tr td:last-child {
  text-align: center;
}

.export-csv {
  background-color: var(--my-color);
}

/* .ViewAllTable{
  margin-right: auto;
} */
.cstm-flex-end {
  display: flex;
  text-align: right;
}

.endStart {
  font-size: 16px;
  margin: 0 5px;
  width: 151px;
  padding: 5px;
  border: 1px solid;
  border-color: #c5b9b9;
}

.tbl-td-th {
  padding: 10px !important;
}

.iconspace {
  display: flex;
  justify-content: space-evenly;
}

.iconspace button {
  background-color: transparent !important;
  margin-right: 0px !important;
}

.countemp {
  font-size: 15px;
  font-weight: 600;
  padding: 0px;

}

/* dragan drop css start*/
.flexible-modal {
  position: absolute;
  z-index: 1;
  border: 1px solid #ccc;
  background: white;
  border-radius: 8px;
}

.flexible-modal-mask {
  position: fixed;
  height: 100%;
  background: transparent !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.flexible-modal-resizer {
  position: absolute;
  display: none;
  right: 0;
  bottom: 0;
  cursor: se-resize;
  margin: 5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}

.flexible-modal-drag-area {
  /* background: var(--my-color) !important; */
  background: transparent !important;
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: move;
}

.dramodal {
  color: #ffffff !important;
}

.drag-btn {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  position: absolute;
  bottom: 20px;
  right: 5px;
}

.drag-btn-close {
  width: 80px;
  font-size: larger;
  margin-right: 10px;
}

.dragbody {
  margin-left: 10px;
  margin-top: 10px;
  height: 250px;
  overflow-y: auto;
}

.dragbody input {
  margin-right: 10px;
}

/* .draginp {
  margin-top: 10px;
} */

/* .my-modal-custom-class {
  color: #ffffff !important;
} */

.my-modal-custom-class .modalTitle {
  color: #ffffff;
  font-weight: 500;
  width: 100%;
  font-size: 26px;
}

.Cstmsubtitle {
  border-bottom: 0px solid var(--my-color);
  padding: 0px 0px 0px 0px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 12px;
}

.dramodal {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--my-color) !important;
  text-align: center;
  height: 60px;
  border-radius: 8px 8px 0 0;
}

.draginp {
  padding: 10px;
  /* background: #39105b1c; */
}

.Cstmdraginp {
  padding: 10px;
  background-color: #fff;
}

.drag-loader {
  height: 100px !important;
  width: 100px !important;
  margin-top: 100px;
  margin-left: 230px;
}

.tabmar {
  margin: 0px 50px 0px 50px;
}

.tabmars {
  margin: 40px 50px 30px 50px;
}

.loader-height {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

/* dragan drop css end */

button#draghandle {
  position: absolute;
  bottom: -42px;
  right: 0;
}

.prod-card {
  position: relative;
}

.CstmFlex {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  cursor: pointer;
}

.Cstmdraginp {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

}

.sl-heart1 .sl-loader {
  width: 95px;
}

.sl-spinner3 .sl-loader {
  width: 60px;
}

.sl-spinner3 .sl-loader .sl-loader-parent .sl-circle {
  width: 56px;
  height: 56px;
}

.sl-bubble5 .sl-loader {
  width: 75px;

}

.sl-box4 .sl-loader {
  width: 74px;
  margin-left: 20px;
}

.sl-box1 .sl-loader .sl-loader-items .sl-parent-cub {
  width: 56px;
  height: 56px;
}

.sl-bubble4 .sl-loader {
  width: 70px;

}

.sl-bubble3 .sl-loader {
  width: 110px;

}

.sl-ekvalayzer .sl-loader-items {
  width: 60px;
  height: 56px;
}

.sl-bubble2 .sl-loader {
  width: 90px;
  height: 40px;
  margin-left: 15px;
}

.sl-bubble1 .sl-loader {
  width: 90px;
}

.sl-box1 .sl-loader .sl-loader-items {
  width: 100%;
  height: 60px;

}

.sl-box3 .sl-loader .sl-loader-items .sl-parent-cub {
  width: 50px;

}

.CstmHeight {
  height: 145px;
  overflow-y: auto;
}

.cus_loader {
  height: 70px !important;
  width: 70px !important;
  margin: 0px 0px 0px 25px;
}

.box-loader {
  padding: 10px;
  border: 0px solid #a3a3a3;
  /* background-color: #ffffff; */
  border-radius: 5px;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85px;
  width: 85px;
}

.show-Icon {
  font-size: 20px;
}

.plusIcon {
  padding-left: 425px;
}

.plusIcons {
  padding-left: 380px;
}

.plusIcons-font {
  padding-left: 324px;
}

.plusIcons-loader {
  padding-left: 415px;
}

.plusIcon_logo {
  padding-left: 365px;
}

.loader-plus-iconlement {
  padding: 10px;
  border: 0px solid #cccccc;
  background-color: #dddddd;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.subtitle-line {
  margin: 0px 10px 14px 1px;
}

.sub-plusIcon {
  margin-left: 335px;
}

.sub-st-plusIcon {
  margin-left: 314px;
}

.sub-st-para-plusIcon {
  margin-left: 300px;
}

.sub-plusIcons {
  margin-left: 325px;
}

.loader-plus-iconlement-sub {
  padding: 10px;
  border: 0px solid #cccccc;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.font_size {
  height: 40px;
  width: 80%;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px;
  font-size: 15px;
}

/* new css */

/* .prod-description-extra-dev {
  display: none;
} */

.CstmModalScroll {
  max-height: 20vh;
  overflow-y: auto;

}

.market-place-card .description {
  max-height: 130px;
  overflow-y: auto;
  padding: 10px;
}

.cus_font {
  margin: 0px 0px 0px 0px;
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
  font-size: 15px;
}

.data_space p {
  margin-right: 10px !important;
}

/*18/096/2024*/
.product-cards {
  height: calc(100% - 0px);
}

.product-cards-outer::-webkit-scrollbar {
  display: none;
}

.total-card {
  align-items: unset;
}

.total-card .companyInfoCardCol .cstm-card {
  height: 100%;
}

.select-employee .top {
  flex-direction: column;
  align-items: start;
}

.integration-btn {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.themesetting-outer .product-setting-inner .HideShow .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #dddddd;
  padding: 12px 10px 12px 0px;
  border-radius: 8px;
}

.themesetting-outer .product-setting-inner .HideShow .top label {
  background: none;
  box-shadow: unset;
}

.themesetting-outer .product-setting-inner {
  padding: 0px 0px 0px 10px;
}

.themesetting-outer .product-setting-inner .HideShow .bot .HideShow .top {
  width: calc(100% - 110px);
  margin-left: 10px;
}

.themesetting-outer .product-setting-inner .HideShow .bot .HideShow .controllers {
  margin-left: -40px;
}

/*18/096/2024*/

.sync_history_mar {
  margin: 0px 35px 0px 0px
}

.sync_trainig {
  margin-top: 15px;
}

.sync_tab_position {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #fff;
}

.tabmar_tab {
  overflow-y: scroll;
  height: 445px !important;
}

.date_search {
  margin-right: 10px;
  font-size: large;
}

.date_search_inp {
  margin-right: 52px;
  font-size: large;
}

.sync_enb {
  height: auto;
  width: 165px !important;
}

.cus_sync {
  display: flex;
  justify-content: space-between;
}

.tab_head {
  position: -webkit-sticky;
  position: sticky;
  top: 45;
  background-color: #fff;
  z-index: 1
}

.cus_mod_popup {
  display: flex;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  align-items: center;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.D_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d_width {
  width: 50px;
}

.modal_open_cus {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.modal_open_cus_body_popup {
  background-color: #fff;
  padding: 20px !important;
  border-radius: 10px !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) !important;
  width: 45%;
  height: 55vh;
  overflow-y: auto;
  margin-top: -210px;
}

.tab_not_found {
  font-weight: bold;
  font-size: 25px;
  text-align: center;
}

.company-login {
  color: var(--my-color);
  font-size: 20px;
}

.company-delete {
  color: var(--my-color);
}

.company-restore {
  color: red;
}

.activity_chart {
  width: 46% !important;
  padding: 10px !important;
}

.activity_chart svg {
  width: 700 !important;
}

/* market btn view */
.market-btn {
  display: flex;
}

.product-item-png {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-item-png img {
  width: 70%;
  object-fit: contain;
  padding: 2px 2px 2px 2px;
}

.no-product {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 600;
}

.market-btn button {
  margin-left: 15px;
  height: 25px;
  /* margin-bottom: 7px; */
  margin-top: 10px;
}

.font_logs td {
  font-family: monospace !important;
}

.login-comp input {
  height: 50px;
  width: 100%;
}

.pro-tab {
  display: flex;
  justify-content: center;
  /* margin-top: 20px; */
  margin: 15px 0px 15px 0px;
  font-size: 20px;
  font-weight: 800;
}

.close-eye {
  margin: 15px 0px 0px -30px;
  cursor: pointer;
}

.tab-head {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.logo-pro-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-pro-div img:hover {
  cursor: pointer;
}

.comp-pro-img {
  height: 65px;
  width: 62px;
  padding: 2px 2px 2px 2px;
  object-fit: contain;
}

.cursor_point:hover {
  cursor: default !important;
}

.comp-pro-imgs {
  width: 100px;
  padding: 2px 2px 2px 2px;
  object-fit: contain;
}

/* end */
.pending {
  color: rgb(244, 143, 12);
}

.success {
  color: green;
}

.fail {
  color: red;
}

@media only screen and (max-width: 552px) {
  .mul-association-btn button.ms-2 {
    margin-top: 10px;
  }

  .CstmModalScroll {
    max-height: calc(100vh - 710px);
    overflow-y: auto;
  }

  .mul-association-btn button {
    padding: 12px 30px !important;
    width: 70%;
  }

  .CstmSettingWrapper {
    padding: 15px;
  }
}

.start_date_dash {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #fff;
  padding: 3px 5px;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
  width: 190px;
  margin-bottom: 15px;
}

.Ann_btn {
  display: flex;
  justify-content: flex-end;
  /* margin-right: 0px; */
  position: static;
  bottom: 20px;
}


/* Market place list */
.market-list {
  height: 700px;
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 20px 0px 20px 20px;
}

/* .market-list p {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
} */
.market-list .market-place-card {
  width: 310px;
  border-radius: 10px 10px 10px 10px;
  border-bottom: aliceblue;
  box-shadow: rgba(0, 0, 0, -0.7) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.market_name {
  text-align: center !important;
  margin-bottom: 20px;
}

/* Nandan P 01/15/2024 starts */

.marketcard-border {
  border: 1px solid #e3e3e3;
}


.grid-marketlist {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 20px 20px 8px;
  grid-gap: 28px 16px;
  width: 100%;
  margin-inline: 0px;
}

.grid-marketlist .market-place-card {
  margin: 0px;
  width: 100%;
  padding: 24px;
}


.grid-marketlist .market-place-card .description {
  padding: 10px 10px 0px;
}

.set-bottom .market-place .slick-list .slick-track {
  padding: 12px 0px;
}

.set-bottom .market-place-card {
  margin: 1rem .5rem;
}

/* New css */

.IconTh {
  width: 10% !important;
}

.pro_connecter .product-modalBody {
  width: 85vw;
  height: 750px;
}
.pro_connecter .company_system_table .tbl_container{
  height: 600px;
}
.FormPageRoot {
  background: linear-gradient(180deg, #002e6c 200px, #f2fafd 0);
  padding: 10px;
  padding-top: 120px;
  flex: 1 1;
  background-color: #f2fafd;
}

.FormPageRoot .contentOuter {
  max-width: 800px;
  margin: 0 auto;
}

.FormPageRoot .contentInner {
  background-color: white;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}

.FormPageRoot .footer {
  height: 70px;
}

.FormPageRoot .nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.FormPageRoot .nav .content {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.FormPageRoot .nav .rightSide .backto a {
  color: white;
  font-weight: 800;
}

.FormPageRoot .nav .logo {
  width: 120px;
}

.FormPageRoot .nav .logo img {
  width: 100%;
  display: block;
}

@media only screen and (max-width: 768px) {
  .FormPageRoot .nav .rightSide .backto a {
    font-size: 14px;
  }

  .FormPageRoot .footer {
    height: 50px;
  }
}

.ein-report .heading {
  padding: 40px 0;
}

.ein-report .heading h1 {
  font-size: 4rem;
}

.report-container {
  width: 85%;
  margin: 0 auto;
  margin-bottom: 40px;
}

.report-table {
  position: relative;
}

.report-table table,
.report-table th,
.report-table td {
  border: none;
  /* border-bottom: 1px solid rgb(209, 209, 209); */
}

.report-table td {
  border: none;
  border-bottom: 2px solid rgb(209, 209, 209);
  font-size: 19px;
}

.report-table table thead {
  background-color: rgb(224, 224, 224);
}

.report-table table thead th {
  font-weight: 600;
  font-size: 22px;
  text-align: left;
}

.report-table table tbody tr:nth-child(even) {
  background-color: unset;
}

.report-table .table {
  height: 400px;
  overflow: auto;
}

.report-table .table::-webkit-scrollbar {
  width: 15px;
}

.report-table .table::-webkit-scrollbar-track {
  background-color: rgba(224, 224, 224, 0.363);
}

.report-table .table::-webkit-scrollbar-thumb {
  background: #a0a0a0;
  border-radius: 10px;
}

.report-table .table::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.report-table .pagination {
  padding: 15px 0;
}

.pagination {
  text-align: right;
}

.report-card-row {
  display: flex;
}

.report-card {
  width: 33%;
  padding: 15px;
}

.report-card .item-card {
  border-radius: 5px;
  padding: 20px;
  display: flex;
  border: 1px solid #ddd;
}

.report-card .item-card .icon {
  width: 50px;
  padding: 50px;
}

.report-card .item-card .icon.company-icon {
  width: 130px;
  padding: 10px;
}

.report-card .item-card .icon.employee-icon {
  width: 100px;
  padding: 25px;
}

.report-card .item-card .icon img {
  width: 100%;
}

.report-card .item-card h5 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  line-height: 1.3;
  color: #262b40;
  font-size: 1.25rem;
}

.report-card .item-card h2 {
  text-align: left;
}

.report-card .item-card p {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 14px;
}

.table-report table thead tr {
  border-bottom: 1px solid #000;
  background-color: #f5f8fb;
}

.table-report table tr {
  border-bottom: 1px solid #ddd;
}

.table-report table,
.table-report th,
.table-report td {
  border: none;
  text-align: left;
  padding: 15px 20px;
  white-space: nowrap;
}

.table-report {
  /* height: 350px; */
  width: 100%;
  overflow: auto;
}

.table-report::-webkit-scrollbar {
  width: 15px;
}

.table-report::-webkit-scrollbar-track {
  background-color: rgba(224, 224, 224, 0.363);
}

.table-report::-webkit-scrollbar-thumb {
  background: #a0a0a0;
  border-radius: 10px;
}

.table-report::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.report-pagination {
  text-align: right;
  margin-top: 15px;
}

.report-pagination > span {
  margin: 0 10px;
}

.report-pagination > button {
  margin: 0 10px;
}

.export-btn-container {
  /* text-align: right; */
  padding: 20px 10px;
  position: relative;
}

.btn-export {
  text-align: right;
}

.dropdown-list {
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 50px;
  right: 10px;
}

.dropdown-list li {
  border-bottom: 1px solid #ddd;
  padding: 15px 50px;
  cursor: pointer;
}

.dropdown-list li:hover {
  background-color: #ddd;
}

.option-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.graph-item {
  padding: 0 !important;
}

button:disabled {
  background-color: #a0a0a0;
}

.curser-loading {
  cursor: progress;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.progress-bar.loading::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  animation-name: example;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

@keyframes example {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.inner-drop-down {
  position: absolute;
  top: 0;
  right: 100%;
  background-color: #fff;
  list-style: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 0;
  display: none;
}

.inner-drop-down > li {
  white-space: nowrap;
}

.dropdown-list > li {
  position: relative;
}

.dropdown-list > li:hover .inner-drop-down {
  display: block;
}

:root {
  --my-color: #002e6c;
  --color: #ffffff;
  --widthcard: 546px;
  --background: #f0f3f4;
  --productcolor: #000;
  --paracolor: #000;
  --proheadingfontsize: 20px;
  --prohendingfontstyle: '';
  --paragraphfontstyle: '';
  --paragraphfontsize:16px
}

.emp-content.sidebar .emp-side-bar {
  left: -220px;
}

.emp-content.sidebar .emp-body {
  width: 100%;
}

.outline {
  padding-bottom: 0;
}

.btn_grp_mng {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.emp-side-bar {
  width: 220px;
  background: var(--my-color);
  /* background: #002e6c; */
  position: fixed;
  top: 87px;
  left: 0;
  height: 100%;
  transition: 0.3s;
}

.emp-body {
  width: calc(100% - 220px);
  margin-left: auto;
  background: #f0f3f4;
  transition: 0.3s;
  /* padding: 25px; */
  height: 100vh;
}

.emp-body .emp-body-inner {
  padding: 96px 25px 25px 25px;
  min-height: 100vh;
  background-color: var(--background);

}

.emp-dash-card {
  width: 20%;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
}

.dash-icon {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  background-color: #f5365c !important;
  color: #fff;
  font-size: 20px;
}

.emp-row {
  display: flex;
  flex-wrap: wrap;
}

/* side bar css start */
.dash-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.side-bar-prod-icon {
  width: 40px;
}

.dash-menu>li>span {
  padding: 12px 20px;
  color: white;
  display: block;
  font-size: 16px;
  border-bottom: 1px solid #fff;
}

.dash-menu>li>span.active {
  cursor: pointer;
  color: var(--my-color);
  background-color: #fff;
}

.dash-menu li>span:hover {
  cursor: pointer;
  color: var(--my-color);
  background-color: #fff;
}

.dash-menu>li>span svg {
  padding-right: 10px;
}

.dash-menu .drop-down>li>span {
  padding: 12px 20px;
  color: white;
  display: block;
  font-size: 16px;
  border-bottom: 1px solid #fff;
}

.dash-menu .drop-down>li>span.active {
  cursor: pointer;
  color: var(--my-color);
  background-color: #fff;
}

.dash-menu .drop-down>li>span:hover {
  cursor: pointer;
  color: var(--my-color);
  background-color: #fff;
}

/* side bar css end */

header {
  display: flex;
  justify-content: space-between;
  /* background: #002e6c; */
  background: var(--my-color);
  width: 100%;
  padding: 10px 10px;
  box-sizing: border-box;
  align-items: center;
  position: fixed;
  z-index: 1;
}

.header-right button {
  cursor: pointer;
}

/* .header-right img {
  width: 40px;
  background: white;
  border-radius: 50%;
  padding: 5px;
} */
.header_img {
  width: 40px;
  background: white;
  border-radius: 50%;
  padding: 5px;
}

.theme_img{
  /* width: 40px !important;
  background: white !important;
  border-radius: 50% !important; */
  padding: 5px;
  margin-top: -10px;
}

ul.drop_down-admin {
  position: absolute;
  right: 10px;
  top: 62px;
  min-width: 140px;
  background: white;
  padding: 0px 2px;
  border-radius: 6px;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 0 16px #00000030;
}

ul.drop_down-admin li {
  list-style: none;
  padding: 10px;
}

ul.drop_down-admin li a {
  display: block;
  /* padding: 5px 15px; */
}

ul.drop_down-admin li a:hover {
  background: var(--my-color);
  color: white;
}

.hover-active:hover {
  background: var(--my-color);
  color: white;
  cursor: pointer;
}

.logo {
  padding-left: 20px;
  display: flex;
}

.logo-wrp {
  width: 180px;
}

.open_sidebar-close svg {
  font-size: 20px;
}

.logo img {
  width: 85%;
}

ul.drop_down-admin.active {
  visibility: visible;
  opacity: 1;
}

.tlt_dash {
  text-align: left;
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.bx_cstm-imp {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
}

.bx_ctsm_con {
  width: 25%;
  background: white;
  border: 0;
  display: flex;
  align-items: center;
  padding: 0;
  box-shadow: 0 0 10px #00000026;
  border-radius: 4px;
}

.bx_ctsm_con svg {
  width: 50px;
  height: 50px;
  padding-right: 30px;
  color: white;
  background: #002e6c;
  padding: 20px 20px;
  margin-right: 20px;
  border-radius: 4px 0 0 4px;
}

.detail_bx-cstm p {
  margin: 0;
  padding: 0;
  color: #606060;
  font-weight: 500;
  padding-top: 16px;
}

.detail_bx-cstm h3 {
  margin: 0;
  padding: 0;
  color: #a3a3a3;
  font-size: 28px;
  font-weight: 600;
}

.table-wrpcstm {
  margin-top: 40px;
}

.table-wrpcstm table tr th {
  text-align: left;
}

button.btn_act_delete {
  background: #dc3545;
  margin-right: 10px;
}

ul.under_drop {
  padding: 0;
  background: #0b62a1;
  height: 0;
  opacity: 0;
  visibility: hidden;
}

li.drop_menu a .icon_rights {
  position: absolute;
  right: 0;
  top: 0;
  padding: 14px 10px;
}

li.drop_menu a {
  position: relative;
}

.bx_search_filter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.bx_search_filter .search_bx-wrp {
  position: relative;
}

.bx_search_filter .search_bx-wrp button {
  position: absolute;
  top: 0;
  right: 0px;
  padding: 6px 10px;
  background: transparent;
  color: black;
}

.pagination_wrp ul {
  display: flex;
  justify-content: flex-end;
  padding: 0;
  padding-right: 10px;
  margin: 0;
  padding-top: 20px;
  grid-gap: 0;
  gap: 0;
}

.pagination_wrp ul li {
  list-style-type: none;
}

.pagination_wrp ul li:first-child button {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.pagination_wrp ul li button {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #002e6c;
  background-color: transparent;
  border: 1px solid #d7d7d7;
  border-radius: 0;
}

.pagination_wrp ul li:last-child button {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

/* dashboard card style start */
.dash-product-row {
  display: flex;
  flex-wrap: wrap;
}

.dash-product-card {
  width: 33%;
}

.dash-product-cards {
  width: var(--widthcard);
  /* resize: horizontal;
  overflow: hidden;  */
}

.dash-product-card p {
  margin: 0;
}

.prod-card {
  border: 1px solid #ddd;
  padding: 20px 20px 0 20px;
  cursor: pointer;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  border-radius: 8px;
  transition: 0.3s;
  margin: 10px 10px;
  /* height: 100%; */
  /* background: var(--bgcolor); */
  background: var(--bgcolor);
}

.prod-card:hover {
  transform: translate(0, 3px);
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.ess-portal .prod-card {
  padding: 20px 20px 20px 20px;
}

.prod-card img {
  width: 100%;
}

.prod-card .prod-img {
  max-width: 75px;
  min-width: 40px;
  width: 100%;
  margin-left: 12px;
}

.card-bar-one .card-content {
  width: calc(100% - 75px);
  margin-top: 4px;
}

.prod-card .prod-heading {
  /* font-size: 20px; */
  font-size: var(--proheadingfontsize);
  font-weight: 700;
  margin-bottom: 10px;
}

.prod-card .card-bar-one {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-bar-one input.prod-heading {
  background: none;
  padding: 0;
  border: 1px solid var(--bgcolor);
}

.card-bar-one input.prod-heading.active:focus {
  border: 1px solid var(--my-color) !important;
}

.card-bar-one textarea.prod-description {
  background: none;
  padding: 0;
  border: 1px solid var(--bgcolor);
  width: 100%;
  font-size: var(--paragraphfontsize);
  /* height: calc(1em * 1.5 * 5 + 3px + 3px + 1px + 1px); */
  height: 100px;
  resize: none;
  cursor: context-menu;
  position: relative;
  color: var(--paracolor);
  font-style: var(--paragraphfontstyle);
}

.card-bar-one textarea.prod-description.active:focus {
  border: 1px solid var(--my-color) !important;
}

.prod-heading-style {
  font-style: var(--prohendingfontstyle);
}

/* dashboard card style end */

/* Nandan P 15/01/2024 */

.set-layout {
  display: flex;
  flex-direction: column;
}

.set-bottom {
  margin-top: auto ;
}
@media screen and (min-width: 1920px) {
}
@media screen and (max-width: 1920px) {
  .ein-report.dashboard-report-emp .report-card .item-card {
    height: 100%;
  }

  .ein-report.dashboard-report-emp .heading {
    justify-content: center;
  }
  .emp-dashboard-body .WebclockAttestation .step .stepHeader {
    flex-direction: column;
  }
  .emp-dashboard-body .JobBoard .head {
    display: flex;
    justify-content: space-between;
  }
  .emp-dashboard-body .JobBoard .head h1 {
    width: 78%;
    text-align: left;
  }
  /* .emp-dashboard-body .breadcrumbs {
    margin-top: 11px;
  } */
  .emp-dashboard-body .emp-body {
    height: 100%;
  }
  button.one-control-btn.sftp-add-btn {
    padding: 14px 45px;
    margin: 10px 8px 0;
  }
  .product-card-outer-out.productcardout-list {
    width: 25%;
  }
  .Integration-outer.integration-active .product-card-content .product-card-outer-out.productcardout-list {
    width: 20%;
}
  .product-modal
    .product-modal-content.productmodalcontent-sm
    .one-col-sm-10.d-flex {
    position: relative;
  }
  .emp-dashboard-body .JobBoard .img {
    width: 220px;
  }
  .emp-dashboard-body .JobBoard .img img {
    width: 100%;
  }
}
@media(max-width:1440px){
  .cstm-col-3.companyInfoCardCol{
    width: 33.3%;
  }
  .grid-marketlist {
    grid-template-columns: repeat(4 , 1fr);
  }
}

@media screen and (max-width: 1366px) {
  button.one-control-btn {
    padding: 14px 10px;
  }
  .dash-product-card.productcard-list,
  .product-card-outer-out.productcardout-list {
    width: 33%;
  }
  .product-modal-content .date-picker .date-range-log {
    top: 43px;
  }
}

@media screen and (max-width: 1224px) {
  .product-setting-section .one-col-6 .label {
    font-size: 14px;
  }
  .product-setting-inner button,
  .product-setting-inner1 button {
    padding: 12px 10px;
  }
  .grid-marketlist {
    grid-template-columns: repeat(3 , 1fr);
  }
}

@media screen and (max-width: 1024px) {
  .emp-dashboard-body .Indeed .sponsor .job .title {
    font-size: 16px;
  }
  .emp-dashboard-body .Indeed .sponsor .job .top .main {
    flex-wrap: wrap;
  }
  .one-col-1.scheduler {
    width: 18%;
  }
  .my-align-item-center {
    align-items: flex-start;
  }
  button.one-control-btn.quick-connect {
    margin-left: 10px;
  }
  .one-col-2.sftp-input {
    width: 32.6666%;
    margin-bottom: 10px;
  }
  :is(.product-modal-content.productmodalcontent-lg) {
    padding: 10px;
    height: auto;
  }

  .product-modal-content.productmodalcontent-lg .imp-container-report {
    padding: 16px;
  }
  .ein-report.dashboard-report-emp .heading h1 {
    font-size: 34px;
  }
  .ein-report.dashboard-report-emp .report-card .item-card {
    justify-content: center;
    flex-wrap: wrap;
  }
  .dashboard-report-emp .item-card {
    height: 100%;
  }
}

@media screen and (max-width: 992px) {
  .dashboard-report-emp .report-card-row {
    display: flex;
    flex-wrap: wrap;
  }
  .dashboard-report-emp .report-card {
    width: 48%;
    padding: 6px;
  }
  .emp-dashboard-body a.pagination__link {
    padding: 4px 10px;
    font-size: 16px;
  }
  .emp-dashboard-body .JobsBody .appsCont .app .top .name {
    margin-right: 20px;
    font-size: 16px;
  }
  .emp-dashboard-body .sourceTime {
    font-size: 14px;
  }
  .emp-dashboard-body .pagination-container a {
    padding: 4px 10px;
    font-size: 16px;
  }
  .one-col-2.export-file-name {
    width: auto;
  }
  .one-col-2.notification-type {
    width: auto;
  }
  .my-align-item-center.sftp-section {
    width: 100%;
    margin-bottom: 11px;
    margin-top: 5px;
  }
  .one-col-2.export-file-type {
    width: auto;
  }

  .one-col-2.import-input {
    width: auto;
  }
  .one-col-1.scheduler label {
    font-size: 14px;
  }
  .my-flex.scheduler-type {
    width: 100%;
  }
  /* .product-modal.productmodal-full .product-modalBody {
    width: 88%; */

  /* .emp-body .emp-body-inner {
    padding-top: 76px;
  } */
  .product-modal-content.productmodalcontent-lg
    :is(.one-col-2, .one-col-10, .one-col-3) {
    width: 100%;
    margin-bottom: 8px;
  }

  .product-modal-content.productmodalcontent-lg .import-schedule-report {
    overflow-y: scroll;
    height: calc(100vh - 120px);
    padding-inline: 10px;
  }

  .product-modal-content.productmodalcontent-lg
    .import-schedule-report
    .one-control-input
    input[type="radio"] {
    margin-right: 4px;
  }

  .product-modal-content.productmodalcontent-lg
    :is(
      .one-control-input,
      .one-control-select,
      .import-schedule-report .one-control-input > label
    ) {
    padding: 0px;
  }

  .product-modal-content.productmodalcontent-lg
    :is(.scheduler-type-container, .one-control-input-date.ms-2) {
    margin-left: 0px;
  }
  .product-left-content {
    width: 100%;
  }

  .product-modal-content.productmodal-padding {
    padding: 10px 8px;
    height: calc(100vh - 60px);
  }
  .product-modal-content.productmodal-padding .product-card-inner2 h4 {
    font-size: 11px;
  }
  .emp-content.sidebar .emp-side-bar {
    left: 0px;
    top: 79px;
    z-index: 30;
  }
  .emp-side-bar {
    left: -220px;
    top: 79px;
    z-index: 30;
  }

  .emp-body {
    width: 100%;
  }
  .logo {
    flex-grow: 1;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .logo-wrp {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }

  .logo img {
    max-width: 140px;
    width: 100%;
  }

  .middleproductcard-section {
    width: 100%;
  }

  .middleproductcard-section-overlay {
    width: 100%;
    position: fixed;
    right: 0px;
    top: 0px;
    height: 100%;
    -webkit-backdrop-filter: blur(1px);
            backdrop-filter: blur(1px);
    z-index: 10;
  }

  .system-admin-outer {
    position: fixed;
    width: 100%;
    max-width: 300px;
    right: 0px;
    top: 122px;
    padding: 12px;
    background-color: #f0f3f4;
    height: calc(100vh - 150px);
    z-index: 50;
  }

  .product-setting-section,
  .product-right-content {
    width: 50%;
    visibility: hidden;
    position: fixed;
    right: 0%;

    height: calc(100vh - 130px);
    transform: translate(100%, 0);
    background-color: #fff;
  }

  .product-setting-section.setting-hide,
  .product-right-content.setting-hide {
    visibility: visible;
    transform: translate(0, 0);
    position: fixed;
    right: 2%;
    height: calc(100vh - 130px);

    overflow: auto;
  }

  .product-right-content.setting-hide {
    right: 0%;
    height: calc(100vh - 110px);
  }

  .Integration-outer {
    width: 100%;
  }

  .product-modal.productmodal-full .Integration-outer {
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
  }

  .product-modal.productmodal-full .product-modal-inner {
    padding: 0px;
  }

  .product-modal.productmodal-full .product-modalBody {
    width: 100%;
    /* height: 100vh; */
  }
  .product-modal-content {
    height: auto;
    min-height: 620px;
  }

  .product-setting-inner1 {
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
    overflow: visible;
  }
  .dashboard-integration-doc,
  .dashboard-integration-doc.association.open {
    width: 100%;
  }
  .container-banner {
    padding-top: 76px;
  }
  .hero-banner-content {
    top: 76px;
  }
  .ess-portal .logo-wrp {
    justify-content: flex-start;
  }

  .product-left-content,
  .integration-document {
    height: calc(100vh - 120px);
  }
  .product-left-content.integration-active .side-img {
    padding: 16px;
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
  }

  .ess-portal .dash-product-card {
    width: 50%;
  }
  .landingpage-userpro.my-flex {
    flex-direction: column-reverse;
  }
  .landingpage-userpro.my-flex .one-col-9 {
    width: 100%;
  }


  /* responsive css  */
  .cstm-col-3.companyInfoCardCol{
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .header-company-info {
    width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dash-product-card.productcard-list,
  .product-right-content,
  .ess-portal .dash-product-card {
    width: 100%;
  }
  /* .product-modal.productmodal-full .product-modalBody {
    width: 95%;
    margin-top: 40px;
  } */
  .product-modal-content
    .date-picker
    .date-range-log
    .rdrDateRangePickerWrapper
    .rdrDefinedRangesWrapper {
    width: 144px;
  }
  .product-modal-content
    .date-picker
    .date-range-log
    .rdrDateRangePickerWrapper
    .rdrDefinedRangesWrapper
    .rdrInputRanges
    .rdrInputRange {
    padding-left: 0;
  }
  .product-modal-content
    .date-picker
    .date-range-log
    .rdrDateRangePickerWrapper
    .rdrDefinedRangesWrapper
    .rdrStaticRangeLabel {
    padding-left: 0;
  }
  .product-modal-content .date-picker .date-range-log {
    top: 54px;
    right: 13px;
  }
  .one-col-1.scheduler label {
    padding: 4px;
  }
  .one-col-1.scheduler {
    margin-bottom: 10px;
    width: 30%;
  }
  .import-schedule-report.heading-report-scheduler h1 {
    margin-top: 18px;
    font-size: 20px;
  }
  .emp-dashboard-body .pis h1 {
    font-size: 24px;
  }
  .emp-dashboard-body .JobsBody .header h2 {
    font-size: 20px;
  }
  .JobsBody .jobTitle {
    font-size: 15px;
    font-weight: 600;
  }
  .emp-dashboard-body .JobsBody .appsCont .app .top .name {
    margin-right: 15px;
    font-size: 14px;
  }
  .emp-dashboard-body .sourceTime {
    font-size: 11px;
  }
  .emp-dashboard-body .pagination-container a {
    padding: 4px 10px;
    font-size: 14px;
  }
  .emp-dashboard-body .pis h2 {
    font-size: 20px;
  }
  .emp-dashboard-body .BaseProductSetting .title {
    font-size: 16px;
  }
  .emp-dashboard-body .JobBoard .img {
    width: 130px;
  }
  .emp-dashboard-body .JobBoard .head {
    margin-top: 11px;
    align-items: center;
  }
  .emp-dashboard-body .Indeed .sponsor .job .top .actions {
    text-align: left;
  }
  .emp-dashboard-body .enSetting .selectSetting {
    flex-wrap: wrap;
  }
  .emp-dashboard-body .enSetting .selectSetting select {
    width: 450px;
  }
  .emp-dashboard-body select {
    font-size: 12px;
  }
  .emp-dashboard-body .enSetting .selectSetting select {
    width: 450px;
    font-size: 12px;
  }
  .grid-marketlist {
    grid-template-columns: repeat(2 , 1fr);
  }
}

@media screen and (max-width: 576px) {
  .dashboard-report-emp .report-pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .dashboard-report-emp .report-pagination > button {
    margin: 14px 14px;
    padding: 8px 30px;
  }
  .ein-report.dashboard-report-emp .heading h1 {
    font-size: 30px;
    padding: 10px 20px;
  }
  .dashboard-report-emp .report-container label {
    margin: 14px 14px;
  }
  .dashboard-report-emp .report-container .report-pagination > span {
    margin: 14px 14px;
  }
  .dashboard-report-emp .report-container select {
    margin: 14px 14px;
  }
  .ein-report.dashboard-report-emp .report-container {
    width: 96%;
  }
  .emp-dashboard-body
    .WebclockAttestation
    .step
    .outcomes
    .outcome
    .value
    span {
    flex-wrap: wrap;
  }
  .emp-dashboard-body .JobBoard .head h1 {
    font-size: 20px;
  }
  .emp-dashboard-body .sponsor .job .title {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .emp-dashboard-body .settingsCont .settingsRow .title {
    font-size: 16px;
  }
  .emp-dashboard-body .enSetting .selectSetting select {
    width: 280px;
  }
  .emp-dashboard-body .emp-body .emp-body-inner {
    padding: 86px 17px 25px 17px;
  }
  .emp-dashboard-body h2 {
    font-size: 18px;
  }
  .emp-dashboard-body .JobsBody .boards {
    flex-wrap: wrap;
  }
  .emp-dashboard-body .JobsBody .boards .board {
    /* height: 40px; */
    width: 82px;
  }
  .emp-dashboard-body .JobsBody .jobTitle {
    font-size: 13px;
  }
  .emp-dashboard-body .JobsBody .appsCont .app .top .nameCont .email {
    font-size: 8px;
  }
  .emp-dashboard-body .JobsBody .header h2 {
    font-size: 18px;
  }
  .emp-dashboard-body .tip {
    font-size: 12px;
  }
  .emp-dashboard-body .pis h2 {
    font-size: 18px;
  }
  .one-col-2.export-file-name {
    width: 33%;
  }
  .one-col-2.export-file-type {
    margin-bottom: 10px;
  }
  .logo img {
    max-width: 100px;
    width: 100%;
  }
  .container-banner {
    padding-top: 64px;
  }
  .hero-banner-content {
    top: 64px;
  }

  .hero-banner-content .content-data {
    width: auto;
  }

  .product-modal-content.productmodalcontent-lg :is(.one-col-4) {
    width: 100%;
    margin-bottom: 8px;
  }

  .product-modal.productmodal-full .pagination-container > li a {
    font-size: 12px;
    padding: 6px;
    margin: 0px 4px;
  }

  .product-modal-content.productmodalcontent-sm .one-col-sm-10 {
    width: 100%;
  }

  .product-modal-content.productmodalcontent-sm
    :is(.logs-table, .date-picker > .one-col-sm-2, .one-col-sm-10.d-flex > *) {
    margin: 0px 0px 8px 5px;
  }
  .product-modal-content.productmodalcontent-sm
    :is(.date-picker .d-flex, .date-picker) {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .product-modal.productmodal-full .provision-modal {
    padding: 12px;
    height: 800px;
  }

  .product-card-outer-out.productcardout-list {
    width: 100%;
  }

  .system-admin-outer {
    max-width: 100%;
    height: calc(100vh - 120px);
  }

  .emp-body .emp-body-inner {
    padding-top: 66px;
  }

  .header-right.my-flex p,
  .market-place-card-outer .market-place-card .description p {
    font-size: 14px;
  }

  .market-place-card-outer .market-place-card h2 {
    font-size: 20px;
  }
  .header-right img {
    width: 30px;
    height: 30px;
  }
  .emp-side-bar,
  .emp-content.sidebar .emp-side-bar {
    top: 64px;
  }
  .product-setting-section.setting-hide {
    position: fixed;
    right: 2%;
    height: calc(100vh - 130px);
    overflow: auto;
  }

  .product-setting-inner1 {
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
    overflow: visible;
  }
  .product-modal.productmodal-full
    .product-modalBody
    .product-modal-content
    h1.pb-0 {
    font-size: 20px;
  }
  .product-modal-content.productmodalcontent-sm
    :is(.date-picker .d-flex, .date-picker) {
    padding-top: 3px;
  }
  .product-modal
    .product-modal-content.productmodalcontent-sm
    .one-col-sm-10.d-flex {
    justify-content: center;
  }
  .product-modal
    .product-modal-content.productmodalcontent-sm
    .rdrNextPrevButton {
    width: 20px;
    height: 20px;
    margin: 0 9px;
  }
  .product-modal
    .product-modal-content.productmodalcontent-sm
    .rdrDateDisplayItem
    input {
    height: 25px;
    font-size: 12px;
  }
  .product-modal .product-modal-content.productmodalcontent-sm .rdrDateDisplay {
    margin: 8px;
  }
  .product-modal .product-modal-content.productmodalcontent-sm .rdrMonth {
    width: auto;
  }
  .product-modal
    .product-modal-content.productmodalcontent-sm
    .rdrMonthAndYearPickers
    select {
    padding: 6px 21px 6px 5px;
    font-size: 14px;
  }
  .rdrDayToday .rdrDayNumber span {
    font-size: 14px;
  }
  .product-modal-content
    .date-picker
    .date-range-log
    .rdrDateRangePickerWrapper
    .rdrDefinedRangesWrapper {
    width: auto;
  }
  .product-modal-content
    .date-picker
    .date-range-log
    .rdrDateRangePickerWrapper
    .rdrStaticRanges {
    display: flex;
    flex-direction: row;
  }
  .product-modal-content
    .date-picker
    .date-range-log
    .rdrDateRangePickerWrapper
    .rdrInputRanges {
    display: flex;
    justify-content: space-around;
  }
  .rdrDateRangePickerWrapper {
    flex-direction: column;
  }

  span.rdrDayNumber {
    font-size: 12px;
  }
  .sort-select {
    padding: 2px 4px;
  }
  .import-schedule-report .one-col-2.sftp-input .one-control-input input {
    padding: 10px 8px !important;
    font-size: 14px;
  }
  .import-schedule-report .one-control-select select {
    font-size: 14px;
    padding: 10px 8px !important;
  }
  .one-col-2.sftp-input button.one-control-btn {
    font-size: 14px;
    padding: 11px 19px !important;
  }
  .one-col-4.scheduler-div {
    width: 100%;
  }
  .one-col-2.sftp-input {
    width: 49%;
  }
  .emp-dashboard-body .Indeed .modalOuter {
    z-index: 999;
  }
  .emp-dashboard-body .modalOuter {
    z-index: 999;
  }
  .company-list{
    margin-top: 30px;
  }

  .cstm-col-3.companyInfoCardCol{
    width: 100%;
  }

 
}

/* dashboard-report-emp */

@media screen and (max-width: 420px) {
  .dashboard-report-emp .report-card {
    width: 100%;
  }
  .ein-report.dashboard-report-emp .report-card .item-card {
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
  }
  .ein-report.dashboard-report-emp .heading h1 {
    font-size: 22px;
    padding: 10px 20px;
  }
  .emp-dashboard-body .settingsCont input[type="text"] {
    min-width: auto;
  }
  .emp-dashboard-body .WebclockAttestation .step textarea {
    min-width: 286px;
  }
  .emp-dashboard-body .JobBoard .img {
    width: 120px;
  }
  .my-flex.mb-2.sftp-table {
    overflow: auto;
    height: 304px;
  }
  .sftp-scheduler-type {
    width: 100%;
  }
  .import-schedule-report.heading-report-scheduler h1 {
    margin-top: 5px;
    font-size: 16px;
  }
  .my-align-item-center.sftp-section {
    font-size: 14px;
  }
  .one-col-2.sftp-input {
    width: 100%;
  }
  .one-col-2.sftp-input button.one-control-btn {
    width: 55%;
    font-size: 12px;
    padding: 11px 10px !important;
  }
  .one-col-2.export-file-name {
    width: 100%;
  }
  .one-col-2.export-file-type {
    width: 100%;
  }
  .one-col-2.notification-type {
    width: 100%;
  }
  .one-col-2.import-input {
    width: 100%;
  }
  .one-col-2.scheduler-time-auto {
    width: 100%;
  }
  .one-col-4.scheduler-div {
    width: 100%;
  }
  .one-col-1.scheduler {
    width: 48%;
  }
  .product-modal
    .product-modal-content.productmodalcontent-sm
    .one-col-sm-10.d-flex {
    display: flex;
    justify-content: flex-start;
  }
  .product-modal-content
    .date-picker
    .date-range-log
    .rdrDateRangePickerWrapper
    .rdrDefinedRangesWrapper
    .rdrStaticRangeLabel {
    font-size: 10px;
    padding-right: 12px;
  }
  .product-modal-content .date-picker .date-range-log {
    top: 43px;
    right: 0;
    margin-right: 0 !important;
  }
  .product-modal-head h2 {
    font-size: 14px;
  }
  .product-modal.productmodal-full
    .product-modalBody
    .product-modal-content
    h1.pb-0 {
    font-size: 18px;
  }
  span.back-btn {
    font-size: 12px;
    cursor: pointer;
  }
  .product-close-modal-btn svg {
    width: 18px;
    height: 18px;
  }
  .product-modal .product-modal-content.productmodalcontent-sm .rdrMonth {
    width: 256px;
  }
  .rdrMonthsVertical {
    align-items: center;
  }
  .ess-portal .logo {
    padding-left: 20px;
  }

  .ess-portal .marketplacecard-container {
    margin-inline: 0.5rem;
  }

  .ess-portal .hero-banner-content .content-data {
    top: 25%;
  }
  .emp-body .emp-body-inner {
    padding: 56px 12px 25px 12px;
  }
  .header-right.my-flex p,
  .market-place-card-outer .market-place-card .description p {
    font-size: 13px;
  }

  .container-banner {
    padding-top: 48px;
  }
  .hero-banner-content {
    top: 48px;
  }

  .product-modal-content.productmodalcontent-lg h1 {
    font-size: 18px;
  }
  .logo {
    padding-left: 0px;
  }
  header {
    padding: 4px;
  }

  .product-setting-section {
    width: 100%;
  }
  .card-bar-one .card-content {
    width: calc(100% - 50px);
  }

  .prod-card .prod-heading {
    font-size: 15px;
  }
  .card-bar-one textarea.prod-description {
    font-size: 13px;
  }

  .emp-side-bar,
  .emp-content.sidebar .emp-side-bar {
    top: 52px;
  }
  .market-place-card {
    /* min-height: auto; */
    padding: 0.5rem 1rem;
    margin: 0.5rem;
  }
  .association-con.employee-report h1 {
    font-size: 20px;
  }
  .product-modal-content.productmodalcontent-sm
    :is(.logs-table, .date-picker > .one-col-sm-2, .one-col-sm-10.d-flex > *) {
    font-size: 11px;
  }
  .productmodalcontent-sm button {
    font-size: 11px;
  }
  a.csv-export-btn {
    font-size: 11px;
  }
  .product-modal-content
    .date-picker
    .date-range-log
    .rdrDateRangePickerWrapper
    .rdrDefinedRangesWrapper {
    width: 311px;
  }
  .one-col-sm-10.d-flex {
    justify-content: left;
  }
  .product-modal
    .product-modal-content.productmodalcontent-sm
    .rdrMonthAndYearPickers
    select {
    font-size: 12px;
  }
  .rdrMonthAndYearWrapper {
    justify-content: center;
  }
  span.rdrDateInput.rdrDateDisplayItem.rdrDateDisplayItemActive {
    max-width: 112px;
  }
  .rdrDateDisplayItem + .rdrDateDisplayItem {
    margin-left: 0.833em;
  }
  .rdrDateDisplay {
    display: flex;
    justify-content: center;
  }
  .rdrCalendarWrapper.rdrDateRangeWrapper {
    align-content: center;
    flex-wrap: wrap;
  }
  .product-modal-content
    .date-picker
    .date-range-log
    .rdrDateRangePickerWrapper
    .rdrDefinedRangesWrapper {
    width: 280px;
  }
  .tablecstm thead tr th {
    padding: 7px 6px;
  }
  .product-modal-content.productmodalcontent-sm .logs-table h3.text-center {
    font-size: 12px;
  }
  .tablecstm thead tr th {
    font-size: 11px;
  }
  .emp-dashboard-body .JobsBody .boards .board {
    margin-top: 6px;
    /* height: 45px; */
    width: 158px;
  }
  .emp-dashboard-body .JobsBody .boards .label {
    font-size: 11px;
    padding: 6px 8px;
  }
  .emp-dashboard-body .pis h1 {
    font-size: 18px;
  }
  .emp-dashboard-body .JobsBody .header h2 {
    font-size: 15px;
  }
  .emp-dashboard-body .breadcrumbs {
    font-size: 12px;
  }
  .emp-dashboard-body .JobsBody .appsCont .app .top .nameCont {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .emp-dashboard-body .JobsBody .appsCont .app .top.notReviewed {
    flex-wrap: wrap;
  }
  .emp-dashboard-body .JobsBody .appsCont .app .top {
    flex-wrap: wrap;
  }
  .emp-dashboard-body .JobsBody .rightSide {
    flex-wrap: wrap;
  }
  .emp-dashboard-body .pagination-container a {
    font-size: 12px;
    padding: 4px 7px;
  }
  .emp-dashboard-body .JobsBody .jobTitle {
    font-size: 12px;
  }
  .emp-dashboard-body .JobsBody .bottom .topbuts.resume a img {
    width: 16px;
  }
  .emp-dashboard-body .JobsBody .bottom .topbuts.resume a span {
    font-size: 12px;
  }
  .emp-dashboard-body .ur button {
    font-size: 12px;
    padding: 8px 10px;
  }
  .emp-dashboard-body td {
    font-size: 13px;
  }
  .emp-dashboard-body .BaseProductSetting .title {
    font-size: 14px;
  }
  .emp-dashboard-body input[type="text"] {
    font-size: 14px;
  }
  .emp-dashboard-body button {
    font-size: 10px;
  }
  .emp-dashboard-body .eChooser .buttonCont button:last-of-type {
    font-size: 12px;
  }

  .grid-marketlist {
    grid-template-columns: repeat(1 , 1fr);
  }

  .grid-marketlist .market-place-card {
    min-height: 100%;
  }

  .set-bottom {
    margin-inline: 0px;
  }

  .set-bottom .market-btn {
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    margin-bottom: 0px;
  }

  .market-btn button {
    margin: 0px;
  }

  .set-bottom .market-place h1 {
    font-size: 20px;
    padding: 0px;
  }

  .set-bottom .market-place-card {
    min-height: 200px;
  }

  .set-bottom .market-place .slick-list .slick-track {
    padding: 20px 0px;
  }
}

@media screen and (max-width: 360px) {
  .emp-dashboard-body
    .BaseProductSetting
    .WebclockAttestation
    .step
    .outcomes
    .outcome
    input {
    min-width: 210px;
  }
  .emp-dashboard-body
    .WebclockAttestation
    .step
    .outcomes
    .outcome
    .value
    span {
    align-content: flex-start !important;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start !important;
  }
  .emp-dashboard-body .WebclockAttestation .step .outcomes .outcome > div {
    display: flex;
    flex-direction: column;
    width: 210px;
  }
  .emp-dashboard-body .WebclockAttestation .step select {
    width: 210px;
  }
  .emp-dashboard-body .WebclockAttestation .step {
    min-width: 100%;
  }
  .emp-dashboard-body .selectSetting {
    flex-wrap: wrap;
  }
  .emp-dashboard-body .WebclockAttestation .selectSetting {
    flex-wrap: wrap;
  }
  .emp-dashboard-body .pis .prod-settings .psrow {
    border-left: solid 2px #002e6c;
  }
  .emp-dashboard-body .WebclockAttestation .step textarea {
    min-width: 225px;
  }
  .emp-dashboard-body .settingsRow select {
    width: 232px;
  }
  .emp-dashboard-body .JobBoard .head h1 {
    font-size: 18px;
  }
  .emp-dashboard-body .JobBoard .img {
    width: 89px;
  }
  .emp-dashboard-body .Indeed .sponsor .job .title {
    font-size: 11px;
  }
  .emp-dashboard-body .Indeed .sponsor .job .label {
    font-size: 11px;
  }
  .emp-dashboard-body .documentationBody {
    font-size: 12px;
  }
  .emp-dashboard-body .Indeed .sponsor .job .actions button {
    font-size: 10px;
  }
  .emp-dashboard-body .Indeed .sponsor div {
    font-size: 12px;
  }
  .emp-dashboard-body .enSetting .selectSetting select {
    width: 232px;
    font-size: 12px;
  }
  .emp-dashboard-body .sourceTime {
    font-size: 10px;
  }
  .emp-dashboard-body .pagination-container a {
    font-size: 10px;
    padding: 4px 7px;
  }
  .emp-dashboard-body .JobsBody .appsCont .app .top .name {
    margin-right: 13px;
    font-size: 11px;
  }
  .emp-dashboard-body .BaseProductSetting input {
    min-width: 244px;
  }
  .emp-dashboard-body .JobsBody .boards .board {
    width: 113px;
  }
  .emp-dashboard-body .JobsBody .jobTitle {
    font-size: 10px;
  }
  .emp-dashboard-body td {
    font-size: 10px;
  }
  .emp-dashboard-body .ur button {
    font-size: 11px;
  }
  .emp-dashboard-body .JobsBody .bottom .topbuts.resume a span {
    font-size: 11px;
  }
  .emp-dashboard-body .JobsBody .bottom .topbuts.resume a img {
    width: 14px;
  }
  .emp-dashboard-body .pis h2 {
    font-size: 16px;
  }
  .emp-dashboard-body .JobsBody .header button {
    font-size: 10px;
  }
  .import-schedule-report.heading-report-scheduler h1 {
    margin-top: 5px;
    font-size: 14px;
  }
  .product-modal-content
    .date-picker
    .date-range-log
    .rdrDateRangePickerWrapper
    .rdrDefinedRangesWrapper {
    width: 265px;
  }
  .logo-wrp {
    width: auto;
  }

  .employeereport-select {
    max-width: 120px;
  }

  .employeereport-select .one-control-select {
    padding-left: 0px;
  }

  .association-con .integration-document {
    padding-inline: 0.5rem;
  }
  .market-place-card {
    min-height: 300px;
  }

  .container-banner {
    padding-top: 40px;
  }
  .hero-banner-content {
    top: 40px;
  }

  .ess-portal .hero-banner-content .content-data h2 {
    font-size: 20px;
    margin: 0px;
    line-height: 28px;
  }
  .ess-portal .hero-banner-content .content-data p {
    font-size: 16px;
    line-height: 24px;
  }
  .ess-portal :is(.hero-banner-content .content-data button, h1) {
    font-size: 16px;
  }

  .ess-portal .footer_sec {
    font-size: 12px;
  }

  .header-right.my-flex p {
    font-size: 12px;
  }

  .logo img {
    max-width: 80px;
  }

  .market-place-card-outer .market-place-card .description p {
    font-size: 12px;
    word-wrap: break-word;
  }
  .marketplace-card-margin {
    margin-inline: 0px;
  }
  .slick-prev {
    left: 0px;
  }
  .slick-next {
    right: 0px;
  }
  .associationcustomreports tbody tr td:last-child {
    max-width: 120px;
  }
  .product-modal-content {
    padding-inline: 8px;
  }
  table.table.associationcustomreports .me-10-px {
    margin-right: 4px;
  }

  table.table.associationcustomreports :is(th, td) {
    padding-inline: 4px;
  }
}



:root {
    --primaryColor: #002e6c;
    --productcartbackcolor: #eeeeee;
    --productcolor: #000;
    --background: #f0f3f4;
    --bgcolor: #f0f3f4;
    --paracolor: #000;
    --paragraphfontsize: 16px;
    --proheadingfontsize: 20px;
    --prohendingfontstyle: '';
    --paragraphfontstyle: '';
    --dynamicwidth: '';
    --dynamicheight: '';
    --my-color: #002e6c;
    --color: #ffffff;
}

.dash-outer {
    /* margin: 40px; */
    margin-top: 25px;
}

.dash-inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-gap: 25px;
    gap: 25px;
}


.dash-card,
.servicerap-outer,
.othercontact-outer {
    padding: 14px;
    border-radius: 12px;
    width: calc(calc(100% - 50px) / 3);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
}


.dash-card h3 {
    margin: 0px 0px 10px 0px;
    color: var(--my-color);
    font-size: 24px;
}

.dash-card button {
    border: 1px solid #0dc76f;
    background-color: #0dc76f0a;
    padding: 8px 20px;
    color: #0dc76f;
    border-radius: 6px;
}

.servicerap-outer {
    color: var(--paracolor);
    display: flex;
    justify-content: space-between;
}

.servicerap-outer h3 {
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: var(--productcolor);
}

.servicerap-outer h4 {
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: var(--productcolor);
}

.servicerap-outer p {
    margin: 10px 0px;

}



.servicerap-inner {
    margin: 10px 0px;
}

.servicerap-inner label {
    font-size: 16px;
    font-weight: 600;
    width: 100px;
    display: inline-block;
    color: var(--productcolor);
}

.servicerap-inner span {
    color: var(--paracolor);
}

.othercontact-btn button span {
    font-size: 17px;
}



.othercontact-outer h3 {
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: var(--productcolor);
    /* margin-right: 34%; */

}

.othercontact-btn {
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
    grid-gap: 15px;
    gap: 15px;
    flex-direction: column;
    align-items: center;
}




.othercontact-btn button {
    padding: 15px 20px;
    padding-left: 30px;
    border-radius: 6px;
    width: 200px;
    border-radius: 40px;
    text-align: left;
}

.dash-outer .dash-card button:hover {
    background: #0dc76f;
    color: #fff;
}

.othercontact-outer p {
    margin: 10px 0px;
    color: var(--paracolor);
}

/* .othercontact-btn button:hover {
    background: var(--productcolor);
} */

.othercontact-btn button:active {
    background: var(--productcolor);
}

.cal_hole {
    background-color: #ffffff;
    border-radius: 93px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
    height: 20px;
    width: 20px;
}

.cal_hole_left {
    background-color: #ffffff;
    border-radius: 93px;
    height: 20px;
    width: 20px;
}

.date_tag {
    font-size: 60px !important;
    color: var(--productcolor);
}

.announcement-outer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    grid-gap: 20px;
    gap: 20px;
    /* flex-wrap: wrap; */
}

.announcement-left h3 {
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 35px;
    color: var(--productcolor);
}

.announcement-left-box {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    grid-gap: 15px;
    gap: 15px;
    border: 1px solid #d9d9d9;
    padding: 15px 20px;
    border-radius: 10px;
    margin-top: 20px;
    height: 430px;
}

.announcementleft-inner {
    display: flex;
    grid-gap: 50px;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 10px;
}

.announcementleft-inner input {
    border: 1px solid #d9d9d9;
    padding: 10px;
    border-radius: 8px;
}

.announcementleft-inner p {
    font-size: 18px;
    width: 100%;
    max-width: 870px;
    text-align: justify;
    margin-top: 0px;
    color: var(--paracolor);
}

.announcement-right,
.hot_topics {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    border: 1px solid #d9d9d9;
    padding: 15px 20px;
    border-radius: 10px;
    margin-top: 20px;

}

.announcement-right,
.cal-box {
    width: calc(calc(100% - 25px) / 3);
}

.Banner-right {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    grid-gap: 15px;
    gap: 15px;
    border: 1px solid #d9d9d9;
    padding: 15px 20px;
    border-radius: 10px;
    width: 100%;
    margin-top: 38px;
    height: 450px;
    margin-bottom: 30px;
}

.announcement-right button {
    padding: 15px 20px;
    border-radius: 6px;
}

.hot-fieldbtn {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
    margin-bottom: 15px;
}

.announcement-right a {
    color: var(--productcolor);
}

.announcement-calender {
    width: 25%;
    border-radius: 12px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
    height: 190px;
}

.announcementcalend-head {
    background-color: var(--my-color);
    color: #fff;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 14px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.projecttimeline-outer {
    margin-top: 20px;
    display: flex;
    grid-gap: 25px;
    gap: 25px;
    flex-wrap: wrap;
    margin-bottom: 25px;
}

.projecttimeline-left h3 {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 15px;
    color: var(--productcolor);
}

.projecttimeline-left p {
    font-size: 16px;
    color: #8b8b8b;
    width: 100%;
    max-width: 700px;
    text-align: justify;
}

.announcementcalend-head h4 {
    font-size: 25px;
}

.projecttimeline-table {
    width: 100%;
}

span.arrow-rightWrapper {
    padding-right: 10px;
    margin-left: 15px;
}

.arrow-color {
    color: var(--my-color) !important;
}

.projecttimeline-table table thead tr th {
    padding: 15px;
    color: #fff;
    background: var(--my-color);
}

.projecttimeline-table table tbody tr td {
    padding: 10px;
    text-align: center;
    font-size: 16px;
}

.hot_topics h3 {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 15px;
    color: var(--productcolor);
}

.hot_topics ul {
    padding-left: 0px;
    /*  border-radius: 8px;
    border: 1px solid #d9d9d9; */
    color: var(--paracolor);
}

.hot_topics ul li {
    list-style: none;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
}

.hot_topics ul li:last-child {
    border: none;
}

.hot_topics ul li p {
    padding: 10px 5px 10px 5px;
    margin: 11px 0px;
}

.announcementcalend-head h4 {
    margin: 8px 0;
}

span.arrow-rightWrapper svg {
    color: var(--productcolor)
}

h1.date_tag {
    padding: 15px 0 20px 0;
}

.hot_topics,
.announce_box {
    width: calc(calc(calc(100% - 25px) / 3)* 2);
}

.projecttimeline-left {
    width: 65%;
}

.projecttimeline-table {
    width: 100%;
}

.images_dash {
    width: 150px;
    padding: 5px;
}

.hot_field {
    height: 320px;
    overflow-y: auto;
}

.hot_fields {
    height: 320px;
    overflow-y: auto;
}

.hot_topic_heading {
    display: flex;
    justify-content: space-between;
    background-color: var(--my-color);
    /* margin: 17px 15px 0px 15px; */
    border-radius: 8px;
}

.anc-edit-btn {
    margin-top: 100%;
}

.anc-edit-btn button {
    height: 35px;
    width: 80px;
}

.hot_input_field {
    padding: 12px 10px 12px 50px !important;
    border-radius: 8px !important;
    width: 95%;
    height: 50px;
    /* margin-left: 15px !important; */
    margin-bottom: 15px;
}

.announcement-right_form {
    display: flex;
    justify-content: space-between;
    padding: 4px !important;
    cursor: auto !important;
}

.topic_close {
    margin: auto;
    font-size: 21px;
    margin-right: inherit;
    padding: 10px;
    color: #ffffff;
    cursor: pointer;
}

.calendar_btn {
    display: flex;
    /* width: 100%; */
    margin: 18px 1px 20px 1px;
    padding: 0px !important;
    border-radius: 10px;
}

.edit-icon-btn-calendar {
    color: var(--productcolor);
    width: 40px;
    font-size: 17px;
}

.cal_head {
    display: flex;
    justify-content: space-between;
    background: var(--my-color);
    border-radius: 8px;
    width: 32%;
}

.cal_head p {
    color: #ffffff;
    margin-left: 10px;
}

.salesforce_silider {
    height: 410px;
    width: 100%;
    margin-top: 12px;
    /* cursor: pointer; */
    border-radius: 10px 10px 10px 10px;
    margin-bottom: 30px;
}

.pen-banner {
    cursor: pointer;
}

.slider_li .slick-dots li {
    display: none;
}

.sales_text {
    overflow-y: auto;
    height: 260px;
}

.sales_text p {
    padding: 0px 15px 0px 0px
}

.head_dash {
    display: flex;
    justify-content: space-between;
    background: var(--my-color);
    border-radius: 8px;
}

.head_dash p {
    margin-left: 15px;
    color: #ffffff;
}


/* .btn-other {
    margin-left: -28px;
}

.btn-others {
    margin-left: -38px;
} */

.hottopic-btn {
    margin-left: 20px;
    height: 45px;
    width: 10%;
    border-radius: 5px 5px 5px 5px;
}

.cal-anc {
    margin-top: 20px;
    display: flex;
    grid-gap: 25px;
    grid-gap: 25px;
    gap: 25px;
    justify-content: space-between;
    margin-bottom: 25px;
    align-items: center;
}

.cal-head-anc {
    background-color: var(--my-color);
    margin-left: 15p;
    border-radius: 5px;
    color: #fff;
}

.save_btn {
    margin: 10px 10px 0px 10px;
}

/* .image_style {
    display: flex !important;
    justify-content: end !important;
    margin-top: -150px !important
} */
.sale_form {
    padding: 12px 10px 12px 50px !important;
    border-radius: 8px !important;
    width: 93%;
    height: 50px;
    margin-bottom: 15px;
}

.salestextarea {
    width: 100% !important;
    border: 1px solid rgb(218, 218, 218);
    border-radius: 3px;
}


.salesforce_modal {
    /* min-height: 460px; */
    width: 100%;
    background-color: #ffffff;
    padding: 12px;
}

.sales_input {
    width: 100%;
    margin: 10px 10px 10px 0px;
    border-radius: 5px 5px 5px 5px;
    height: 40px;
    font-size: 16px;
    display: block;
    padding: 5px;
    box-sizing: border-box;
    border: 1px solid rgb(218, 218, 218);
}

.salesforcemodal {
    width: 25%;
}

.salesforce_modal label {
    margin-left: 10px;
}

.saleforse-btn {
    margin-left: 10px;
}

.saleforse-btn button {
    height: 35px;
    width: 70px;
    font-size: 15px;
    margin-top: 10px;
}

.bannerimage-container {
    position: relative;
}


.editbanner-icon {
    position: absolute;
    z-index: 11;
    top: 24px;
    left: 12px;
    padding: 4px;
    margin: 0px;
}

.editbanner-msg {
    position: absolute;
    margin: 0px;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
}


.annouce-remember {
    flex-direction: column-reverse;

}

.annouce-remember .sync_history_mar {
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 20px;
    gap: 20px;
}

.annouce-remember .start_date_dash {
    margin-bottom: 0px;
    margin-right: auto;
}

.annouce-remember .anc-edit-btn {
    margin-top: 0px;
}


@media screen and (max-width: 1885px) {


    .hottopic-btn {
        width: 25%;
    }

    .announcement-right,
    .hot_topics,
    .cal-box,
    .announce_box {
        width: calc(calc(100% - 25px) / 2);
    }

    .projecttimeline-left {
        width: 100%;
    }





    .announcement-calender {
        width: 100%;
    }
}

@media screen and (max-width: 1366px) {
    .announcement-outer {
        flex-wrap: wrap;
    }

    .announcement-left {
        width: 66%;
    }

    .announcement-right {
        width: 32%;
    }

    .hottopic-btn {
        width: 25%;
    }

    .announcement-left {
        width: 66%;
    }

    .projecttimeline-left {
        width: 66%;
    }

    .projecttimeline-table {
        width: 100%;
    }


    .announce_box {
        width: calc(calc(100% - 25px) - 36%);
    }

    .cal-box {
        width: 36%;
    }

    .hot_topics ul {
        width: 100%;
        /* height: 385px;
        overflow: auto; */
    }

    .hot_topics {
        width: 32%;
    }

    .announcement-calender {
        width: 100%;
    }
}

@media screen and (max-width: 1024px) {

    .dash-card,
    .servicerap-outer {
        width: calc(calc(100% - 20px) / 2);
    }


    .othercontact-outer {
        width: 100%;
    }

    .announcement-left-box,
    .hot_topics,
    .announcement-right,
    .othercontact-outer,
    .dash-card,
    .servicerap-outer {
        padding: 12px;
    }

    .othercontact-outer h3,
    .othercontact-outer p {
        padding-left: 0px;
    }

    .othercontact-btn {
        flex-direction: row;
        margin-bottom: 11px;
        grid-gap: 10px;
        gap: 10px;
    }

    .othercontact-btn button {
        width: calc(calc(100% - 20px) / 3);
        padding: 12px;
        text-align: center;
    }


    .cal-anc {
        flex-direction: column;
    }

    .announce_box {
        width: 100%;
    }

    .cal-box {
        width: 100%;
    }

    .hottopic-btn {
        width: 25%;
    }

    .projecttimeline-table {
        width: 100%;
        overflow: auto;
    }

    .announcement-left {
        width: 100%;
    }

    .announcement-right {
        width: 100%;
        height: 290px;
    }

    .projecttimeline-left {
        width: 100%;
    }

    .hot_topics {
        width: 100%;
        margin-top: 15px;
    }

    .announcement-calender {
        width: 100%;
    }
}


@media screen and (max-width: 576px) {

    .dash-card,
    .servicerap-outer {
        width: 100%;
    }

    .othercontact-btn {
        flex-direction: column;
        margin-bottom: 11px;
        align-items: flex-start;
    }


    .othercontact-btn button {
        width: 100%;
        text-align: left;
        padding: 16px 24px;
    }

    .hottopic-btn {
        width: 32%;
    }

    .announce_box {
        height: 100%;
    }

    .announcementleft-inner {
        flex-direction: column;
    }

    .announcement-calender {
        width: 100%;
    }
}

/* .servicerap-outer-card{
  display: flex;
  justify-content: space-between;
} */
.projecttimeline-outer .hot_topics {
    margin-top: 20px;
}

.projecttimeline-outer .hot_topics ul {
    margin: 0px;
}

@media screen and (min-device-width: 1025px) and (max-device-width: 1269px) {


    .dash-inner {
        justify-content: start;
    }
}

@media screen and (min-device-width: 1025px) and (max-device-width: 1366px) {
    .hot_topics {
        width: calc(calc(calc(100% - 25px) / 3)* 2);
    }

    .announcement-right {
        width: calc(calc(100% - 25px) / 3);
    }
}
:root {
  --my-color: #002e6c;
  --color: #ffffff;
  --fontFamily:"Roboto", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  line-height: 1.3;
  font-size: 16px;
  background-color: white;
}

* {
  font-family: var(--fontFamily)
}

.content {
  max-width: 900px;
  margin: 0 auto;
}

*:focus {
  outline: none;
}

button,
input[type="submit"],
.button {
  cursor: pointer;
  /* background-color: #002e6c; */
  background-color: var(--my-color);
  color: white;
  padding: 5px;
  border-radius: 3px;
  border: none;
  font-size: 14px;
}

a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

h1 {
  margin: 0;
  padding: 15px 0 30px 0;
  line-height: 1;
  text-align: center;
  font-size: 30px;
}

h2 {
  margin: 0;
  line-height: 1;
  margin-bottom: 20px;
  text-align: center;
}

select {
  font-size: 16px;
  margin: 0 5px;
}

.bottomMargin {
  margin-bottom: 10px;
}

.rounded3 {
  border-radius: 3px;
}

.rounded5 {
  border-radius: 5px;
}

.grey {
  background-color: #eeeeee;
}

.green {
  background-color: #0cad4d !important;
  color: white;
}

.green-text {
  background-color: #0cad4d;
}

.blue {
  background-color: #002e6c;
}

.blue-text {
  color: #002e6c;
}

.red {
  background-color: red !important;
  color: white;
}

input[type="text"],
input[type="password"] {
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid rgb(218, 218, 218);
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 26px;
    padding: 10px 0 20px 0;
  }

  h2 {
    margin-bottom: 10px;
  }

  .disappearSmall {
    display: none;
  }
}
.error_top{
  margin-top: 10px;
  margin-right: 280px 
}
