.py-2 {
  padding: 1rem 0;
}
.px-1 {
  padding: 0 0.5rem;
}
.mx-1 {
  margin: 0 0.5rem;
}
.schedule-report select {
  padding: 10px 15px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}
.schedule-report .col-2 {
  width: 15%;
}
.schedule-report .col-6 {
  width: 50%;
}
.schedule-report .col-5 {
  width: 42%;
}
.schedule-report .col-12 {
  width: 100%;
}
.schedule-report .col-10 {
  width: 85%;
}
.schedule-report .d-flex {
  align-items: center;
  justify-content: unset;
}

.schedule-report {
  padding-top: 20px;
}

.schedule-report .daily {
  border: 1px solid #ced4da;
  padding: 20px;
  border-radius: 6px;
}

.schedule-report .daily input {
  margin: 0 10px;
  padding: 10px 15px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

/* 06-09-2023 css */

.schedule-report .formInput {
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid rgb(218, 218, 218);
  width: 100%;
  padding: 12px 10px !important;
}

.schedule-report .formInput::-webkit-outer-spin-button,
.schedule-report .formInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.schedule-report .formselect {
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid rgb(218, 218, 218);
  margin: 0;
  width: 100%;
  padding: 12px 10px !important;
}
.Custmrow {
  display: flex;
  flex-wrap: wrap;
}
.custmmb-2 {
  margin-bottom: 0.8rem;
}
.custmEmpty {
  text-align: center;
  padding: 6px 0;
  margin: 15px 0;
}
.custmCol-3 {
  flex: 0 0 auto;
  width: 22.54%;
  padding: 0 12px;
}
.custmCol-12 {
  flex: 0 0 auto;
  width: 97.65%;
  padding: 0 12px;
}
.custmCol-2 {
  flex: 0 0 auto;
  width: 12.9111%;
  padding: 0 12px;
}
.CustmBtn {
  padding: 12px 30px;
  /* height: 100%; */
}
.CustmWidth {
  width: 45%;
  display: flex;
  align-items: center;
}
.custmFullWidth {
  width: 100%;
}
.custmSpaceBetwen {
  justify-content: space-between !important;
}
.custmPaddingXY {
  padding: 10px 15px;
}

.table-company-sftp {
  justify-content: flex-end;
  display: flex;
}
.time-management-sftp {
  margin-left: auto;
}
.scheduler-btn {
  display: flex;
  justify-content: flex-end;
}

.scheduler-btn button {
  padding: 12px 25px;
}

.scheduler-autocomplete {
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
}

.scheduler-autocomplete button {
  padding: 0 25px;
}

.scheduler-autocomplete > div {
  display: block !important;
  width: 100%;
}
.scheduler-autocomplete > div > input {
  width: 100%;
  font-size: 16px;
  display: block;
  border-radius: 3px;
  box-sizing: border-box;
  border: 1px solid rgb(218, 218, 218);
  padding: 12px 10px !important;
}
.sftp-terminal .react-terminal-window-buttons {
  display: none;
}
.sftp-terminal .react-terminal-wrapper {
  padding: 15px;
}

.sftp-terminal .react-terminal-input:before {
  content: ">";
}
.terminal-green {
  text-decoration: underline;
  color: green;
}

.connect-sftp .fa-2xl {
  font-size: 12rem;
}

.date-picker {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
}
.mr-1 {
  margin-right: 0.5rem;
}

/* logs table start */
.logs-table td {
  font-family: monospace;
  /* padding: 10px 5px; */
}
.logs-table .status {
  background: #ddd;
  padding: 5px 14px;
  border-radius: 10px;
}

.logs-table .status.info {
  color: yellow;
  background-color: black;
}

.logs-table .status.error {
  color: red;
}

.logs-table .status.success {
  color: #fff;
  background-color: green;
}
.logs-table {
  width: 100%;
  overflow-y: auto;
}

/* logs table end */
