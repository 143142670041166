:root {
  --background:#f0f3f4;
}

.modalOuter {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0,0,0,0.8);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalBody {
  /* background-color: white; */
  background-color: var(--background);
  padding: 20px;
  border-radius: 10px;
  width: 90vw;
  max-width: 750px;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
  box-sizing: border-box;
}

.modalOuter .close {
  position: fixed;
  right: 5px;
  top: 5px;
  width: 25px;
  height: 25px;
  z-index: 11;
  cursor: pointer;
}
