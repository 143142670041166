* {
  box-sizing: border-box;
}

:root {
  --my-color: #002e6c;
  --color: #ffffff;
}

:root {
  --primaryColor: #002e6c;
  --productcartbackcolor: #eeeeee;
  --productcolor: #000;
  --background: #f0f3f4;
  --bgcolor: #f0f3f4;
  --paracolor: #000;
  --paragraphfontsize: 16px;
  --proheadingfontsize: 20px;
  --prohendingfontstyle: '';
  --paragraphfontstyle: '';
  --dynamicwidth: '';
  --dynamicheight: ''
}

.lalit {
  width: var(--dynamicwidth);
  height: var(--dynamicheight);
}

/* utility class start */
.cursor-pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

.my-flex {
  display: flex;
  flex-wrap: wrap;
}

.my-d-flex {
  display: flex;
}

.my-justify-content-between {
  justify-content: space-between;
}

.dynamic_color {
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.my-justify-content-center {
  justify-content: center;
}

.my-justify-content-end {
  justify-content: flex-end;
}

.my-align-item-center {
  align-items: center;
}

.my-align-item-end {
  align-items: flex-end;
}

.my-flex-grow-1 {
  flex: 1;
}

.m-0 {
  margin: 0 !important;
}

.mb-2 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 2rem !important;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mx-1 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mx-2 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-4 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mt-0 {
  margin: 0;
}

.mt-1 {
  margin-top: 0.5rem !important;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ms-1 {
  margin-left: 0.5rem;
}

.ms-2 {
  margin-left: 1rem;
}

.ms-4 {
  margin-left: 2rem;
}

.ms-10-px {
  margin-left: 10px;
}

.me-10-px {
  margin-right: 10px;
}

.me-1 {
  margin-right: 0.5rem;
}

.me-2 {
  margin-right: 1rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mt-8 {
  margin-top: 4rem;
}

.m-auto {
  margin: auto;
}

.p-0 {
  padding: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.p-2 {
  padding: 1rem;
}

.p-4 {
  padding: 2rem;
}

.pt-4 {
  padding-top: 2rem !important;
}

.pe-2 {
  padding-right: 1rem !important;
}

.pe-1 {
  padding-right: 0.5rem !important;
}

.pt-6 {
  padding-top: 3rem !important;
}

.px-4 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.one-col-1 {
  width: 8.3333%;
}

.one-col-2 {
  width: 16.6666%;
}

.one-col-3 {
  width: 25%;
}

.one-col-4 {
  width: 33.33333%;
}

.one-col-5 {
  width: 41.66666%;
}

.one-col-6 {
  width: 50%;
}

.one-col-7 {
  width: 58.3333%;
}

.one-col-8 {
  width: 66.66666%;
}

.one-col-9 {
  width: 75%;
}

.one-col-10 {
  width: 82.33333%;
}

.one-col-11 {
  width: 91.66666%;
}

.one-col-12 {
  width: 100%;
}

.one-col-sm-2 {
  width: 16.6666%;
}

.one-col-sm-10 {
  width: 82.33333%;
}

.text-left {
  text-align: left;
}

.full-width {
  width: 100% !important;
}

.color-fff {
  color: #fff;
}

.my-btn {
  padding: 10px 10px;
}

.overflow-hidden {
  overflow: hidden;
}

.z-index-99 {
  /* z-index: 99; */
  z-index: 10;
}

/* utility class end */

/* tooltip css start */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: #002e6c !important;
}

/* tooltip css end */

/* drop css start */
.dash-menu .drop-down {
  list-style-type: none;
  padding: 0;
  margin-left: 15px;
}

/* drop css end */

main {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
}

.loadinGif {
  margin: 0 auto;
  display: block;
  width: 100%;
  max-width: 130px;
  color: 'red'
}

.textInputCont {
  display: block;
  width: 100%;
  position: relative;
  line-height: 1;
}

.textInputCont input {
  width: 100%;
  padding: 5px 3px;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  border-color: #e4e4e4;
  padding-right: 22px;
  font-size: 16px;
}

.textInputCont input.nox {
  padding-right: 3px;
}

.textInputCont .xbutton {
  position: absolute;
  right: 3px;
  height: 70%;
  top: 15%;
  cursor: pointer;
  width: auto;
}

/* 29-09-2022 */

.d-flexcstm {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
}

.cstmbtn1 {
  background: #4e6ab7;
  padding: 10px 20px;
}

.tablecstm thead tr th {
  text-align: left;
  white-space: nowrap;
}

.formcontrol {
  width: 100%;
}

.associatecompany {
  width: 100%;
  display: flex;
  align-items: center;
}

.autocomplete {
  width: calc(100% - 146px);
}

.autocomplete input {
  width: 100%;
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid rgb(218, 218, 218);
}

.primarycheck {
  margin: 0 0 0 20px;
  display: flex;
  color: #3894ff;
  align-items: center;
}

.primarycheck input {
  width: 20px;
  height: 20px;
}

.addcompanybtn {
  background: #4e6ab7;
  padding: 3.5px 15px;
  margin-left: 10px;
  font-size: 20px;
}

.tablecustompop {
  width: 100%;
}

.tablecustompop thead tr th {
  text-align: left;
  white-space: nowrap;
}

.tablecustompop th,
.tablecustompop td {
  border: none;
  border-top: 1px solid rgb(218, 218, 218);
}

.tablecustompop tbody tr:nth-child(even) {
  background: transparent;
}

.badgecolor {
  background-color: #27b148;
  color: #fff;
  font-size: 13px;
  display: inline-block;
  padding: 3px 14px 2px;
  border-radius: 5px;
}

.modal-contentcstm {
  width: 600px;
  max-width: 90%;
}

.submitbtncstm {
  background: #4e6ab7 !important;
  border: 1px solid #4e6ab7 !important;
  padding: 8px 20px !important;
  margin-left: 10px !important;
  font-size: 15px !important;
  margin: 0 !important;
}

.cancelbtncstm {
  background-color: #eaeaea !important;
  border: 1px solid #505559 !important;
  color: #505559 !important;
  margin-left: 10px !important;
}

.mbcstm {
  margin-bottom: 30px;
}

.tablecstm tbody tr td {
  white-space: nowrap;
}

.cstmdlt {
  color: #cc1313;
  background: none;
  font-size: 16px !important;
}

@media only screen and (max-width: 768px) {
  main {
    margin-top: 0;
  }
}

.connect-sftp h1 {
  padding-bottom: 0;
}

.connect-sftp p {
  text-align: center;
}

.color-green {
  color: #27b148;
}

/* market place css start */
.market-place h1 {
  text-align: left;
}

.market-place .slick-center {
  transform: scale(1.1);
}

.market-place .slick-prev,
.slick-next {
  z-index: 9;
}

.market-logo {
  max-width: 200px;
  height: 75px;
  padding-bottom: 0.5rem;
  margin: auto;
}

.market-logo img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.market-place .slick-track {
  padding: 40px 0;
}

.market-place-card {
  padding: 0.5rem 1.5rem;
  margin: 1rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 50px;
  border-bottom: 4px solid #3894ff;
  cursor: pointer;
  transition: ease-in 0.3s;
  /* background-color: #fff; */
  background-color: var(--bgcolor);
  min-height: 255px;
  color: var(--paracolor);
  font-size: var(--paragraphfontsize);
  font-style: var(--paragraphfontstyle);
}

/* .slick-active:not(.slick-center) .market-place-card:hover {
  transform: scale(1.1);
} */

.market-place-card h2 {
  text-align: left;
  color: var(--productcolor);
  font-size: var(--proheadingfontsize);
  font-style: var(--prohendingfontstyle);
}

.market-place .place-row {
  display: flex;
  flex-wrap: wrap;
}

/* market place css end */

/* date range start */
.log-management .date-picker {
  position: relative;
  justify-content: space-between;
}

.date-range-log {
  position: absolute;
  top: 51px;
  /* left: 0; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 10px;
  z-index: 9;
  background-color: #fff;
}

.date-picker span {
  color: #000;
}

.date-picker .log-btn {
  display: flex;
  justify-content: flex-end;
}

.logs-table tr:nth-child(even) {
  background-color: unset !important;
}

/* date range end */
.csv-export-btn {
  cursor: pointer;
  background-color: var(--my-color);
  color: white;
  padding: 7px;
  border-radius: 3px;
  border: none;
  font-size: 14px;
  margin: 0 10px;
}

.queue-id {
  color: #002e6c;
}

.queue-id:hover {
  text-decoration: underline;
  cursor: pointer;
}

.schedule-report .prod-settings {
  padding: 0 12px;
}

.quick-connect-reset-btn {
  padding: 0 12px;
}

.quick-connect-reset-btn button {
  margin-right: 12px;
}

.timing-account input {
  min-width: 100px;
  font-size: 16px;
  border-radius: 3px;
  box-sizing: border-box;
  border: 1px solid rgb(218, 218, 218);
}

/* market place css start */
.market-place .slick-prev,
.market-place .slick-next {
  /* background-color: red !important; */
  background-color: var(--my-color) !important;
  height: 35px !important;
  width: 35px !important;
  text-align: center;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  background-color: #002e6c;
}

.market-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* market place css end */
/* tool confirmation css start */
.confirm-body {
  height: 100vh;
  display: flex;
}

.confirm-container {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 600px;
  margin: auto;
  padding: 20px;
  text-align: center;
}

.confirm-container .badge-check-icon {
  width: 30%;
  margin: auto;
}

.confirm-container .badge-check-icon img {
  width: 100%;
}

/* tool confirmation css end */

/* product modal css start */
.product-modal {
  background: #000000a3;
  z-index: 33;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.product-modal-inner {
  padding: 45px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-modalBody {
  /* background-color: white; */
  background-color: var(--background);
  border-radius: 10px;
  width: 92vw;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}

.product-close-modal-btn svg {
  cursor: pointer;
  font-size: 20px;
  transition: 0.3s;
  width: 25px;
  height: 25px;
  padding: 8px;
  border-radius: 50%;
  font-weight: 400;
}

.product-close-modal-btn svg:hover {
  background-color: #091e420f;
}

.product-modal-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  background-color: var(--my-color);
  color: #fff;
}

.product-modal-head h2 {
  margin-bottom: 0px;
}

.product-modal-content {
  padding: 10px 20px;
  height: 750px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.custom_product-modal {
  background: #000000a3;
  z-index: 33;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.custom_product-modalBody {
  background-color: var(--background);
  border-radius: 10px;
  width: 50vw;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  height: 700px;
}

.sync_history {
  margin: 10px 0px 10px 80%
}

.date_cul {
  margin-top: 100px;
  left: 250px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.Integration-outer {
  width: 70%;
  position: relative;
  transition: 0.6s;
  overflow: auto;
  max-height: 680px;
}

.product-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
  /* cursor: pointer; */
}

.product-modal-header span {
  cursor: pointer;
}

.setting_heading {
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.product-modal-header h3 {
  font-size: 24px;
  margin-bottom: 0px;
  margin-top: 0;
}

.product-modal-header span svg {
  cursor: pointer;
  font-size: 25px;
}

.product-card-content-outer {
  display: flex;
}

.product-card-outer-out {
  padding: 10px;
  width: 25%;
}

.integration-active .product-card-outer-out {
  width: 20%;
}

.product-card-outer {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  background: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.product-card-outer .input-h3 {
  border: 1px solid #fff !important;
  margin-bottom: 0px;
  margin-top: 8px;
  text-align: center;
  font-size: var(--proheadingfontsize);
  font-weight: 600;
  cursor: context-menu;
  width: 100%;
  position: relative;
  color: var(--productcolor) !important;
  font-style: var(--prohendingfontstyle);
}

.product-card-outer .input-h3.active:focus {
  border: 1px solid #002e6c !important;
}

.product-card-outer .input-h3:disabled {
  color: #000;
  background-color: #fff;
}

.product-card-outer .input-p {
  border: 1px solid #fff !important;
  text-align: center;
  margin: 5px 0px;
  font-size: var(--paragraphfontsize);
  height: calc(1em * 1.5 * 5 + 3px + 3px + 1px + 1px);
  padding: 5px 5px;
  resize: none;
  cursor: context-menu;
  width: 100%;
  position: relative;
  color: var(--paracolor) !important;
  font-style: var(--paragraphfontstyle);
}

.product-card-outer .input-p.active:focus {
  border: 1px solid #002e6c !important;
}

.product-card-outer .input-p:disabled {
  background-color: #fff;
  color: #000;
}

.product-setting-section {
  width: 30%;
  transition: 0.6s;
  padding-left: 15px;
  position: relative;
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.product-card-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-right: 10px;
}

.product-card-inner img {
  /* width: 100px;
  padding: 5px; */
  object-fit: contain;
  width: 100%;
}

.product-card-inner {
  display: flex;
  justify-content: center;
  background-color: var(--productcartbackcolor);
  /* background-color: #eeeeee; */
  border-radius: 10px 10px 0px 0px;
  height: 100px;
  width: 100%;
}

.product-card-inner1 {
  display: flex;
  flex-direction: column;
  height: 145px;
  overflow: hidden;
}

.product-card-inner1 h3 {
  margin-bottom: 0px;
  margin-top: 8px;
  text-align: center;
  font-size: 20px;
}

.product-card-inner1 p {
  text-align: center;
  margin: 5px 0px;
}

.product-card-inner2 {
  background: #ff0000;
  padding: 15px;
  text-align: center;
}

.product-card-inner2 h4 {
  margin: 0px;
  color: #fff;
  letter-spacing: 1px;
  font-size: 14px;
}

.product-setting-inner {
  padding: 10px 0;
  padding-left: 10px;
  border-left: solid 10px var(--my-color);
  margin-bottom: 20px;
}

.unsave .product-setting-inner {
  border-left: solid 10px #ff0000;
}

.product-setting-inner label {
  font-weight: 600;
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.product-setting-inner.email-notification p {
  font-size: 14px;
  margin: 0;
  margin-bottom: 1rem;
  color: var(--paracolor);
}

.product-setting-inner p {
  color: var(--paracolor);
  /* font-size: var(--paragraphfontsize); */
  font-style: var(--paragraphfontstyle);
}

.product-setting-inner.email-notification .activeEmps button {
  padding: 8px 15px;
}

.product-setting-inner.email-notification .activeEmps {
  display: flex;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
}

.product-setting-inner.email-notification .employees select {
  width: 100%;
}

.product-setting-inner.email-notification .freeinput .form-input {
  padding: 10px;
  border: 1px solid #d9d9d9;
  width: 90%;
  border-radius: 5px;
  /* margin: 11px; */
}

.product-setting-inner select,
.product-setting-inner1 select {
  padding: 10px;
  margin: 0px;
  margin-top: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  width: 80%;
}

.product-setting-inner button,
.product-setting-inner1 button {
  padding: 12px 20px;
}

.product-setting-inner .n-days-in input {
  padding: 12px 20px;
  width: 80%;
  border: 1px solid #d9d9d9;
}

.product-setting-section h3 {
  margin-bottom: 2px;
}

.product-setting-inner1 label {
  font-weight: 600;
  font-size: 20px;
}

.product-setting-inner1 .Access-type label {
  font-weight: 400;
  font-size: 16px;
}

.product-setting-inner1 {
  margin-top: 15px;
  overflow: auto;
  max-height: 380px;
}

.access-control {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px;
}

.access-control h3 {
  margin: 0px;
  font-size: 16px;
}

.access-control button {
  padding: 5px;
}

.access-control button.inactive {
  background-color: gray;
}

.access-control.inactive {
  background-color: #ff6363;
}

.product-setting-inner2 button {
  padding: 10px;
  margin-top: 15px;
}

.system-admin-outer {
  padding-left: 25px;
}

.system-admin-inner label {
  font-size: 16px;
  font-weight: 600;
}

.system-admin-outer h3 {
  font-size: 24px;
  /* margin-bottom: 15px; */
}

.system-admin-inner select {
  padding: 10px;
  width: 60%;
  margin: 0px;
  margin-top: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

.system-admin-inner button {
  padding: 12px 0;
  margin-left: 10px;
  width: 142px;
}

.product-deactivate {
  margin-top: 25px;
}

.prod-card {
  /* height: 100% !important;
  padding: 15px !important;
  margin-bottom: 25px !important; */
  position: relative;
}

.dash-product-card {
  position: relative;
}

/* product modal css end */
.Integration-outer.integration-active {
  width: 100%;
}

.product-left-content.integration-active {
  width: 100%;
  transition: 0.6s;
}

.product-setting-section.setting-hide {
  visibility: hidden;
  position: absolute;
  right: 0%;
  transform: translate(100%, 0);
}

.product-right-content.setting-hide {
  visibility: hidden;
  position: absolute;
  right: 0%;
  transform: translate(100%, 0);
}

/* product integration modal css end */
.heading {
  display: flex;
  justify-content: space-between;
}

.provision-modal {
  padding: 20px;
}

.left-heading h2 {
  text-align: left;
  margin-bottom: 10px;
  color: var(--productcolor);
  font-size: var(--proheadingfontsize);
  font-style: var(--prohendingfontstyle);
}

.side-img {
  width: 25%;
  /* width: 150px; */
  /* display: flex; */
  align-items: center;
}

.side-img img {
  width: 100%;
  float: right;
}

.left-heading h4 {
  margin: 10px 0px;
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.left-heading p {
  color: red;
  font-weight: 600;
}

.hint-product {
  font-size: 12px;
  align-self: flex-end;
  font-weight: 400;
}

.provision-modal {
  padding: 20px;
  height: 750px;
  position: relative;
}

.product_card_content {
  display: flex;
}

.product-left-content {
  width: 70%;
  transition: 0.6s;
  overflow: auto;
}

.product-right-content {
  width: 30%;
  padding-left: 16px;
  transition: 0.6s;
}

.suite-btn {
  padding: 12px 20px;
}

.product-table .header-action input {
  padding: 10px;
  border: 1px solid rgb(218, 218, 218);
}

.product-table .header-action select {
  border: 1px solid rgb(218, 218, 218);
}

.product-table table thead tr {
  background-color: var(--my-color);
  color: #fff;
}

/* .product-table .statusz.FAIL {
  background-color: red;
  color: #fff;
} */

/* .my-align-item-center {
  font-size: 25px;
  cursor: pointer;
} */

.ess .side-img img {
  width: 100%;
}

.ess .side-img {
  width: 25%;
}

.container-banner {
  position: relative;
  padding-top: 86px;
}

/* .hero-banner-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
.hero-banner-img {
  background-image: url(../assets/images/banner-1c.png);
  height: 600px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 23%;
}

/*start 27/11/2023 */

.hero-banner-content {
  position: absolute;
  top: 86px;
  left: 0%;
  width: 100%;
  height: 600px;
  background-color: rgba(0, 0, 0, 0.35);
}

/* end 27/11/2023 */

/* .hero-banner-img {
  height: 800px;
} */

.hero-banner-content .content-data {
  position: absolute;
  top: 30%;
  left: 4%;
  width: 430px;
  padding: 20px;
}

.hero-banner-content .content-data button {
  padding: 20px;
  font-size: 20px;
  border-radius: 5px;
}

.upper-img {
  position: absolute;
  top: 50%;
  left: 50%;
}

.hero-banner-content .content-data h2 {
  text-align: left;
  /* color: #002e6c; */
  color: #fff;
  font-weight: 300;
  line-height: 2.375rem;
  margin-bottom: 0;
  font-size: 1.75rem;
  margin: 5px 0px;
}

.hero-banner-content .content-data p {
  /* color: #002e6c; */
  color: #fff;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.8rem;
  margin-bottom: 1.125rem;
  margin-top: 0.625rem;
  max-width: 26.25rem;
}

.dashboard-integration-doc {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(100%, 0px);
  height: 100%;
  width: 70%;
  background-color: #fff;
  transition: 0.6s;
}

.dashboard-integration-doc.open::after {
  content: "";
  width: 43%;
  height: 100%;
  background: #00000057;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-100%, 0);
}

.dashboard-integration-doc.association {
  width: 40%;
}

.dashboard-integration-doc.open.association::after {
  width: 150%;
}

.dashboard-integration-doc.open {
  transform: translate(0, 0px);
}

.integration-document {
  padding: 0 2rem;
  overflow: auto;
  height: 700px;
}

.integration-configuration {
  padding: 0 2rem;
  overflow: auto;
  height: 95%;
  padding-bottom: 25px;
}

.settings .saveAlert .box {
  width: 20px;
  height: 20px;
  background-color: #ff221e;
  display: inline-block;
}

.settings .saveAlert {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.edit-btn-container {
  position: absolute;
  top: 0;
  left: 0;
}

.prod-card .edit-btn-container {
  display: flex;
  justify-content: flex-end;
}

.edit-icon-btn {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  color: var(--productcolor);
}

footer.footer_sec {
  padding: 20px 0px;
  background: #002e6c;
  font-weight: 500;
  color: #fff;
  margin-top: 50px;
}

.text {
  color: #fff;
}

/************ new import scheduler css start *************/

.one-control-input,
.one-control-select {
  padding: 0 8px;
}

.import-schedule-report .one-control-input input {
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #dadada;
  width: 100%;
  padding: 12px 10px !important;
}

.import-schedule-report .one-control-input input[type="radio"] {
  display: unset;
  width: unset;
}

.import-schedule-report .one-control-input>label {
  padding: 12px 10px;
  display: inline-block;
}

.import-schedule-report .one-control-select select {
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #dadada;
  margin: 0;
  width: 100%;
  padding: 12px 10px !important;
}

.import-schedule-report .one-control-input-date input {
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #dadada;
  margin: 0;
  width: 100%;
  padding: 12px 10px !important;
}

button.one-control-btn {
  padding: 14px 15px;
}

.imp-container-report {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 7px;
}

.scheduler-type-container {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  margin: 0 8px;
}

/************ new import scheduler css end  **************/
.product-modal-content .date-picker {
  position: relative;
}

.product-modal-content .date-picker .date-range-log {
  position: absolute;
  top: 53px;
  right: 73px;
}

/************ new Multiple EIN css Start  **************/
.association-form .one-control-input {
  padding: 0;
  margin-bottom: 1rem;
}

.association-form .one-control-input input {
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #dadada;
  width: 100%;
  padding: 12px 10px !important;
}

.association-form .one-control-button button {
  padding: 14px 16px !important;
}

.association-form th,
.association-form td {
  border: none;
  border-top: 1px solid rgb(218, 218, 218);
}

.mul-association-btn button {
  padding: 12px 30px !important;
}

.auto-com {
  position: relative;
}

.association-con {
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

.dashboard-integration-doc.association .custom-report-multiple {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  z-index: 999;
  transition: 0.5s;
}

.dashboard-integration-doc.association .custom-report-multiple.active {
  left: 0;
}

.one-select-option select {
  padding: 10px;
  width: 100%;
  margin: 0px;
  margin-top: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

.custom-report-multiple .one-control-input {
  padding: 0;
}

.custom-report-multiple .one-control-input input {
  padding: 10px;
  border: 1px solid #d9d9d9;
  width: 100%;
  border-radius: 5px;
}

.custom-report-multiple .one-control-button1 button {
  padding: 11px;
  border: 1px solid #d9d9d9;
  width: 100%;
  border-radius: 5px;
}

/************ new Multiple EIN css end    **************/

/* 27/11/2023 */

.product-right-content.setting-show,
.product-left-content {
  height: 700px;
  overflow-x: hidden;
  overflow-y: auto;
}

/* system-admin-tool-form css start */
.system-admin-tool-form .one-control-input input {
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #dadada;
  width: 100%;
  padding: 12px 10px !important;
}

/* system-admin-tool-form css  end */

/* .emp-body {
  height: auto !important;
} */

/* .Integration-divider > div:nth-child(1) {
  width: 60%;
}

.Integration-divider > div:nth-child(2) {
  width: 40%;
} */

.userprovision-table.table thead tr th:nth-child(5) {
  width: 220px;
}

.userprovision-table.table thead tr :is(th:nth-child(2),
  th:nth-child(3),
  th:nth-child(4),
  th:nth-child(6),
  th:nth-child(1)) {
  width: 80px;
}

.userprovision-table.table thead tr :is(th:nth-child(1)) {
  width: 120px;
}

.userprovision-table.table thead tr :is(th:nth-child(7)) {
  min-width: 150px;
}

.product-table table tbody tr :is(td:nth-child(7)) {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-table table tbody tr td {
  font-size: 13px;
  padding: 8px;
}

.product-table table tbody tr td:nth-child(5) {
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.middleproductcard-section {
  width: 75%;
}

.middleproductcard-section-overlay {
  width: 25%;
}

.middletop-section {
  z-index: 12;
  position: relative;
}

/* slick slider dot  */
.slick-dots li {
  margin: 0px !important;
}

.header-right img {
  height: 40px;
}

.card-bar-one input.prod-heading {
  width: 100%;
  position: relative;
  color: var(--productcolor);
}

.card-bar-one input.prod-heading:disabled {
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.marketplace-card-margin {
  margin-inline: 32px;
}

header {
  z-index: 16 !important;
}

.systemadmininner-select select {
  width: 100%;
  margin-bottom: 1rem;
}

.product-modal-content.productmodal-padding .access-control button {
  margin-top: 8px;
}

.table-responsive {
  overflow-x: auto;
  margin-right: 10px;
}

.product-modal.productmodal-full .product-right-content .product-setting-inner button {
  margin-top: 8px;
}

.system-admin-tool-form .one-control-input input {
  max-width: 270px;
}

.system-admin-tool-form .one-control-input {
  padding: 0px;
}

.systemadmin-btn button {
  width: 100%;
  max-width: 270px;
}

:is(.associations-table, .import-table, .associationcustomreports) tbody tr td:last-child {
  min-width: 150px;
}

.associationcustomreports tbody tr td:last-child {
  min-width: 170px;
}

.associations-table tbody tr td:nth-child(7) {
  text-align: center;
}

.associationcustomreports.table,
.associationcompany-table.table {
  min-width: 500px;
  width: 100%;
}

.association-list {
  width: 80%;
  padding-top: 20px;
  padding-bottom: 50px;
}

.product-modal-content.productmodalcontent-sm .one-col-sm-10.d-flex {
  justify-content: flex-end;
}

.product-modal-content.productmodalcontent-sm .date-picker {
  justify-content: flex-start;
}

.employee-report .one-control-select select {
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #dadada;
  margin: 0;
  width: 100%;
  padding: 12px 10px !important;
}

.ess-portal .prod-card {
  min-height: 180px;
  display: flex;
  align-items: center;
}

.ess-portal .marketplacecard-container {
  margin-inline: 2rem;
}

.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.employeereport-select {
  max-width: 150px;
  width: 100%;
}

.my-association-list {
  width: 100%;
}

.modalOuter {
  z-index: 999;
}

.Indeed.JobBoard .one-select-option select {
  margin-top: 0;
}

.emp-dashboard-body .career.JobsBody.JobBoard .img {
  height: 100px;
}

.emp-dashboard-body .JobsBody .head {
  display: flex;
  justify-content: space-between;
}

.card-bar-one textarea.prod-description:disabled {
  color: var(--paracolor);
}

.prod-description-extra-dev {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* login new design css start */
.login-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../assets/images/login-bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.login-container .login-box {
  width: 90%;
  max-width: 500px;
  padding: 2rem;
  background-color: #0a75c2;
  color: #fff;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.login-container .login-box .one-input input {
  width: 100%;
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

.login-container .login-box .header {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.login-container .login-box .login-logo {
  width: 60%;
  margin: auto;
}

.login-container .login-box .login-logo img {
  width: 100%;
}

.login-btn {
  width: 100%;
  padding: 10px;
  transition: 0.3s;
  font-size: 18px;
  background-color: #002e6c;
}

.login-btn:hover {
  background-color: #003f95;
}

/* login new design css end   */

/* company list data table start */
.company_system_table a,
.company_system_table button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  text-decoration: none;
}

.table_head {
  margin-top: -56px;
}

.date_tab {
  padding: 0px 0px 5px 0px;
  display: flex;
  justify-content: flex-end;
}

.log-btn_tab {
  display: flex;
  justify-content: flex-end;
  padding: 0px
}

.company_system_table select:focus-visible {
  outline: none !important;
}

.company_system_table input[type="checkbox"] {
  width: 18px;
  height: 18px;
}

.company_system_table .system_table {
  padding: 1rem;
}

.company_system_table .tbl_container {
  overflow-x: auto;
  background-color: #fff;
  height: 750px;
  overflow-y: auto;
}

.tbl_header-bom {
  margin-bottom: 15px;
}

.company_system_table .tbl_container table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

.company_system_table .tbl_container th,
td {
  text-align: left;
  padding: 5px;
}

.company_system_table .tbl_containers th,
td {
  text-align: left;
  padding: 5px;
}

.company_system_table .tbl_container table tbody tr:nth-child(even) {
  background: none;
}

.company_system_table .tbl_container table tbody tr:nth-child(odd) {
  background: none;
}

.company_system_table .tbl_container tbody tr,
.company_system_table .tbl_container tbody tr td {
  border: 1px solid #ddd;
}

.company_system_table .tbl_container thead tr:nth-child(1) th {
  border: 1px solid #fff;
}

.company_system_table .tbl_container thead tr:nth-child(1) {
  background: var(--my-color);
  color: #fff;
}

.company_system_table .tbl_container thead tr:nth-child(2) {
  background: #f2f0f2;
}

.company_system_table .tbl_container tbody input[type="checkbox"] {
  border: 1px solid var(--my-color);
}

.company_system_table .tbl_container thead tr select {
  width: 150px;
  border: 1px solid #bdbaba;
  padding: 4px;
}

.company_system_table .tbl_container {
  background-color: #fff;
}

.company_system_table .tbl_containers thead tr:nth-child(1) {
  background: var(--my-color);
  color: #fff;
}

.company_system_table .refresh_data {
  color: #002e6c;
}

.company_system_table.company-list .tbl_container thead tr:nth-child(1) th:nth-child(1) {
  background-color: #000;
  width: 3%;
}

.company_system_table .tbl_container tbody tr:hover {
  background-color: #d9d9d9 !important;
}

.company_system_table .tbl_container tbody tr td:nth-child(6) {
  color: #002e6c;
  border: none;
  display: flex;
  align-items: center;
  justify-self: center;
}

.company_system_table .flex-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.company_system_table .filter_down {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0company_system_table 0.5rem 0;
}

.company_system_table .page_upper {
  justify-content: space-between !important;
  position: sticky !important;
}

.company_system_table .row_filter {
  margin-right: 1rem;
}

.company_system_table .arrow_input {
  height: 100%;
  margin: 0px 0company_system_table 0.5rem;
  width: 100px;
}

.company_system_table .fresh_img {
  margin: 0 0company_system_table 0.3rem;
}

.company_system_table .arrow_bx {
  height: 20px;
  width: 20px;
  border: 1px solid #bdbaba;
  margin: 0px 0company_system_table 0.8rem;
  flex-wrap: nowrap;
}

.company_system_table .add_new {
  padding: 5px 15px;
  border: 1px solid #002e6c;
  color: #002e6c;
  background: #fff;
}

.company_system_table .left_tsec select,
.company_system_table .filter_down select {
  border: 1px solid #bdbaba;
  padding: 4px;
}

.company_system_table .custom_filter {
  margin-left: 2rem;
}

.company_system_table .default_drop {
  border: none !important;
  color: #002e6c;
}

.company_system_table .right_tsec {
  gap: 2rem;
}

.company_system_table .inf_td img {
  margin-right: 1rem;
}

.company_system_table .tbl_container table tfoot {
  background: #f2f0f2;
}

.company_system_table button {
  background-color: var(--my-color);
  margin-right: 20px;
}

@media (max-width: 768px) {
  .company_system_table .right_tsec {
    margin: 10px 0 4px;
    gap: 10px;
  }

  .company_system_table .left_tsec {
    gap: 10px;
  }
}

@media (max-width: 480px) {
  .company_system_table .right_tsec {
    margin: 5px 0;
  }

  .company_system_table .show_filter {
    gap: 10px;
  }
}

/* company list data table   end */

.product-company-detail {
  padding: 10px 20px;
  margin-top: 5px;
}

.product-company-detail-heading {
  margin-left: 50px;
  font-size: 23px;
  font-weight: 600;
  margin-bottom: 5px;
}

.product-company-detail h3 {
  font-size: 24px;
  margin-bottom: 0px;
  margin-top: 0;
}

.company-details-container {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
}

body {
  /* zoom: 80%; */
}


.scrollable-content {
  overflow: auto;
  /* This will add scrollbars when content overflows */
  max-height: 400px;
  /* Set maximum height for the content */
}

.employee-integration-event .HideShow .top {
  align-items: flex-start;
}

.employee-integration-event {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px;
}

.integration-event-status-green {
  color: green;
}

.integration-event-status-red {
  color: red;
}

.emp-integration-setting {
  padding: 10px 20px;
  height: 320px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.btn-copy:hover {
  background-color: green;
}

.desc-items .mb-0 {
  margin-bottom: 0 !important;
}

.desc-items {
  height: 220px;
  overflow-y: scroll;
}

/* .emp-integration-item{
  margin-bottom: 32px!important;
    margin-left: 10px!important;
} */


.emp-installation-controller {
  background-color: #8a8282;
  padding: 20px 10px;
  font-size: 14px;
  line-height: 1.2;
  min-height: 100vh;
}


.emp-installation-sub-controller {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
  background-color: white;
  padding: 30px;
  border-radius: 5px;
}


.emp-installation-status-table {
  width: 100%;
  margin: 10px 0;
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
}

/* 29/03/2024 */

.modalContainer {
  position: relative;
  height: 400px;
}

.sidebarCustom {
  width: 200px;
  padding: 10px;
  border-right: 1px solid gray;
  background-color: rgb(248, 248, 248);
  height: 100%;
  position: absolute;
  left: 0px;
}

.navlistCustom {
  list-style: none;
  padding-left: 0px;
}

.navitemCustom {
  display: inline-block;
  width: 100%;
  padding-block: 5px;
  padding-inline: 10px;
  border-radius: 5px;
  margin-bottom: 1px;
}

.navitemCustom.myactive {
  background-color: rgb(20, 20, 59);
  color: white;
}

.mainContentCustom {
  position: absolute;
  left: 200px;
  width: calc(100% - 200px);
  height: 100%;
}

.mainContentCustom {
  padding: 10px;
  overflow-y: auto;
}

.formFieldCustom {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.passwordChangeBtn {
  margin-block: 5px;
  padding: 10px 20px;
}

.passwordClearBtn {
  margin-block: 5px;
  padding: 10px 20px;
  background-color: silver;
  color: black;
}

.flexCenterCustom {
  gap: 10px;
  display: flex;
  justify-content: center;
}

.customFieldSet {
  border-radius: 10px;
  border: 2px solid silver;
}

.customFlex {
  display: flex;
  gap: 10px;
}

/* add employee 29/03 */
.addEmloyeeCustom .top {
  display: block;
}

.addEmloyeeCustom .form-input {
  width: 100% !important;
}

.horizontalLinewithOr {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hrLine {
  width: 45%;
  height: 1px;
  border: 1px solid gray;
}

.text-end {
  text-align: end;
}

.newsetupup-btn {
  cursor: pointer;
  background-color: var(--my-color);
  color: white;
  padding: 8px !important;
  border-radius: 3px;
  border: none;
}

.mainContentCustom2 {
  padding: 10px;

  width: 100%;
  height: 100%;
}

.drag-area {
  border: 2px dashed #5256ad;
  height: 130px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.drag-area .icon {
  font-size: 100px;
  color: #fff;
}

.drag-area header {
  padding: 0px 0px 5px 0px;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  position: unset !important;
  background-color: unset;
  color: #5256ad;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drag-area span {
  font-size: 16px;
  font-weight: 500;
  color: #5256ad;
  margin: 5px 0 5px 0;
}

.drag-area button {
  padding: 7px 20px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  outline: none;
  background: #5256ad;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.uploadprocess-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.step {

  margin-top: 25px !important;
}

.setupdlhd {
  margin: 5px 0;
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}


.setupname {
  font-size: 16px;
  display: block;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid rgb(218, 218, 218);
}


.saveInstallationsetupBtn {
  margin-block: 5px;
  padding: 10px 20px;
}

.Cstmfileuploadinput {
  margin-left: 80px;
}

.open-mr {
  margin-right: 5px;
}

.btn-mineral {
  padding: 15px !important;
}

.mineral-container {
  display: flex !important;
}

.left-side-mineral-data {
  margin-right: 860px !important;
  margin-top: 35px !important;
}


/* minral page css start */
.mineral-login-page .main-wrapper {
  max-width: 50rem;
  margin: auto;
  text-align: center;
}

.mineral-login-page .logo-wrapper {
  max-width: 100%;
  margin: 3.75rem 0px 2.5rem;
}

.mineral-login-page .Cstmlogo {
  margin-bottom: 0px;
  margin-top: 20px;
  max-height: 60px;
  max-width: 200px;
}

.mineral-login-page .hr {
  border: none;
  height: 1px;
  margin: 2rem auto;
  width: 100%;
  background-color: rgb(203, 203, 203);
}

.mineral-login-page .signin-heading {
  font-size: 2rem;
  font-weight: 500;
}

.mineral-login-page .form-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 90px auto 40px;
  max-width: 800px;
  padding: 0px 20px;
  width: 100%;
}

.mineral-login-page .Cstmtext-start {
  text-align: start;
  margin-bottom: 10px;
  color: rgb(40, 40, 40);
  line-height: normal;
  font-size: 16px;
  font-weight: bold;
}

.mineral-login-page #Username {
  width: 480px;
  height: 48px;
  font-size: 16px;
  border-color: rgb(169, 169, 169);
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  display: block;
  padding: 6px 15px;

}

.mineral-login-page #Password {
  width: 480px;
  height: 48px;
  font-size: 16px;
  border-color: rgb(169, 169, 169);
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  display: block;
  padding: 6px 15px;

}

.mineral-login-page input[name="checkbox"] {
  height: 20px;
  width: 20px;
}

.mineral-login-page .remember-wrapper {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.mineral-login-page .signin-btn {
  border: none;
  font-weight: 500;
  background: #005e8e;
  min-height: 60px;
  min-width: 152px;
  padding: 5px 15px;
  width: 320px;
  font-size: 20px;
  margin-top: 20px;

}

.mineral-login-page .forgotpasstext {
  color: rgb(105, 205, 255);
  font-size: 20px;
  font-weight: 500;
}

.mineral-login-page .termstext {
  padding: 5rem 0px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: #282828;
}

.mineral-login-page .cstmtext-color {
  color: rgb(105, 205, 255);
}

/* mineral page css end */

.light {
  transform: rotate(180deg);
  transition: all .3s ease-in;
  color: #002e6c;
  height: 30px;
  margin-left: 15px;
  margin-top: 12px;
}

.dark {
  transform: rotate(360deg);
  transition: all .3s ease-in;
  color: #002e6c;
  height: 30px;
  margin-left: 15px;
  margin-top: 12px;
}

/* mineral cart */
.mineral_cart {
  padding: 0.5rem 1.5rem;
  margin: 1rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 50px;
  border-bottom: 4px solid #3894ff;
  cursor: pointer;
  transition: ease-in 0.3s;
  background-color: #fff;
  min-height: 300px;
}

.mineral_cart_img {
  height: 270px;
  width: 100%;
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-bottom: 4px solid #3894ff;
  cursor: pointer;
  border-radius: 10px 10px 10px 10px;
}

.mineral-main-sec {
  display: flex;
  justify-content: space-between;
}

.market-place .slick-list .slick-track {
  padding: 0;
}

.market-place h1 {
  padding: 10px 0 0 0;
  font-size: 24px;
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.mineral-sec .btn-mineral {
  padding: 10px 15px !important;
}

.mineral-img img {
  height: 50px;
}

.mineral-img p {
  margin: 0;
}

.mineral-desc {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
}

.mineral-desc-min {
  padding: 10px;
  border-radius: 8px;
}

.min_biz p {
  color: var(--paracolor);
  font-style: var(--paragraphfontstyle);
}

.mineral-dynamic-desc p {
  margin: 0 0 30px 0;
  color: var(--paracolor);
  font-style: var(--paragraphfontstyle);
}

.market-place .slick-list .slick-slide {
  padding: 0 5px;
}

.market-place .slick-slider .slick-next {
  right: 0;
}

.market-place .slick-slider .slick-prev {
  left: 0;
}

@media(max-width:768px) {

  .market-place .slick-list .slick-slide {
    padding: 0 5px;
  }

  .mineral-main-sec {
    flex-wrap: wrap;
  }

  .mineral-page {
    padding: 20px 0 0 0;
  }
}

@media(max-width:576px) {
  .market-place .slick-list .slick-slide {
    padding: 0;
  }
}

.auth_text {
  text-align: center;
  margin-top: 15px;
  height: 500px;
}

.edit-icon-btn-mineral {
  cursor: pointer;
}

.video_img {
  width: 100%;
  height: 270px;
  object-fit: cover;
  width: 100%;
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-bottom: 4px solid #3894ff;
  cursor: pointer;
  border-radius: 10px 10px 10px 10px;
}

.configuration_bizlibrary {
  margin-bottom: 10px;
  text-align: center;
  line-height: 1;
  padding: 10px 10px 10px 0;
  font-size: 30px;
}

.configuration_bizlibrary_url {
  margin-bottom: 5px;
  width: 100%;
  padding: 12px 10px !important;
}

.biz_lib_file {
  /* width: 50%; */
  padding: 10px;
  -webkit-border-radius: 5px;
  border: 1px dashed #012b64;
  text-align: center;
  background-color: #DDD;
  cursor: pointer;
}

.columndata {
  float: left;
  width: 50%;
  padding: 10px;
  height: 300px;
  /* Should be removed. Only for demonstration */
}

/* Clear floats after the columns */
.rowdata:after {
  content: "";
  display: table;
  clear: both;
}


/* 02/07/2024 */
.cstm-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.cstm-col-4 {
  width: 33.3%;
  padding: 10px;
}

.PresentationImage {
  width: 100%;
  object-fit: cover;
}

.RemovePpt {
  position: absolute;
  top: 0px;
  right: 0px;
  display: none;
  transform: translate(50%, -50%);
  cursor: pointer;
}

.PptParent {
  position: relative;
}

.PptParent:hover .RemovePpt {
  display: flex;
}

/* modal */
.product-modal-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-modal-center .product-modal-inner {
  width: 700px;
  max-width: 80%;
}

.product-modal-center .user-provisional-outer {
  width: 100%;
}

.product-modal-body {
  background-color: #ffffff;
  /* padding: 10px; */
}

/* modal end */

.schedule-confi {
  color: var(--productcolor);
  font-size: var(--proheadingfontsize);
  font-style: var(--prohendingfontstyle);
}

.cus_report strong {
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.cus_report span {
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.cus_report_heading {
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.cus_report label {
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.paragraph {
  color: var(--paracolor);
  font-size: var(--paragraphfontsize);
  font-style: var(--paragraphfontstyle);
}

.heading_style {
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.paragraph_style {
  color: var(--paracolor);
  font-style: var(--paragraphfontstyle);
}

.font_color {
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

/* input file */
.cstm-close-btn {
  width: 80px !important;
  height: 40px !important;
  border-radius: 2px;
  margin-left: 5px;
  padding: 5px;

}

.cstm-flex-end {
  display: flex;
  justify-content: flex-end;
}

/* input file end */
.border-left {
  border-left: 1px solid #c5c5c5;
}

/* 09/07/2027 company status */


.cstm-col-3 {
  width: 25%;
  padding: 10px;
}

.cstm-card {
  border-radius: 5px;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0px 0px 2px #c0c0c0;
}

.cstm-col-6 {
  width: 50%;
  padding: 10px;
}

.cstm-col-12 {
  width: 100%;
  padding: 10px;
}

.cstmflexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cstm-card-head {
  font-size: 45px;
  color: var(--my-color);
  text-align: start;

}

.cstm-sub-heading {
  font-size: 18px;
  font-weight: 800;
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.cstm-card-svg {
  width: 80%;
}

.cstm-card-svg svg {
  max-width: 70px;
}

.color-primary {
  color: var(--primaryColor);
}

/* cstm table */
.cstm-tbl_container thead {
  background-color: var(--my-color);
  text-align: left;
  color: #ffffff;
}

.cstm-tbl_container tr th:last-child {
  text-align: center;
}

.cstm-tbl_container tr td:last-child {
  text-align: center;
}

.export-csv {
  background-color: var(--my-color);
}

/* .ViewAllTable{
  margin-right: auto;
} */
.cstm-flex-end {
  display: flex;
  text-align: right;
}

.endStart {
  font-size: 16px;
  margin: 0 5px;
  width: 151px;
  padding: 5px;
  border: 1px solid;
  border-color: #c5b9b9;
}

.tbl-td-th {
  padding: 10px !important;
}

.iconspace {
  display: flex;
  justify-content: space-evenly;
}

.iconspace button {
  background-color: transparent !important;
  margin-right: 0px !important;
}

.countemp {
  font-size: 15px;
  font-weight: 600;
  padding: 0px;

}

/* dragan drop css start*/
.flexible-modal {
  position: absolute;
  z-index: 1;
  border: 1px solid #ccc;
  background: white;
  border-radius: 8px;
}

.flexible-modal-mask {
  position: fixed;
  height: 100%;
  background: transparent !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.flexible-modal-resizer {
  position: absolute;
  display: none;
  right: 0;
  bottom: 0;
  cursor: se-resize;
  margin: 5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}

.flexible-modal-drag-area {
  /* background: var(--my-color) !important; */
  background: transparent !important;
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: move;
}

.dramodal {
  color: #ffffff !important;
}

.drag-btn {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  position: absolute;
  bottom: 20px;
  right: 5px;
}

.drag-btn-close {
  width: 80px;
  font-size: larger;
  margin-right: 10px;
}

.dragbody {
  margin-left: 10px;
  margin-top: 10px;
  height: 250px;
  overflow-y: auto;
}

.dragbody input {
  margin-right: 10px;
}

/* .draginp {
  margin-top: 10px;
} */

/* .my-modal-custom-class {
  color: #ffffff !important;
} */

.my-modal-custom-class .modalTitle {
  color: #ffffff;
  font-weight: 500;
  width: 100%;
  font-size: 26px;
}

.Cstmsubtitle {
  border-bottom: 0px solid var(--my-color);
  padding: 0px 0px 0px 0px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 12px;
}

.dramodal {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--my-color) !important;
  text-align: center;
  height: 60px;
  border-radius: 8px 8px 0 0;
}

.draginp {
  padding: 10px;
  /* background: #39105b1c; */
}

.Cstmdraginp {
  padding: 10px;
  background-color: #fff;
}

.drag-loader {
  height: 100px !important;
  width: 100px !important;
  margin-top: 100px;
  margin-left: 230px;
}

.tabmar {
  margin: 0px 50px 0px 50px;
}

.tabmars {
  margin: 40px 50px 30px 50px;
}

.loader-height {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

/* dragan drop css end */

button#draghandle {
  position: absolute;
  bottom: -42px;
  right: 0;
}

.prod-card {
  position: relative;
}

.CstmFlex {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.Cstmdraginp {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

}

.sl-heart1 .sl-loader {
  width: 95px;
}

.sl-spinner3 .sl-loader {
  width: 60px;
}

.sl-spinner3 .sl-loader .sl-loader-parent .sl-circle {
  width: 56px;
  height: 56px;
}

.sl-bubble5 .sl-loader {
  width: 75px;

}

.sl-box4 .sl-loader {
  width: 74px;
  margin-left: 20px;
}

.sl-box1 .sl-loader .sl-loader-items .sl-parent-cub {
  width: 56px;
  height: 56px;
}

.sl-bubble4 .sl-loader {
  width: 70px;

}

.sl-bubble3 .sl-loader {
  width: 110px;

}

.sl-ekvalayzer .sl-loader-items {
  width: 60px;
  height: 56px;
}

.sl-bubble2 .sl-loader {
  width: 90px;
  height: 40px;
  margin-left: 15px;
}

.sl-bubble1 .sl-loader {
  width: 90px;
}

.sl-box1 .sl-loader .sl-loader-items {
  width: 100%;
  height: 60px;

}

.sl-box3 .sl-loader .sl-loader-items .sl-parent-cub {
  width: 50px;

}

.CstmHeight {
  height: 145px;
  overflow-y: auto;
}

.cus_loader {
  height: 70px !important;
  width: 70px !important;
  margin: 0px 0px 0px 25px;
}

.box-loader {
  padding: 10px;
  border: 0px solid #a3a3a3;
  /* background-color: #ffffff; */
  border-radius: 5px;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85px;
  width: 85px;
}

.show-Icon {
  font-size: 20px;
}

.plusIcon {
  padding-left: 425px;
}

.plusIcons {
  padding-left: 380px;
}

.plusIcons-font {
  padding-left: 324px;
}

.plusIcons-loader {
  padding-left: 415px;
}

.plusIcon_logo {
  padding-left: 365px;
}

.loader-plus-iconlement {
  padding: 10px;
  border: 0px solid #cccccc;
  background-color: #dddddd;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.subtitle-line {
  margin: 0px 10px 14px 1px;
}

.sub-plusIcon {
  margin-left: 335px;
}

.sub-st-plusIcon {
  margin-left: 314px;
}

.sub-st-para-plusIcon {
  margin-left: 300px;
}

.sub-plusIcons {
  margin-left: 325px;
}

.loader-plus-iconlement-sub {
  padding: 10px;
  border: 0px solid #cccccc;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.font_size {
  height: 40px;
  width: 80%;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px;
  font-size: 15px;
}

/* new css */

/* .prod-description-extra-dev {
  display: none;
} */

.CstmModalScroll {
  max-height: 20vh;
  overflow-y: auto;

}

.market-place-card .description {
  max-height: 130px;
  overflow-y: auto;
  padding: 10px;
}

.cus_font {
  margin: 0px 0px 0px 0px;
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
  font-size: 15px;
}

.data_space p {
  margin-right: 10px !important;
}

/*18/096/2024*/
.product-cards {
  height: calc(100% - 0px);
}

.product-cards-outer::-webkit-scrollbar {
  display: none;
}

.total-card {
  align-items: unset;
}

.total-card .companyInfoCardCol .cstm-card {
  height: 100%;
}

.select-employee .top {
  flex-direction: column;
  align-items: start;
}

.integration-btn {
  display: flex;
  align-items: center;
  gap: 10px;
}

.themesetting-outer .product-setting-inner .HideShow .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #dddddd;
  padding: 12px 10px 12px 0px;
  border-radius: 8px;
}

.themesetting-outer .product-setting-inner .HideShow .top label {
  background: none;
  box-shadow: unset;
}

.themesetting-outer .product-setting-inner {
  padding: 0px 0px 0px 10px;
}

.themesetting-outer .product-setting-inner .HideShow .bot .HideShow .top {
  width: calc(100% - 110px);
  margin-left: 10px;
}

.themesetting-outer .product-setting-inner .HideShow .bot .HideShow .controllers {
  margin-left: -40px;
}

/*18/096/2024*/

.sync_history_mar {
  margin: 0px 35px 0px 0px
}

.sync_trainig {
  margin-top: 15px;
}

.sync_tab_position {
  position: sticky;
  top: 0;
  background-color: #fff;
}

.tabmar_tab {
  overflow-y: scroll;
  height: 445px !important;
}

.date_search {
  margin-right: 10px;
  font-size: large;
}

.date_search_inp {
  margin-right: 52px;
  font-size: large;
}

.sync_enb {
  height: auto;
  width: 165px !important;
}

.cus_sync {
  display: flex;
  justify-content: space-between;
}

.tab_head {
  position: sticky;
  top: 45;
  background-color: #fff;
  z-index: 1
}

.cus_mod_popup {
  display: flex;
  justify-content: space-between;
  position: sticky;
  align-items: center;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.D_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d_width {
  width: 50px;
}

.modal_open_cus {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.modal_open_cus_body_popup {
  background-color: #fff;
  padding: 20px !important;
  border-radius: 10px !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) !important;
  width: 45%;
  height: 55vh;
  overflow-y: auto;
  margin-top: -210px;
}

.tab_not_found {
  font-weight: bold;
  font-size: 25px;
  text-align: center;
}

.company-login {
  color: var(--my-color);
  font-size: 20px;
}

.company-delete {
  color: var(--my-color);
}

.company-restore {
  color: red;
}

.activity_chart {
  width: 46% !important;
  padding: 10px !important;
}

.activity_chart svg {
  width: 700 !important;
}

/* market btn view */
.market-btn {
  display: flex;
}

.product-item-png {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-item-png img {
  width: 70%;
  object-fit: contain;
  padding: 2px 2px 2px 2px;
}

.no-product {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 600;
}

.market-btn button {
  margin-left: 15px;
  height: 25px;
  /* margin-bottom: 7px; */
  margin-top: 10px;
}

.font_logs td {
  font-family: monospace !important;
}

.login-comp input {
  height: 50px;
  width: 100%;
}

.pro-tab {
  display: flex;
  justify-content: center;
  /* margin-top: 20px; */
  margin: 15px 0px 15px 0px;
  font-size: 20px;
  font-weight: 800;
}

.close-eye {
  margin: 15px 0px 0px -30px;
  cursor: pointer;
}

.tab-head {
  position: sticky;
  top: 0;
}

.logo-pro-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-pro-div img:hover {
  cursor: pointer;
}

.comp-pro-img {
  height: 65px;
  width: 62px;
  padding: 2px 2px 2px 2px;
  object-fit: contain;
}

.cursor_point:hover {
  cursor: default !important;
}

.comp-pro-imgs {
  width: 100px;
  padding: 2px 2px 2px 2px;
  object-fit: contain;
}

/* end */
.pending {
  color: rgb(244, 143, 12);
}

.success {
  color: green;
}

.fail {
  color: red;
}

@media only screen and (max-width: 552px) {
  .mul-association-btn button.ms-2 {
    margin-top: 10px;
  }

  .CstmModalScroll {
    max-height: calc(100vh - 710px);
    overflow-y: auto;
  }

  .mul-association-btn button {
    padding: 12px 30px !important;
    width: 70%;
  }

  .CstmSettingWrapper {
    padding: 15px;
  }
}

.start_date_dash {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #fff;
  padding: 3px 5px;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
  width: 190px;
  margin-bottom: 15px;
}

.Ann_btn {
  display: flex;
  justify-content: flex-end;
  /* margin-right: 0px; */
  position: static;
  bottom: 20px;
}


/* Market place list */
.market-list {
  height: 700px;
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 20px 0px 20px 20px;
}

/* .market-list p {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
} */
.market-list .market-place-card {
  width: 310px;
  border-radius: 10px 10px 10px 10px;
  border-bottom: aliceblue;
  box-shadow: rgba(0, 0, 0, -0.7) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.market_name {
  text-align: center !important;
  margin-bottom: 20px;
}

/* Nandan P 01/15/2024 starts */

.marketcard-border {
  border: 1px solid #e3e3e3;
}


.grid-marketlist {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 20px 20px 8px;
  grid-gap: 28px 16px;
  width: 100%;
  margin-inline: 0px;
}

.grid-marketlist .market-place-card {
  margin: 0px;
  width: 100%;
  padding: 24px;
}


.grid-marketlist .market-place-card .description {
  padding: 10px 10px 0px;
}

.set-bottom .market-place .slick-list .slick-track {
  padding: 12px 0px;
}

.set-bottom .market-place-card {
  margin: 1rem .5rem;
}

/* New css */

.IconTh {
  width: 10% !important;
}

.pro_connecter .product-modalBody {
  width: 85vw;
  height: 750px;
}
.pro_connecter .company_system_table .tbl_container{
  height: 600px;
}