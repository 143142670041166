.dashboard .intsCont {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.dashboard .intsCont .intx {
  width: 30%;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.4);
  margin: 5px 5px 15px 5px;
  transition: all 0.3s;
  border-radius: 5px;
  border: solid 1px rgba(0,0,0,.1);
}

.dashboard .intsCont .intx:hover {
  box-shadow: 0 3px 3px 0 rgba(0,0,0,.4);
  transform: translateY(-4px);
  transition: all 0.3s;
}

.dashboard .intsCont .intx .title {
  padding: 10px 5px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
}

.dashboard .intsCont .intx .img {
  height: 100px;
  margin: 5px 0;
}

.dashboard .intsCont .intx .desc {
  padding: 15px 5px;
  text-align: center;
  padding-top: 0;
}

.dashboard .intsCont .intx .status.green, .dashboard .intsCont .intx .status.red {
  color: white;
}

@media only screen and (max-width: 768px) {
  .dashboard .intsCont .intx {
    width: 45%;
  }
}

@media only screen and (max-width: 520px) {
  .dashboard .intsCont .intx {
    width: 90%;
  }
}


.dashboard .admin .head {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
}

.dashboard .admin .section {
  margin-bottom: 15px;
}