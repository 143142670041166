.UserProvisioningBody .intsCont {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.UserProvisioningBody .intsCont .intx {
  width: 30%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.4);
  margin: 5px 5px 15px 5px;
  transition: all 0.3s;
  border-radius: 5px;
}

.UserProvisioningBody .intsCont .intx:hover {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.4);
  transform: translateY(-4px);
  transition: all 0.3s;
}

.UserProvisioningBody .intsCont .intx .img {
  height: 100px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.UserProvisioningBody .intsCont .intx .title {
  padding: 10px 5px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
}

.UserProvisioningBody .intsCont .intx .desc {
  padding: 15px 5px;
  text-align: center;
  padding-top: 0;
}

.UserProvisioningBody .intsCont .intx .status {
  padding: 10px 5px;
  text-align: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #eeeeee;
}

.UserProvisioningBody .intsCont .intx .status.green,
.UserProvisioningBody .intsCont .intx .status.red {
  color: white;
}

.status.blue {
  background-color: #001c80 !important;
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .UserProvisioningBody .intsCont .intx {
    width: 45%;
  }

  .UserProvisioningBody .intsCont {
    justify-content: center;
  }
}

@media only screen and (max-width: 520px) {
  .UserProvisioningBody .intsCont .intx {
    width: 90%;
  }
}

.bold {
  font-weight: bold !important;
}
