.HideShow .top {
  display: flex;
  align-items: flex-end;
  margin-right: 10px;
  cursor: pointer;
}

.HideShow .top .controller {
  margin-left: 10px;
  font-size: 20px;
  font-weight: 600;
}

.HideShow .top .controller button {
  font-size: 12px;
  background: none;
  color: black;
}
.HideShow .top .controllers {
  margin-left: -20px;
  font-size: 20px;
  font-weight: 600;
}
