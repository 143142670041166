:root {
  --my-color: #002e6c;
  --color: #ffffff;
  --primaryColor: #002e6c;
  --productcartbackcolor: #eeeeee;
  --productcolor: #000;
  --background: #f0f3f4;
  --bgcolor: #f0f3f4;
  --paracolor: #000;
  --paragraphfontsize: 16px;
  --proheadingfontsize: 20px;
  --prohendingfontstyle: '';
  --paragraphfontstyle: '';
}

.CloudPunchBody h2 {
  display: flex;
  align-items: center;
}

.search-input {
  display: inline-block !important;
}

.my-btn-disable {
  background-color: #ddd;
  color: #000;
}

.CloudPunchBody h2 button {
  font-size: 12px;
  margin-left: 10px;
}

.bg-theme {
  background-color: var(--my-color) !important;
}

.CloudPunchBody .section {
  margin-top: 20px;
  position: relative;
}

.CloudPunchBody .section .head {
  font-size: 22px;
}

.CloudPunchBody .CreateApplicant {
  margin-top: 10px;
}

.CloudPunchBody .tableCont .noapps {
  padding: 10px;
}

.CloudPunchBody .table button {
  font-size: 12px;
}

.CloudPunchBody .table .but {
  width: 150px;
  text-align: center;
}

.CloudPunchBody .table .but>div {
  margin: 0 auto;
}

.CloudPunchBody .table .enrrow.active {
  font-weight: 800;
}

.CloudPunchBody .head {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.CloudPunchBody .ccTreeName {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.CloudPunchBody .ccTreeName .name {
  font-size: 16px;
  font-weight: 700;
}

.CloudPunchBody .ccTreeName .addmore {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
}

.CloudPunchBody .ccTreeCont {
  margin-bottom: 15px;
}

.CloudPunchBody .ccTreeList {
  padding-left: 10px;
}

.CloudPunchBody .ccTreeList .ccName {
  display: flex;
  align-items: center;
}

.CloudPunchBody .addDelete {
  background: none;
  padding: 0;
  margin-left: 5px;
}

.CloudPunchBody .addDelete div {
  height: 15px;
  width: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CloudPunchBody .addDelete.add {
  background-color: #0cad4d;
}

.CloudPunchBody .addDelete.delete {
  background-color: red;
}

.CloudPunchBody .addDelete.delete div {
  height: 10px;
}

.CloudPunchBody .install {
  padding-left: 15px;
}

.CloudPunchBody .HideShow h2 {
  display: block;
  margin-bottom: 0;
}

.CloudPunchBody .HideShow .step .stepBody {
  padding-left: 15px;
  color: var(--paracolor);
  font-style: var(--paragraphfontstyle);
}

.CloudPunchBody .HideShow .step {
  margin: 15px 0;
}

.CloudPunchBody .HideShow .step:first-of-type {
  margin-top: 5px;
}

.CloudPunchBody .HideShow .head {
  font-size: 18px;
  margin-bottom: 5px;
  text-decoration: underline;
  font-style: var(--prohendingfontstyle);
  color: var(--productcolor);
}

.CloudPunchBody .HideShow p {
  margin: 8px 0;
}

.btn-cloudpunch-download {
  padding: 8px !important;
  margin: 5px;
  border-radius: 10px !important;
}

.CloudPunchBody .downloadlinks a {
  display: inline-block;
  background-color: #002e6c;
  color: white;
  border-radius: 10px;
  padding: 5px 10px;
  margin-right: 10px;
}

.table.tablecstm .col2 {
  width: 10%;
}

.table.tablecstm .col5 {
  width: 40%;
}

.sort-select {
  padding: 3px 15px;
}

.margin-left-button-blue {
  margin-left: 5px;
  color: blue;
}

.margin-left-button-red {
  margin-left: 5px;
  color: red;
}

.margin-left-button-yellow {
  margin-left: 5px;
  color: yellowgreen;
}

.margin-left-button {
  margin-left: 5px;
}

.installation-tablerow {
  background-color: var(--my-color);
  color: white;
}

.icon-plus {
  font-size: 18px;
  font-weight: 600;
  border: 2px solid #000;
  width: 25px;
  height: 25px;
  border-radius: 27px;
  line-height: 25px;
  cursor: pointer;
  text-align: center;
}

.mealBrackSelect {
  width: 100%;
  margin: 0;
  border: none;
}

.visibility-none {
  visibility: hidden;
}

.circle-default {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  display: block;
  background: #000;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}


.actions {
  display: flex;
  justify-content: center;
}

.actions-btn {
  margin-left: 5px;
}

.circle-flex-main {
  display: flex;
  padding-right: 13px;
  justify-content: center;
}

.circle-flex {
  display: flex;
  justify-content: center;
}

.circle-blue-animation {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  display: block;
  background: linear-gradient(85deg, #12048b, #2b07fc);
  border-radius: 50%;
  animation: blueanimate 0.7s infinite;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.circle-red-animation {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  display: block;
  background: linear-gradient(85deg, #8b0404, #ff3232);
  border-radius: 50%;
  /* animation: greenanimate 0.7s infinite; */
  cursor: not-allowed;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.circle-blue {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  display: block;
  background: #00FF00;
  border-radius: 50%;
  /* animation: blueanimate 0.7s infinite; */
  box-shadow: rgb(0 50 253 / 24%) 0px 3px 8px;
}

.circle-red {
  margin-left: 5px;
  width: 15px;
  height: 15px;
  display: block;
  background: #1f1f1f;
  border-radius: 50%;
  /* animation: greenanimate 0.7s infinite; */
  cursor: not-allowed;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@keyframes blueanimate {
  from {
    background: linear-gradient(85deg, #12048b, #0d0053);
  }


  to {
    background: linear-gradient(85deg, #2b07fc, #2a17d4);
  }

}

@keyframes greenanimate {
  from {
    background: linear-gradient(85deg, #000000, #ff3232);
  }


  to {
    background: linear-gradient(85deg, #ff4747, #000000, );
  }

}





@media only screen and (max-width: 768px) {}