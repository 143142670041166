.callbackPage .errorBox {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}

.callbackPage .errorBox .error {
  font-weight: bold;
  margin-bottom: 30px;
}

.callbackPage .errorBox .linkBox {
  text-align: center;
}
