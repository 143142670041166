:root {
  --my-color: #002e6c;
  --color: #ffffff;
  --widthcard: 546px;
  --background: #f0f3f4;
  --productcolor: #000;
  --paracolor: #000;
  --proheadingfontsize: 20px;
  --prohendingfontstyle: '';
  --paragraphfontstyle: '';
  --paragraphfontsize:16px
}

.emp-content.sidebar .emp-side-bar {
  left: -220px;
}

.emp-content.sidebar .emp-body {
  width: 100%;
}

.outline {
  padding-bottom: 0;
}

.btn_grp_mng {
  display: flex;
  gap: 10px;
}

.emp-side-bar {
  width: 220px;
  background: var(--my-color);
  /* background: #002e6c; */
  position: fixed;
  top: 87px;
  left: 0;
  height: 100%;
  transition: 0.3s;
}

.emp-body {
  width: calc(100% - 220px);
  margin-left: auto;
  background: #f0f3f4;
  transition: 0.3s;
  /* padding: 25px; */
  height: 100vh;
}

.emp-body .emp-body-inner {
  padding: 96px 25px 25px 25px;
  min-height: 100vh;
  background-color: var(--background);

}

.emp-dash-card {
  width: 20%;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
}

.dash-icon {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  background-color: #f5365c !important;
  color: #fff;
  font-size: 20px;
}

.emp-row {
  display: flex;
  flex-wrap: wrap;
}

/* side bar css start */
.dash-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.side-bar-prod-icon {
  width: 40px;
}

.dash-menu>li>span {
  padding: 12px 20px;
  color: white;
  display: block;
  font-size: 16px;
  border-bottom: 1px solid #fff;
}

.dash-menu>li>span.active {
  cursor: pointer;
  color: var(--my-color);
  background-color: #fff;
}

.dash-menu li>span:hover {
  cursor: pointer;
  color: var(--my-color);
  background-color: #fff;
}

.dash-menu>li>span svg {
  padding-right: 10px;
}

.dash-menu .drop-down>li>span {
  padding: 12px 20px;
  color: white;
  display: block;
  font-size: 16px;
  border-bottom: 1px solid #fff;
}

.dash-menu .drop-down>li>span.active {
  cursor: pointer;
  color: var(--my-color);
  background-color: #fff;
}

.dash-menu .drop-down>li>span:hover {
  cursor: pointer;
  color: var(--my-color);
  background-color: #fff;
}

/* side bar css end */

header {
  display: flex;
  justify-content: space-between;
  /* background: #002e6c; */
  background: var(--my-color);
  width: 100%;
  padding: 10px 10px;
  box-sizing: border-box;
  align-items: center;
  position: fixed;
  z-index: 1;
}

.header-right button {
  cursor: pointer;
}

/* .header-right img {
  width: 40px;
  background: white;
  border-radius: 50%;
  padding: 5px;
} */
.header_img {
  width: 40px;
  background: white;
  border-radius: 50%;
  padding: 5px;
}

.theme_img{
  /* width: 40px !important;
  background: white !important;
  border-radius: 50% !important; */
  padding: 5px;
  margin-top: -10px;
}

ul.drop_down-admin {
  position: absolute;
  right: 10px;
  top: 62px;
  min-width: 140px;
  background: white;
  padding: 0px 2px;
  border-radius: 6px;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 0 16px #00000030;
}

ul.drop_down-admin li {
  list-style: none;
  padding: 10px;
}

ul.drop_down-admin li a {
  display: block;
  /* padding: 5px 15px; */
}

ul.drop_down-admin li a:hover {
  background: var(--my-color);
  color: white;
}

.hover-active:hover {
  background: var(--my-color);
  color: white;
  cursor: pointer;
}

.logo {
  padding-left: 20px;
  display: flex;
}

.logo-wrp {
  width: 180px;
}

.open_sidebar-close svg {
  font-size: 20px;
}

.logo img {
  width: 85%;
}

ul.drop_down-admin.active {
  visibility: visible;
  opacity: 1;
}

.tlt_dash {
  text-align: left;
  color: var(--productcolor);
  font-style: var(--prohendingfontstyle);
}

.bx_cstm-imp {
  display: flex;
  gap: 40px;
}

.bx_ctsm_con {
  width: 25%;
  background: white;
  border: 0;
  display: flex;
  align-items: center;
  padding: 0;
  box-shadow: 0 0 10px #00000026;
  border-radius: 4px;
}

.bx_ctsm_con svg {
  width: 50px;
  height: 50px;
  padding-right: 30px;
  color: white;
  background: #002e6c;
  padding: 20px 20px;
  margin-right: 20px;
  border-radius: 4px 0 0 4px;
}

.detail_bx-cstm p {
  margin: 0;
  padding: 0;
  color: #606060;
  font-weight: 500;
  padding-top: 16px;
}

.detail_bx-cstm h3 {
  margin: 0;
  padding: 0;
  color: #a3a3a3;
  font-size: 28px;
  font-weight: 600;
}

.table-wrpcstm {
  margin-top: 40px;
}

.table-wrpcstm table tr th {
  text-align: left;
}

button.btn_act_delete {
  background: #dc3545;
  margin-right: 10px;
}

ul.under_drop {
  padding: 0;
  background: #0b62a1;
  height: 0;
  opacity: 0;
  visibility: hidden;
}

li.drop_menu a .icon_rights {
  position: absolute;
  right: 0;
  top: 0;
  padding: 14px 10px;
}

li.drop_menu a {
  position: relative;
}

.bx_search_filter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.bx_search_filter .search_bx-wrp {
  position: relative;
}

.bx_search_filter .search_bx-wrp button {
  position: absolute;
  top: 0;
  right: 0px;
  padding: 6px 10px;
  background: transparent;
  color: black;
}

.pagination_wrp ul {
  display: flex;
  justify-content: flex-end;
  padding: 0;
  padding-right: 10px;
  margin: 0;
  padding-top: 20px;
  gap: 0;
}

.pagination_wrp ul li {
  list-style-type: none;
}

.pagination_wrp ul li:first-child button {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.pagination_wrp ul li button {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #002e6c;
  background-color: transparent;
  border: 1px solid #d7d7d7;
  border-radius: 0;
}

.pagination_wrp ul li:last-child button {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

/* dashboard card style start */
.dash-product-row {
  display: flex;
  flex-wrap: wrap;
}

.dash-product-card {
  width: 33%;
}

.dash-product-cards {
  width: var(--widthcard);
  /* resize: horizontal;
  overflow: hidden;  */
}

.dash-product-card p {
  margin: 0;
}

.prod-card {
  border: 1px solid #ddd;
  padding: 20px 20px 0 20px;
  cursor: pointer;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  border-radius: 8px;
  transition: 0.3s;
  margin: 10px 10px;
  /* height: 100%; */
  /* background: var(--bgcolor); */
  background: var(--bgcolor);
}

.prod-card:hover {
  transform: translate(0, 3px);
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.ess-portal .prod-card {
  padding: 20px 20px 20px 20px;
}

.prod-card img {
  width: 100%;
}

.prod-card .prod-img {
  max-width: 75px;
  min-width: 40px;
  width: 100%;
  margin-left: 12px;
}

.card-bar-one .card-content {
  width: calc(100% - 75px);
  margin-top: 4px;
}

.prod-card .prod-heading {
  /* font-size: 20px; */
  font-size: var(--proheadingfontsize);
  font-weight: 700;
  margin-bottom: 10px;
}

.prod-card .card-bar-one {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-bar-one input.prod-heading {
  background: none;
  padding: 0;
  border: 1px solid var(--bgcolor);
}

.card-bar-one input.prod-heading.active:focus {
  border: 1px solid var(--my-color) !important;
}

.card-bar-one textarea.prod-description {
  background: none;
  padding: 0;
  border: 1px solid var(--bgcolor);
  width: 100%;
  font-size: var(--paragraphfontsize);
  /* height: calc(1em * 1.5 * 5 + 3px + 3px + 1px + 1px); */
  height: 100px;
  resize: none;
  cursor: context-menu;
  position: relative;
  color: var(--paracolor);
  font-style: var(--paragraphfontstyle);
}

.card-bar-one textarea.prod-description.active:focus {
  border: 1px solid var(--my-color) !important;
}

.prod-heading-style {
  font-style: var(--prohendingfontstyle);
}

/* dashboard card style end */

/* Nandan P 15/01/2024 */

.set-layout {
  display: flex;
  flex-direction: column;
}

.set-bottom {
  margin-top: auto ;
}