@media screen and (min-width: 1920px) {
}
@media screen and (max-width: 1920px) {
  .ein-report.dashboard-report-emp .report-card .item-card {
    height: 100%;
  }

  .ein-report.dashboard-report-emp .heading {
    justify-content: center;
  }
  .emp-dashboard-body .WebclockAttestation .step .stepHeader {
    flex-direction: column;
  }
  .emp-dashboard-body .JobBoard .head {
    display: flex;
    justify-content: space-between;
  }
  .emp-dashboard-body .JobBoard .head h1 {
    width: 78%;
    text-align: left;
  }
  /* .emp-dashboard-body .breadcrumbs {
    margin-top: 11px;
  } */
  .emp-dashboard-body .emp-body {
    height: 100%;
  }
  button.one-control-btn.sftp-add-btn {
    padding: 14px 45px;
    margin: 10px 8px 0;
  }
  .product-card-outer-out.productcardout-list {
    width: 25%;
  }
  .Integration-outer.integration-active .product-card-content .product-card-outer-out.productcardout-list {
    width: 20%;
}
  .product-modal
    .product-modal-content.productmodalcontent-sm
    .one-col-sm-10.d-flex {
    position: relative;
  }
  .emp-dashboard-body .JobBoard .img {
    width: 220px;
  }
  .emp-dashboard-body .JobBoard .img img {
    width: 100%;
  }
}
@media(max-width:1440px){
  .cstm-col-3.companyInfoCardCol{
    width: 33.3%;
  }
  .grid-marketlist {
    grid-template-columns: repeat(4 , 1fr);
  }
}

@media screen and (max-width: 1366px) {
  button.one-control-btn {
    padding: 14px 10px;
  }
  .dash-product-card.productcard-list,
  .product-card-outer-out.productcardout-list {
    width: 33%;
  }
  .product-modal-content .date-picker .date-range-log {
    top: 43px;
  }
}

@media screen and (max-width: 1224px) {
  .product-setting-section .one-col-6 .label {
    font-size: 14px;
  }
  .product-setting-inner button,
  .product-setting-inner1 button {
    padding: 12px 10px;
  }
  .grid-marketlist {
    grid-template-columns: repeat(3 , 1fr);
  }
}

@media screen and (max-width: 1024px) {
  .emp-dashboard-body .Indeed .sponsor .job .title {
    font-size: 16px;
  }
  .emp-dashboard-body .Indeed .sponsor .job .top .main {
    flex-wrap: wrap;
  }
  .one-col-1.scheduler {
    width: 18%;
  }
  .my-align-item-center {
    align-items: flex-start;
  }
  button.one-control-btn.quick-connect {
    margin-left: 10px;
  }
  .one-col-2.sftp-input {
    width: 32.6666%;
    margin-bottom: 10px;
  }
  :is(.product-modal-content.productmodalcontent-lg) {
    padding: 10px;
    height: auto;
  }

  .product-modal-content.productmodalcontent-lg .imp-container-report {
    padding: 16px;
  }
  .ein-report.dashboard-report-emp .heading h1 {
    font-size: 34px;
  }
  .ein-report.dashboard-report-emp .report-card .item-card {
    justify-content: center;
    flex-wrap: wrap;
  }
  .dashboard-report-emp .item-card {
    height: 100%;
  }
}

@media screen and (max-width: 992px) {
  .dashboard-report-emp .report-card-row {
    display: flex;
    flex-wrap: wrap;
  }
  .dashboard-report-emp .report-card {
    width: 48%;
    padding: 6px;
  }
  .emp-dashboard-body a.pagination__link {
    padding: 4px 10px;
    font-size: 16px;
  }
  .emp-dashboard-body .JobsBody .appsCont .app .top .name {
    margin-right: 20px;
    font-size: 16px;
  }
  .emp-dashboard-body .sourceTime {
    font-size: 14px;
  }
  .emp-dashboard-body .pagination-container a {
    padding: 4px 10px;
    font-size: 16px;
  }
  .one-col-2.export-file-name {
    width: auto;
  }
  .one-col-2.notification-type {
    width: auto;
  }
  .my-align-item-center.sftp-section {
    width: 100%;
    margin-bottom: 11px;
    margin-top: 5px;
  }
  .one-col-2.export-file-type {
    width: auto;
  }

  .one-col-2.import-input {
    width: auto;
  }
  .one-col-1.scheduler label {
    font-size: 14px;
  }
  .my-flex.scheduler-type {
    width: 100%;
  }
  /* .product-modal.productmodal-full .product-modalBody {
    width: 88%; */

  /* .emp-body .emp-body-inner {
    padding-top: 76px;
  } */
  .product-modal-content.productmodalcontent-lg
    :is(.one-col-2, .one-col-10, .one-col-3) {
    width: 100%;
    margin-bottom: 8px;
  }

  .product-modal-content.productmodalcontent-lg .import-schedule-report {
    overflow-y: scroll;
    height: calc(100vh - 120px);
    padding-inline: 10px;
  }

  .product-modal-content.productmodalcontent-lg
    .import-schedule-report
    .one-control-input
    input[type="radio"] {
    margin-right: 4px;
  }

  .product-modal-content.productmodalcontent-lg
    :is(
      .one-control-input,
      .one-control-select,
      .import-schedule-report .one-control-input > label
    ) {
    padding: 0px;
  }

  .product-modal-content.productmodalcontent-lg
    :is(.scheduler-type-container, .one-control-input-date.ms-2) {
    margin-left: 0px;
  }
  .product-left-content {
    width: 100%;
  }

  .product-modal-content.productmodal-padding {
    padding: 10px 8px;
    height: calc(100vh - 60px);
  }
  .product-modal-content.productmodal-padding .product-card-inner2 h4 {
    font-size: 11px;
  }
  .emp-content.sidebar .emp-side-bar {
    left: 0px;
    top: 79px;
    z-index: 30;
  }
  .emp-side-bar {
    left: -220px;
    top: 79px;
    z-index: 30;
  }

  .emp-body {
    width: 100%;
  }
  .logo {
    flex-grow: 1;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .logo-wrp {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }

  .logo img {
    max-width: 140px;
    width: 100%;
  }

  .middleproductcard-section {
    width: 100%;
  }

  .middleproductcard-section-overlay {
    width: 100%;
    position: fixed;
    right: 0px;
    top: 0px;
    height: 100%;
    -webkit-backdrop-filter: blur(1px);
            backdrop-filter: blur(1px);
    z-index: 10;
  }

  .system-admin-outer {
    position: fixed;
    width: 100%;
    max-width: 300px;
    right: 0px;
    top: 122px;
    padding: 12px;
    background-color: #f0f3f4;
    height: calc(100vh - 150px);
    z-index: 50;
  }

  .product-setting-section,
  .product-right-content {
    width: 50%;
    visibility: hidden;
    position: fixed;
    right: 0%;

    height: calc(100vh - 130px);
    transform: translate(100%, 0);
    background-color: #fff;
  }

  .product-setting-section.setting-hide,
  .product-right-content.setting-hide {
    visibility: visible;
    transform: translate(0, 0);
    position: fixed;
    right: 2%;
    height: calc(100vh - 130px);

    overflow: auto;
  }

  .product-right-content.setting-hide {
    right: 0%;
    height: calc(100vh - 110px);
  }

  .Integration-outer {
    width: 100%;
  }

  .product-modal.productmodal-full .Integration-outer {
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
  }

  .product-modal.productmodal-full .product-modal-inner {
    padding: 0px;
  }

  .product-modal.productmodal-full .product-modalBody {
    width: 100%;
    /* height: 100vh; */
  }
  .product-modal-content {
    height: auto;
    min-height: 620px;
  }

  .product-setting-inner1 {
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
    overflow: visible;
  }
  .dashboard-integration-doc,
  .dashboard-integration-doc.association.open {
    width: 100%;
  }
  .container-banner {
    padding-top: 76px;
  }
  .hero-banner-content {
    top: 76px;
  }
  .ess-portal .logo-wrp {
    justify-content: flex-start;
  }

  .product-left-content,
  .integration-document {
    height: calc(100vh - 120px);
  }
  .product-left-content.integration-active .side-img {
    padding: 16px;
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
  }

  .ess-portal .dash-product-card {
    width: 50%;
  }
  .landingpage-userpro.my-flex {
    flex-direction: column-reverse;
  }
  .landingpage-userpro.my-flex .one-col-9 {
    width: 100%;
  }


  /* responsive css  */
  .cstm-col-3.companyInfoCardCol{
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .header-company-info {
    width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dash-product-card.productcard-list,
  .product-right-content,
  .ess-portal .dash-product-card {
    width: 100%;
  }
  /* .product-modal.productmodal-full .product-modalBody {
    width: 95%;
    margin-top: 40px;
  } */
  .product-modal-content
    .date-picker
    .date-range-log
    .rdrDateRangePickerWrapper
    .rdrDefinedRangesWrapper {
    width: 144px;
  }
  .product-modal-content
    .date-picker
    .date-range-log
    .rdrDateRangePickerWrapper
    .rdrDefinedRangesWrapper
    .rdrInputRanges
    .rdrInputRange {
    padding-left: 0;
  }
  .product-modal-content
    .date-picker
    .date-range-log
    .rdrDateRangePickerWrapper
    .rdrDefinedRangesWrapper
    .rdrStaticRangeLabel {
    padding-left: 0;
  }
  .product-modal-content .date-picker .date-range-log {
    top: 54px;
    right: 13px;
  }
  .one-col-1.scheduler label {
    padding: 4px;
  }
  .one-col-1.scheduler {
    margin-bottom: 10px;
    width: 30%;
  }
  .import-schedule-report.heading-report-scheduler h1 {
    margin-top: 18px;
    font-size: 20px;
  }
  .emp-dashboard-body .pis h1 {
    font-size: 24px;
  }
  .emp-dashboard-body .JobsBody .header h2 {
    font-size: 20px;
  }
  .JobsBody .jobTitle {
    font-size: 15px;
    font-weight: 600;
  }
  .emp-dashboard-body .JobsBody .appsCont .app .top .name {
    margin-right: 15px;
    font-size: 14px;
  }
  .emp-dashboard-body .sourceTime {
    font-size: 11px;
  }
  .emp-dashboard-body .pagination-container a {
    padding: 4px 10px;
    font-size: 14px;
  }
  .emp-dashboard-body .pis h2 {
    font-size: 20px;
  }
  .emp-dashboard-body .BaseProductSetting .title {
    font-size: 16px;
  }
  .emp-dashboard-body .JobBoard .img {
    width: 130px;
  }
  .emp-dashboard-body .JobBoard .head {
    margin-top: 11px;
    align-items: center;
  }
  .emp-dashboard-body .Indeed .sponsor .job .top .actions {
    text-align: left;
  }
  .emp-dashboard-body .enSetting .selectSetting {
    flex-wrap: wrap;
  }
  .emp-dashboard-body .enSetting .selectSetting select {
    width: 450px;
  }
  .emp-dashboard-body select {
    font-size: 12px;
  }
  .emp-dashboard-body .enSetting .selectSetting select {
    width: 450px;
    font-size: 12px;
  }
  .grid-marketlist {
    grid-template-columns: repeat(2 , 1fr);
  }
}

@media screen and (max-width: 576px) {
  .dashboard-report-emp .report-pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .dashboard-report-emp .report-pagination > button {
    margin: 14px 14px;
    padding: 8px 30px;
  }
  .ein-report.dashboard-report-emp .heading h1 {
    font-size: 30px;
    padding: 10px 20px;
  }
  .dashboard-report-emp .report-container label {
    margin: 14px 14px;
  }
  .dashboard-report-emp .report-container .report-pagination > span {
    margin: 14px 14px;
  }
  .dashboard-report-emp .report-container select {
    margin: 14px 14px;
  }
  .ein-report.dashboard-report-emp .report-container {
    width: 96%;
  }
  .emp-dashboard-body
    .WebclockAttestation
    .step
    .outcomes
    .outcome
    .value
    span {
    flex-wrap: wrap;
  }
  .emp-dashboard-body .JobBoard .head h1 {
    font-size: 20px;
  }
  .emp-dashboard-body .sponsor .job .title {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .emp-dashboard-body .settingsCont .settingsRow .title {
    font-size: 16px;
  }
  .emp-dashboard-body .enSetting .selectSetting select {
    width: 280px;
  }
  .emp-dashboard-body .emp-body .emp-body-inner {
    padding: 86px 17px 25px 17px;
  }
  .emp-dashboard-body h2 {
    font-size: 18px;
  }
  .emp-dashboard-body .JobsBody .boards {
    flex-wrap: wrap;
  }
  .emp-dashboard-body .JobsBody .boards .board {
    /* height: 40px; */
    width: 82px;
  }
  .emp-dashboard-body .JobsBody .jobTitle {
    font-size: 13px;
  }
  .emp-dashboard-body .JobsBody .appsCont .app .top .nameCont .email {
    font-size: 8px;
  }
  .emp-dashboard-body .JobsBody .header h2 {
    font-size: 18px;
  }
  .emp-dashboard-body .tip {
    font-size: 12px;
  }
  .emp-dashboard-body .pis h2 {
    font-size: 18px;
  }
  .one-col-2.export-file-name {
    width: 33%;
  }
  .one-col-2.export-file-type {
    margin-bottom: 10px;
  }
  .logo img {
    max-width: 100px;
    width: 100%;
  }
  .container-banner {
    padding-top: 64px;
  }
  .hero-banner-content {
    top: 64px;
  }

  .hero-banner-content .content-data {
    width: auto;
  }

  .product-modal-content.productmodalcontent-lg :is(.one-col-4) {
    width: 100%;
    margin-bottom: 8px;
  }

  .product-modal.productmodal-full .pagination-container > li a {
    font-size: 12px;
    padding: 6px;
    margin: 0px 4px;
  }

  .product-modal-content.productmodalcontent-sm .one-col-sm-10 {
    width: 100%;
  }

  .product-modal-content.productmodalcontent-sm
    :is(.logs-table, .date-picker > .one-col-sm-2, .one-col-sm-10.d-flex > *) {
    margin: 0px 0px 8px 5px;
  }
  .product-modal-content.productmodalcontent-sm
    :is(.date-picker .d-flex, .date-picker) {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .product-modal.productmodal-full .provision-modal {
    padding: 12px;
    height: 800px;
  }

  .product-card-outer-out.productcardout-list {
    width: 100%;
  }

  .system-admin-outer {
    max-width: 100%;
    height: calc(100vh - 120px);
  }

  .emp-body .emp-body-inner {
    padding-top: 66px;
  }

  .header-right.my-flex p,
  .market-place-card-outer .market-place-card .description p {
    font-size: 14px;
  }

  .market-place-card-outer .market-place-card h2 {
    font-size: 20px;
  }
  .header-right img {
    width: 30px;
    height: 30px;
  }
  .emp-side-bar,
  .emp-content.sidebar .emp-side-bar {
    top: 64px;
  }
  .product-setting-section.setting-hide {
    position: fixed;
    right: 2%;
    height: calc(100vh - 130px);
    overflow: auto;
  }

  .product-setting-inner1 {
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
    overflow: visible;
  }
  .product-modal.productmodal-full
    .product-modalBody
    .product-modal-content
    h1.pb-0 {
    font-size: 20px;
  }
  .product-modal-content.productmodalcontent-sm
    :is(.date-picker .d-flex, .date-picker) {
    padding-top: 3px;
  }
  .product-modal
    .product-modal-content.productmodalcontent-sm
    .one-col-sm-10.d-flex {
    justify-content: center;
  }
  .product-modal
    .product-modal-content.productmodalcontent-sm
    .rdrNextPrevButton {
    width: 20px;
    height: 20px;
    margin: 0 9px;
  }
  .product-modal
    .product-modal-content.productmodalcontent-sm
    .rdrDateDisplayItem
    input {
    height: 25px;
    font-size: 12px;
  }
  .product-modal .product-modal-content.productmodalcontent-sm .rdrDateDisplay {
    margin: 8px;
  }
  .product-modal .product-modal-content.productmodalcontent-sm .rdrMonth {
    width: auto;
  }
  .product-modal
    .product-modal-content.productmodalcontent-sm
    .rdrMonthAndYearPickers
    select {
    padding: 6px 21px 6px 5px;
    font-size: 14px;
  }
  .rdrDayToday .rdrDayNumber span {
    font-size: 14px;
  }
  .product-modal-content
    .date-picker
    .date-range-log
    .rdrDateRangePickerWrapper
    .rdrDefinedRangesWrapper {
    width: auto;
  }
  .product-modal-content
    .date-picker
    .date-range-log
    .rdrDateRangePickerWrapper
    .rdrStaticRanges {
    display: flex;
    flex-direction: row;
  }
  .product-modal-content
    .date-picker
    .date-range-log
    .rdrDateRangePickerWrapper
    .rdrInputRanges {
    display: flex;
    justify-content: space-around;
  }
  .rdrDateRangePickerWrapper {
    flex-direction: column;
  }

  span.rdrDayNumber {
    font-size: 12px;
  }
  .sort-select {
    padding: 2px 4px;
  }
  .import-schedule-report .one-col-2.sftp-input .one-control-input input {
    padding: 10px 8px !important;
    font-size: 14px;
  }
  .import-schedule-report .one-control-select select {
    font-size: 14px;
    padding: 10px 8px !important;
  }
  .one-col-2.sftp-input button.one-control-btn {
    font-size: 14px;
    padding: 11px 19px !important;
  }
  .one-col-4.scheduler-div {
    width: 100%;
  }
  .one-col-2.sftp-input {
    width: 49%;
  }
  .emp-dashboard-body .Indeed .modalOuter {
    z-index: 999;
  }
  .emp-dashboard-body .modalOuter {
    z-index: 999;
  }
  .company-list{
    margin-top: 30px;
  }

  .cstm-col-3.companyInfoCardCol{
    width: 100%;
  }

 
}

/* dashboard-report-emp */

@media screen and (max-width: 420px) {
  .dashboard-report-emp .report-card {
    width: 100%;
  }
  .ein-report.dashboard-report-emp .report-card .item-card {
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
  }
  .ein-report.dashboard-report-emp .heading h1 {
    font-size: 22px;
    padding: 10px 20px;
  }
  .emp-dashboard-body .settingsCont input[type="text"] {
    min-width: auto;
  }
  .emp-dashboard-body .WebclockAttestation .step textarea {
    min-width: 286px;
  }
  .emp-dashboard-body .JobBoard .img {
    width: 120px;
  }
  .my-flex.mb-2.sftp-table {
    overflow: auto;
    height: 304px;
  }
  .sftp-scheduler-type {
    width: 100%;
  }
  .import-schedule-report.heading-report-scheduler h1 {
    margin-top: 5px;
    font-size: 16px;
  }
  .my-align-item-center.sftp-section {
    font-size: 14px;
  }
  .one-col-2.sftp-input {
    width: 100%;
  }
  .one-col-2.sftp-input button.one-control-btn {
    width: 55%;
    font-size: 12px;
    padding: 11px 10px !important;
  }
  .one-col-2.export-file-name {
    width: 100%;
  }
  .one-col-2.export-file-type {
    width: 100%;
  }
  .one-col-2.notification-type {
    width: 100%;
  }
  .one-col-2.import-input {
    width: 100%;
  }
  .one-col-2.scheduler-time-auto {
    width: 100%;
  }
  .one-col-4.scheduler-div {
    width: 100%;
  }
  .one-col-1.scheduler {
    width: 48%;
  }
  .product-modal
    .product-modal-content.productmodalcontent-sm
    .one-col-sm-10.d-flex {
    display: flex;
    justify-content: flex-start;
  }
  .product-modal-content
    .date-picker
    .date-range-log
    .rdrDateRangePickerWrapper
    .rdrDefinedRangesWrapper
    .rdrStaticRangeLabel {
    font-size: 10px;
    padding-right: 12px;
  }
  .product-modal-content .date-picker .date-range-log {
    top: 43px;
    right: 0;
    margin-right: 0 !important;
  }
  .product-modal-head h2 {
    font-size: 14px;
  }
  .product-modal.productmodal-full
    .product-modalBody
    .product-modal-content
    h1.pb-0 {
    font-size: 18px;
  }
  span.back-btn {
    font-size: 12px;
    cursor: pointer;
  }
  .product-close-modal-btn svg {
    width: 18px;
    height: 18px;
  }
  .product-modal .product-modal-content.productmodalcontent-sm .rdrMonth {
    width: 256px;
  }
  .rdrMonthsVertical {
    align-items: center;
  }
  .ess-portal .logo {
    padding-left: 20px;
  }

  .ess-portal .marketplacecard-container {
    margin-inline: 0.5rem;
  }

  .ess-portal .hero-banner-content .content-data {
    top: 25%;
  }
  .emp-body .emp-body-inner {
    padding: 56px 12px 25px 12px;
  }
  .header-right.my-flex p,
  .market-place-card-outer .market-place-card .description p {
    font-size: 13px;
  }

  .container-banner {
    padding-top: 48px;
  }
  .hero-banner-content {
    top: 48px;
  }

  .product-modal-content.productmodalcontent-lg h1 {
    font-size: 18px;
  }
  .logo {
    padding-left: 0px;
  }
  header {
    padding: 4px;
  }

  .product-setting-section {
    width: 100%;
  }
  .card-bar-one .card-content {
    width: calc(100% - 50px);
  }

  .prod-card .prod-heading {
    font-size: 15px;
  }
  .card-bar-one textarea.prod-description {
    font-size: 13px;
  }

  .emp-side-bar,
  .emp-content.sidebar .emp-side-bar {
    top: 52px;
  }
  .market-place-card {
    /* min-height: auto; */
    padding: 0.5rem 1rem;
    margin: 0.5rem;
  }
  .association-con.employee-report h1 {
    font-size: 20px;
  }
  .product-modal-content.productmodalcontent-sm
    :is(.logs-table, .date-picker > .one-col-sm-2, .one-col-sm-10.d-flex > *) {
    font-size: 11px;
  }
  .productmodalcontent-sm button {
    font-size: 11px;
  }
  a.csv-export-btn {
    font-size: 11px;
  }
  .product-modal-content
    .date-picker
    .date-range-log
    .rdrDateRangePickerWrapper
    .rdrDefinedRangesWrapper {
    width: 311px;
  }
  .one-col-sm-10.d-flex {
    justify-content: left;
  }
  .product-modal
    .product-modal-content.productmodalcontent-sm
    .rdrMonthAndYearPickers
    select {
    font-size: 12px;
  }
  .rdrMonthAndYearWrapper {
    justify-content: center;
  }
  span.rdrDateInput.rdrDateDisplayItem.rdrDateDisplayItemActive {
    max-width: 112px;
  }
  .rdrDateDisplayItem + .rdrDateDisplayItem {
    margin-left: 0.833em;
  }
  .rdrDateDisplay {
    display: flex;
    justify-content: center;
  }
  .rdrCalendarWrapper.rdrDateRangeWrapper {
    align-content: center;
    flex-wrap: wrap;
  }
  .product-modal-content
    .date-picker
    .date-range-log
    .rdrDateRangePickerWrapper
    .rdrDefinedRangesWrapper {
    width: 280px;
  }
  .tablecstm thead tr th {
    padding: 7px 6px;
  }
  .product-modal-content.productmodalcontent-sm .logs-table h3.text-center {
    font-size: 12px;
  }
  .tablecstm thead tr th {
    font-size: 11px;
  }
  .emp-dashboard-body .JobsBody .boards .board {
    margin-top: 6px;
    /* height: 45px; */
    width: 158px;
  }
  .emp-dashboard-body .JobsBody .boards .label {
    font-size: 11px;
    padding: 6px 8px;
  }
  .emp-dashboard-body .pis h1 {
    font-size: 18px;
  }
  .emp-dashboard-body .JobsBody .header h2 {
    font-size: 15px;
  }
  .emp-dashboard-body .breadcrumbs {
    font-size: 12px;
  }
  .emp-dashboard-body .JobsBody .appsCont .app .top .nameCont {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .emp-dashboard-body .JobsBody .appsCont .app .top.notReviewed {
    flex-wrap: wrap;
  }
  .emp-dashboard-body .JobsBody .appsCont .app .top {
    flex-wrap: wrap;
  }
  .emp-dashboard-body .JobsBody .rightSide {
    flex-wrap: wrap;
  }
  .emp-dashboard-body .pagination-container a {
    font-size: 12px;
    padding: 4px 7px;
  }
  .emp-dashboard-body .JobsBody .jobTitle {
    font-size: 12px;
  }
  .emp-dashboard-body .JobsBody .bottom .topbuts.resume a img {
    width: 16px;
  }
  .emp-dashboard-body .JobsBody .bottom .topbuts.resume a span {
    font-size: 12px;
  }
  .emp-dashboard-body .ur button {
    font-size: 12px;
    padding: 8px 10px;
  }
  .emp-dashboard-body td {
    font-size: 13px;
  }
  .emp-dashboard-body .BaseProductSetting .title {
    font-size: 14px;
  }
  .emp-dashboard-body input[type="text"] {
    font-size: 14px;
  }
  .emp-dashboard-body button {
    font-size: 10px;
  }
  .emp-dashboard-body .eChooser .buttonCont button:last-of-type {
    font-size: 12px;
  }

  .grid-marketlist {
    grid-template-columns: repeat(1 , 1fr);
  }

  .grid-marketlist .market-place-card {
    min-height: 100%;
  }

  .set-bottom {
    margin-inline: 0px;
  }

  .set-bottom .market-btn {
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
    margin-bottom: 0px;
  }

  .market-btn button {
    margin: 0px;
  }

  .set-bottom .market-place h1 {
    font-size: 20px;
    padding: 0px;
  }

  .set-bottom .market-place-card {
    min-height: 200px;
  }

  .set-bottom .market-place .slick-list .slick-track {
    padding: 20px 0px;
  }
}

@media screen and (max-width: 360px) {
  .emp-dashboard-body
    .BaseProductSetting
    .WebclockAttestation
    .step
    .outcomes
    .outcome
    input {
    min-width: 210px;
  }
  .emp-dashboard-body
    .WebclockAttestation
    .step
    .outcomes
    .outcome
    .value
    span {
    align-content: flex-start !important;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start !important;
  }
  .emp-dashboard-body .WebclockAttestation .step .outcomes .outcome > div {
    display: flex;
    flex-direction: column;
    width: 210px;
  }
  .emp-dashboard-body .WebclockAttestation .step select {
    width: 210px;
  }
  .emp-dashboard-body .WebclockAttestation .step {
    min-width: 100%;
  }
  .emp-dashboard-body .selectSetting {
    flex-wrap: wrap;
  }
  .emp-dashboard-body .WebclockAttestation .selectSetting {
    flex-wrap: wrap;
  }
  .emp-dashboard-body .pis .prod-settings .psrow {
    border-left: solid 2px #002e6c;
  }
  .emp-dashboard-body .WebclockAttestation .step textarea {
    min-width: 225px;
  }
  .emp-dashboard-body .settingsRow select {
    width: 232px;
  }
  .emp-dashboard-body .JobBoard .head h1 {
    font-size: 18px;
  }
  .emp-dashboard-body .JobBoard .img {
    width: 89px;
  }
  .emp-dashboard-body .Indeed .sponsor .job .title {
    font-size: 11px;
  }
  .emp-dashboard-body .Indeed .sponsor .job .label {
    font-size: 11px;
  }
  .emp-dashboard-body .documentationBody {
    font-size: 12px;
  }
  .emp-dashboard-body .Indeed .sponsor .job .actions button {
    font-size: 10px;
  }
  .emp-dashboard-body .Indeed .sponsor div {
    font-size: 12px;
  }
  .emp-dashboard-body .enSetting .selectSetting select {
    width: 232px;
    font-size: 12px;
  }
  .emp-dashboard-body .sourceTime {
    font-size: 10px;
  }
  .emp-dashboard-body .pagination-container a {
    font-size: 10px;
    padding: 4px 7px;
  }
  .emp-dashboard-body .JobsBody .appsCont .app .top .name {
    margin-right: 13px;
    font-size: 11px;
  }
  .emp-dashboard-body .BaseProductSetting input {
    min-width: 244px;
  }
  .emp-dashboard-body .JobsBody .boards .board {
    width: 113px;
  }
  .emp-dashboard-body .JobsBody .jobTitle {
    font-size: 10px;
  }
  .emp-dashboard-body td {
    font-size: 10px;
  }
  .emp-dashboard-body .ur button {
    font-size: 11px;
  }
  .emp-dashboard-body .JobsBody .bottom .topbuts.resume a span {
    font-size: 11px;
  }
  .emp-dashboard-body .JobsBody .bottom .topbuts.resume a img {
    width: 14px;
  }
  .emp-dashboard-body .pis h2 {
    font-size: 16px;
  }
  .emp-dashboard-body .JobsBody .header button {
    font-size: 10px;
  }
  .import-schedule-report.heading-report-scheduler h1 {
    margin-top: 5px;
    font-size: 14px;
  }
  .product-modal-content
    .date-picker
    .date-range-log
    .rdrDateRangePickerWrapper
    .rdrDefinedRangesWrapper {
    width: 265px;
  }
  .logo-wrp {
    width: auto;
  }

  .employeereport-select {
    max-width: 120px;
  }

  .employeereport-select .one-control-select {
    padding-left: 0px;
  }

  .association-con .integration-document {
    padding-inline: 0.5rem;
  }
  .market-place-card {
    min-height: 300px;
  }

  .container-banner {
    padding-top: 40px;
  }
  .hero-banner-content {
    top: 40px;
  }

  .ess-portal .hero-banner-content .content-data h2 {
    font-size: 20px;
    margin: 0px;
    line-height: 28px;
  }
  .ess-portal .hero-banner-content .content-data p {
    font-size: 16px;
    line-height: 24px;
  }
  .ess-portal :is(.hero-banner-content .content-data button, h1) {
    font-size: 16px;
  }

  .ess-portal .footer_sec {
    font-size: 12px;
  }

  .header-right.my-flex p {
    font-size: 12px;
  }

  .logo img {
    max-width: 80px;
  }

  .market-place-card-outer .market-place-card .description p {
    font-size: 12px;
    word-wrap: break-word;
  }
  .marketplace-card-margin {
    margin-inline: 0px;
  }
  .slick-prev {
    left: 0px;
  }
  .slick-next {
    right: 0px;
  }
  .associationcustomreports tbody tr td:last-child {
    max-width: 120px;
  }
  .product-modal-content {
    padding-inline: 8px;
  }
  table.table.associationcustomreports .me-10-px {
    margin-right: 4px;
  }

  table.table.associationcustomreports :is(th, td) {
    padding-inline: 4px;
  }
}


